import React, { Fragment, useEffect, useState } from 'react';
import Tools from '../../js/tools'
import notie from 'notie'
import { Mail, Briefcase } from 'react-feather';
import { useNavigate, useParams } from 'react-router-dom'
import { GetPermisos } from '../common/getPermisos';
import Breadcrumb from '../common/breadcrumbv2'
import Select from '../common/select-basic'
import Dropzone from '../common/dropzone';

const Default = ({
    esPrincipal,
    esEditar,
    setSelected
}) => {
    const navigate = useNavigate();
    const params = useParams();
    const [form, setForm] = useState({});
    const [catalogos, setCatalogos] = useState({});

    const onSelectEstado = (item) => {
        Tools.toolFetch({
            ruta: 'rt_ctg_cd',
            method: 'POST',
            body: item
        }, (response) => {
            setCatalogos((s) => ({
                ...s,
                ctgDfCd: response.datos
            }))

            setForm((s) => ({
                ...s,
                ID_EDO_DF: item.clave
            }))
        })
    }

    const guardar = () => {
        let formEmpresaDf = Tools.getForm('formEmpresaDf', { claveEtiquetas: '.txtVacios', ignore: ['txt_num_int_rs'] })

        if (formEmpresaDf.isEmtyCount || (!form.fileCert || !form.fileKey)) {
            let mensajeP = ''
            if (!form.fileCert) {
                mensajeP += `${formEmpresaDf.isEmtyCount ? `, ` : ''}Archivo .cer`
                setForm((s) => ({ ...s, statusCert: 'removed', fileCert: null }))
            }

            if (!form.fileKey) {
                mensajeP += `${formEmpresaDf.isEmtyCount ? `, ` : ''}Archivo .key`
                setForm((s) => ({ ...s, statusKey: 'removed', fileKey: null }))
            }

            let mensaje = `${formEmpresaDf.isEmtyCount ? formEmpresaDf.isEmtyString : ''}${mensajeP}`
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (formEmpresaDf.isEmtyCount) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formEmpresaDf.isEmtyString})`,
                // stay: Boolean, // optional, default = false
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        let formImg = new FormData(document.getElementById('formEmpresaDf'))
        formImg.append('idEm', params.idEm || '')
        formImg.append('cert', form.fileCert)
        formImg.append('key', form.fileKey)

        fetch('/rt_empresas_df_nueva', {
            method: 'POST',
            body: formImg
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.estatus === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.estatus === 'EXISTE') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Este contacto ya existe intente con otro correo`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.estatus === 'ARCHIVOS') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Falta cargar los archivos .cer y .key`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.estatus === 'ARCHIVOS-INCORRECTOS') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Los archivos no coinciden, asegúrate de subir ambos archivos de forma correcta .cer y .key`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (!esEditar) {
                    setTimeout(() => {
                        if (setSelected) {
                            setSelected('df')
                            navigate(`/empresa/${params.idEm}`)
                        } else {
                            navigate('/empresa/datos-fiscales')
                        }
                    }, 2000)
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Registro realizado con éxito.`,
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
    }

    const guardarDfDir = () => {
        let formEmpresaDf = Tools.getForm('formEmpresaDf', { claveEtiquetas: '.txtVacios', ignore: ['txt_num_int_rs'] })

        if (formEmpresaDf.isEmtyCount) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${formEmpresaDf.isEmtyString})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        formEmpresaDf.data.idEm = params.idEm || ''

        fetch('/rt_empresas_df_editar', {
            method: 'POST',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify({
                id: params.idDf,
                ...formEmpresaDf.data
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.estatus === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Registro realizado con exito.`,
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
    }

    const guardarCred = () => {
        let formEmpresaDf = Tools.getForm('formEmpresaDf', { claveEtiquetas: '.txtVacios', ignore: ['txt_num_int_rs'] })
        let mensaje = ''

        if (!formEmpresaDf.data.txt_clave ||
            !formEmpresaDf.data.txt_llave ||
            !formEmpresaDf.data.txt_usr
        ) {
            mensaje = `${!formEmpresaDf.data.txt_usr.length ? 'Usuario, ' : ''}${!formEmpresaDf.data.txt_clave.length ? 'Clave, ' : ''}${!formEmpresaDf.data.txt_llave.length ? 'Llave, ' : ''}`
            mensaje = mensaje.length ? mensaje.substring(0, mensaje.length - 2) : ''
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta completar (${mensaje})`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        formEmpresaDf.data.idEm = params.idEm || ''

        fetch('/rt_empresas_df_cred_editar', {
            method: 'POST',
            headers: { "Content-Type": 'application/json' },
            body: JSON.stringify({
                id: params.idDf,
                ...formEmpresaDf.data
            })
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.estatus === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Registro realizado con exito.`,
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
    }

    const guardarCert = () => {
        if (!form.fileCert) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta subir (Archivo Cert.cer)`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        let formImg = new FormData(document.getElementById('formEmpresaDf'))
        formImg.append('idEm', params.idEm || '')
        formImg.append('id', params.idDf)
        formImg.append('cert', form.fileCert)

        fetch('/rt_empresas_df_cert_editar', {
            method: 'POST',
            body: formImg
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.estatus === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.estatus === 'ARCHIVOS-INCORRECTOS') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `El archivo no coincide, asegúrate de subir el archivo .cer`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Archivo Cert.cer actualizado con exito.`,
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
    }

    const guardarKey = () => {
        if (!form.fileKey) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Falta subir (Archivo Key.key)`,
                time: 3, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        let formImg = new FormData(document.getElementById('formEmpresaDf'))
        formImg.append('idEm', params.idEm || '')
        formImg.append('id', params.idDf)
        formImg.append('key', form.fileKey)

        fetch('/rt_empresas_df_key_editar', {
            method: 'POST',
            body: formImg
        })
            .then((response) => response.json())
            .then((response) => {
                if (response.estatus === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.estatus === 'ARCHIVOS-INCORRECTOS') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `El archivo no coincide, asegúrate de subir el archivo .key`,
                        // stay: Boolean, // optional, default = false
                        time: 3, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Archivo Key.key actualizado con éxito.`,
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
    }

    useEffect(() => {
        fetch('/rt_ctg_df', {
            method: "POST",
            body: JSON.stringify({}),
            headers: { "Content-Type": "application/json" }
        })
            .then((response) => response.json())
            .then((response) => {
                setCatalogos(response.datos)
            })

        if (esEditar) {
            return Tools.toolFetch({
                ruta: 'rt_empresas_df_detalle',
                method: 'POST',
                body: { idEm: params.idEm || '', id: params.idDf },
            }, (response) => {
                let datos = response.datos

                if (response.estatus === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                onSelectEstado({ clave: datos.ID_EDO_EDF })
                setForm(datos)
            })
        }
    }, [])

    return (
        <Fragment>
            {esPrincipal ? <Breadcrumb icon={Briefcase} title={'Empresa'} parent={['datos_fiscales']} /> : ''}
            {/* className="container-fluid " */}
            <div>
                <div className='row'>
                    <form id='formEmpresaDf' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className="btn btn-regresar" type="button"
                                    onClick={(e) => {
                                        if (setSelected) {
                                            setSelected('df')
                                            navigate(`/empresa/${params.idEm}`)
                                        } else {
                                            navigate('/empresa/datos-fiscales')
                                        }
                                    }} ></button>
                                <h4><strong>{esEditar ? 'EDITAR DATOS FISCALES' : 'NUEVOS DATOS FISCALES'}</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className="row">
                                    <div className="col-sm-12 col-md-12">
                                        <div className='row'>
                                            <div className="col-md-12 mb-2">
                                                <h6><strong>DATOS DE FACTURACIÓN</strong></h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        id="txt_alias" name="txt_alias"
                                                        type="text"
                                                        defaultValue={form ? form.ALIAS_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i className="fa fa-file" aria-hidden="true"></i> Alias<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_alias" style={{ display: 'none' }}><strong>Falta completar (Alias)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        id="txt_rs" name="txt_rs"
                                                        type="text"
                                                        defaultValue={form ? form.RAZON_SOCIAL_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i className="fa fa-file" aria-hidden="true"></i> Razón Social<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_rs" style={{ display: 'none' }}><strong>Falta completar (Razón Social)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input autoComplete='off'
                                                        id="txt_rfc" name="txt_rfc"
                                                        type="text"
                                                        defaultValue={form ? form.RFC_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label htmlFor="validationTooltip02"><i className="fa fa-file" aria-hidden="true"></i> R.F.C<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_rfc" style={{ display: 'none' }}><strong>Falta completar (R.F.C)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select data-label="Regimen Fiscal"
                                                        id="list_regimen_fiscal"
                                                        name="list_regimen_fiscal"
                                                        value={form.REGIMEN_FISCAL_EDF}
                                                        datos={catalogos.ctgDfRegimenFiscal}
                                                        onChange={() => null}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-file" aria-hidden="true"></i> Regimen Fiscal<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios list_regimen_fiscal" style={{ display: 'none' }}><strong>Falta completar (Regimen Fiscal)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select data-label="Uso CFDI"
                                                        id="list_uso_cfdi"
                                                        name="list_uso_cfdi"
                                                        value={form.USO_CFDI_EDF}
                                                        datos={catalogos.ctgDfUsoCfi}
                                                        onChange={() => null}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-file" aria-hidden="true"></i> Uso CFDI<span className="text-danger">*</span></label>
                                                </div>

                                                <span className="text-danger txtVacios list_uso_cfdi" style={{ display: 'none' }}><strong>Falta completar (Uso CFDI)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id='correo_rs' name='correo_rs'
                                                        autoComplete='off'
                                                        type="text"
                                                        defaultValue={form ? form.CORREO_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-envelope" aria-hidden="true"></i> Correo<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios correo_rs" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                            </div>
                                            <div className="col-md-12 mb-4 mt-4">
                                                <h6><strong>DIRECCIÓN FISCAL</strong></h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select data-label="Estado"
                                                        id="list_estado"
                                                        name="list_estado"
                                                        value={form.ID_EDO_EDF}
                                                        datos={catalogos.ctgEdo}
                                                        onChange={(datos) => {
                                                            onSelectEstado(datos.item)
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-map-marker" aria-hidden="true"></i> Estado<span className="text-danger">*</span></label>
                                                </div>


                                                <span className="text-danger txtVacios list_estado" style={{ display: 'none' }}><strong>Falta sleccionar (Estado)</strong></span>
                                            </div>
                                            <div className="col-sm-12 col-md-6 mb-3">
                                                <div className='input-field select'>
                                                    <Select data-label="Ciudad"
                                                        id="list_ciudad"
                                                        name="list_ciudad"
                                                        value={form.ID_CD_EDF}
                                                        datos={catalogos.ctgDfCd}
                                                        onChange={({ item }) => {
                                                            setForm((s) => ({
                                                                ...s,
                                                                ID_CD_DF: item.clave
                                                            }))
                                                        }}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-map-marker" aria-hidden="true"></i> Ciudad<span className="text-danger">*</span></label>
                                                </div>
                                                <div className="form-group">


                                                    <span className="text-danger txtVacios list_ciudad" style={{ display: 'none' }}><strong>Falta sleccionar (Ciudad)</strong></span>
                                                </div>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id='txt_colonia_rs' name='txt_colonia_rs'
                                                        autoComplete='off' type="text"
                                                        defaultValue={form ? form.COlONIA_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-map-marker" aria-hidden="true"></i> Colonia<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_colonia_rs" style={{ display: 'none' }}><strong>Falta completar (Código Postal)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id='txt_calle_rs' name='txt_calle_rs'
                                                        autoComplete='off'
                                                        type="text"
                                                        defaultValue={form ? form.CALLE_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-map-marker" aria-hidden="true"></i> Calle<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_calle_rs" style={{ display: 'none' }}><strong>Falta completar (Calle)</strong></span>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className='input-field'>
                                                    <input id='txt_num_ext_rs' name='txt_num_ext_rs'
                                                        autoComplete='off'
                                                        type="text"
                                                        defaultValue={form ? form.NUM_EXT_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-hashtag" aria-hidden="true"></i> Número. Ext<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_num_ext_rs" style={{ display: 'none' }}><strong>Falta completar (Número. Ext)</strong></span>
                                            </div>
                                            <div className="col-md-4 mb-3">
                                                <div className='input-field'>
                                                    <input id='txt_num_int_rs' name='txt_num_int_rs'
                                                        autoComplete='off'
                                                        type="text"
                                                        defaultValue={form ? form.NUM_INT_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-hashtag" aria-hidden="true"></i> Número. Int</label>
                                                </div>
                                                <span className="text-danger txtVacios txt_num_int_rs" style={{ display: 'none' }}><strong>Falta completar (Número. Int)</strong></span>
                                            </div>
                                            <div className="col-sm-12 col-md-4 mb-3">
                                                <div className='input-field'>
                                                    <input id="txt_cp" name='txt_cp'
                                                        autoComplete='off'
                                                        type="text"
                                                        defaultValue={form ? form.CP_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-hashtag" aria-hidden="true"></i> C.P<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                            </div>
                                            <div className="col-sm-12 col-md-12 mb-3">
                                                <div className='input-field'>
                                                    <textarea id="txt_comentarios" name='txt_comentarios' rows={5}
                                                        autoComplete='off'
                                                        defaultValue={form.COMENTARIOS_EDF}
                                                        required
                                                        spellcheck="false"
                                                    ></textarea>
                                                    <label className="form-label"><i className="fa fa-comment" aria-hidden="true"></i> Comentarios<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                            </div>
                                            {(esEditar && (GetPermisos.find(permiso => [2041].includes(permiso)) ? true : false)) ?
                                                <div className="col-md-12">
                                                    <div className="justify-content-end d-flex mt-3">
                                                        <button className="btn btn-primary btn-sm d-flex align-items-center" type="button"
                                                            onClick={(e) => guardarDfDir()} >Guardar datos fiscales y dirección</button>
                                                    </div>
                                                </div>
                                                : ''}
                                            <div className="col-md-12 mb-4 mt-4">
                                                <h6><strong>CREDENCIALES</strong></h6>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id="txt_usr" name='txt_usr'
                                                        autoComplete='off'
                                                        type="text"
                                                        defaultValue={form ? form.USUARIO_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><span class="icon-icono_Usuario-interno"></span> Usuario<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_usr" style={{ display: 'none' }}><strong>Falta completar (Usuario)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id="txt_clave" name='txt_clave'
                                                        autoComplete='off'
                                                        type="password"
                                                        defaultValue={form ? form.CLAVE_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><span className="icon-icono_Contrasena"></span> Clave<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_clave" style={{ display: 'none' }}><strong>Falta completar (Clave)</strong></span>
                                            </div>
                                            <div className="col-md-6 mb-3">
                                                <div className='input-field'>
                                                    <input id="txt_llave" name='txt_llave'
                                                        autoComplete='off'
                                                        type="password"
                                                        defaultValue={form ? form.LLAVE_EDF : ''}
                                                        required
                                                        spellcheck="false"
                                                    />
                                                    <label className="form-label"><i className="fa fa-key" aria-hidden="true"></i> Llave<span className="text-danger">*</span></label>
                                                </div>
                                                <span className="text-danger txtVacios txt_llave" style={{ display: 'none' }}><strong>Falta completar (Llave)</strong></span>
                                            </div>
                                            {(esEditar && (GetPermisos.find(permiso => [2041].includes(permiso)) ? true : false)) ?
                                                <div className="col-md-12">
                                                    <div className="justify-content-end d-flex mt-3">
                                                        <button className="btn btn-primary btn-sm d-flex align-items-center" type="button"
                                                            onClick={(e) => guardarCred()} >Guardar credenciales</button>
                                                    </div>
                                                </div>
                                                : ''}
                                            <div className="col-md-12 mb-3 mt-4">
                                                <div className='row'>
                                                    <div className='col-md-6'>

                                                        <label className="form-label labelupload"><i className="fa fa-file" aria-hidden="true"></i> Añadir archivo .Cert<span className="text-danger">*</span></label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend" placeholder='Subir archivo'></div>
                                                            <Dropzone
                                                                /* inputContent={'Da click o arrastra el archivo .cer'} */
                                                                initialFiles={form ? form.ARCHIVO_CERT : null}
                                                                fileName={form ? form.CERT_ARCHIVO_EDF : null}
                                                                onImg={(datos) => {
                                                                    setForm((s) => ({ ...s, statusCert: datos.status, fileCert: datos.file }))
                                                                }}
                                                            />

                                                        </div>
                                                        {/* <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                                        {esEditar?
                                                        <div className="col-md-12">
                                                            <div className="justify-content-end d-flex mt-3">
                                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="button"
                                                                        onClick={(e) => guardarCert()} >Guardar archivo .cer</button>
                                                            </div>
                                                        </div>
                                                        :''} */}
                                                    </div>
                                                    <div className='col-md-6'>

                                                        <label className="form-label labelupload"><i className="fa fa-key" aria-hidden="true"></i> Añadir archivo .Key<span className="text-danger">*</span></label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend" placeholder='Subir archivo'></div>
                                                            <Dropzone
                                                                /* inputContent={'Da click o arrastra el archivo .key'} */
                                                                initialFiles={form ? form.ARCHIVO_KEY : null}
                                                                fileName={form ? form.KEY_ARCHIVO_EDF : null}
                                                                onImg={(datos) => {
                                                                    setForm((s) => ({ ...s, statusKey: datos.status, fileKey: datos.file }))
                                                                }}
                                                            />
                                                        </div>
                                                        <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (C.P)</strong></span>
                                                        {(esEditar && (GetPermisos.find(permiso => [2041].includes(permiso)) ? true : false)) ?
                                                            <div className="col-md-12">
                                                                <div className="justify-content-end d-flex mt-3">
                                                                    <button className="btn btn-primary btn-sm d-flex align-items-center" type="button"
                                                                        onClick={(e) => guardarKey()} >Guardar archivo .key</button>
                                                                </div>
                                                            </div>
                                                            : ''}
                                                    </div>
                                                </div>
                                            </div>
                                            {(!esEditar && (GetPermisos.find(permiso => [2040].includes(permiso)) ? true : false)) ?
                                                <div className="col-md-12 mt-2">
                                                    <div className="justify-content-end d-flex">
                                                        <button className="btn-nuevo d-flex align-items-center" type="button"
                                                            onClick={(e) => guardar()} >
                                                            <span className="text">Guardar</span>
                                                            <div className="icon-container">
                                                                <div className="icon icon--left">
                                                                </div>
                                                                <div className="icon icon--right">
                                                                </div>
                                                            </div>
                                                        </button>
                                                    </div>
                                                </div>
                                                : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default
import React from 'react';
import Default from '../components/dashboard/defaultCompo/default';
import Ecommerce from '../components/dashboard/ecommerce';

// Import custom Components 
import University from '../components/dashboard/university';
import Crypto from '../components/dashboard/crypto/crypto-component';
import ServerComponent from '../components/dashboard/server/server-component';
import Project from '../components/dashboard/project/project';

// widgets
import General from '../components/widgets/general';
import Chart from '../components/widgets/chart';

// Base
import Typography from '../components/base/typography';
import Avatar from '../components/base/avatar';
import HelperClass from '../components/base/helperclass';
import Grid from '../components/base/grid';
import TagsandPills from '../components/base/tagsandpills';
import ProgressBar from '../components/base/progressBar';
import ModalComponent from '../components/base/modalComponent';
import AlertComponent from '../components/base/Alert/Alert';
import PopoverComponent from '../components/base/Popover/Popover';
import TooltipsComponent from '../components/base/tooltipsComponent';
import Spinner from '../components/base/spinner';
import DropdownComponent from '../components/base/DropDown/Dropdown';
import TabBootstrap from '../components/base/Tabs/TabBootstrap';
import TabLine from '../components/base/Tabs/TabLine';
import Accordion from '../components/base/Accordian/Accordian';
import Navs from '../components/base/Nav/Navs';
import List from '../components/base/lists/list';
import Shadow from '../components/base/shadow';


// Advance
import Scrollable from '../components/advance/scrollable';
import Toastr from '../components/advance/toastr';
import RatingComponent from '../components/advance/ratingComponent';
import DropzoneComponent from '../components/advance/dropzone';
import TourComponent from '../components/advance/tourComponent';
import SweetAlert from '../components/advance/sweetAlert';
import SlickSlider from '../components/advance/slickSlider';
import CarouselComponent from '../components/advance/Carousels/carouselComponent';
import Ribbon from '../components/advance/ribbon';
import Pagination from '../components/advance/pagination';
import Steps from '../components/advance/steps';
import UIBreadCrumb from '../components/advance/uibreadcrumb';
import RangeSlider from '../components/advance/rangeSlider';
import ImageCropper from '../components/advance/imageCropper';
import StickyNotes from '../components/advance/stickyNotes';
import DragNDropComp from '../components/advance/drag-n-drop/dragNDropComp';
import UploadImage from '../components/advance/uploadImage';

// icons 
import FlagIcons from '../components/icons/flagIcons';
import FontAwsomeIcon from '../components/icons/fontAwsomeIcon';
import IcoIcons from '../components/icons/icoIcons';
import ThemifyIcons from '../components/icons/themifyIcons';
import FeatherIcons from '../components/icons/featherIcons';
import WeatherIcons from '../components/icons/weatherIcons';

// buttons
import DefaultBtn from '../components/buttons/default-btn';
import FlatBtn from '../components/buttons/flatBtn';
import EdgeBtn from '../components/buttons/edgeBtn';
import RaisedBtn from '../components/buttons/raisedBtn';
import GroupBtn from '../components/buttons/groupBtn';

// gallery
import ImageGallery from '../components/gallery/imageGallery';
import ImageHover from '../components/gallery/imageHover';
import ImageWithDesc from '../components/gallery/imageWithDesc';
import MesonryGallery from '../components/gallery/mesonryGallery';
import MesonryDesc from '../components/gallery/mesonryDesc';

// forms 
import FormValidation from '../components/forms/form-control/form-validation';
import BaseInput from '../components/forms/form-control/baseInput';
import RadioCheckbox from '../components/forms/form-control/radio-checkbox';
import InputGroupComp from '../components/forms/form-control/inputGroup';
import MegaOptions from '../components/forms/form-control/megaOptions';

import DatepickerComponent from '../components/forms/form-widgets/datepickerComponent';
import TimePickerWrapper from '../components/forms/form-widgets/timepickerComponent/timepicker';
import TypeaheadComp from '../components/forms/form-widgets/typeaheadComponent/typeahead';


import FormDefault from '../components/forms/form-layout/formDefault';
import FormWizard from '../components/forms/form-layout/wizard/form-wizard';


// tables 
import BasicTable from '../components/tables/bootstrap/basicTable';
import StylingTable from '../components/tables/bootstrap/stylingTable';
import BorderTable from '../components/tables/bootstrap/borderTable';
import SizingTable from '../components/tables/bootstrap/sizingTable';
import DataTableComponent from '../components/tables/dataTableComponent';

// cards
import BasicCards from '../components/cards/basicCards';
import CreativeCards from '../components/cards/creativeCards';
import TabCard from '../components/cards/tabCard';
import DraggingCards from '../components/cards/draggingCards';

// timeline
import Timeline2 from '../components/timelines/timeline2';
import Timeline from '../components/timelines/timeline';

// charts
import GoogleChart from '../components/charts/googleChart';
import ChartJs from '../components/charts/chartJs';
import Knobcharts from '../components/charts/knob';
import ApexCharts from '../components/charts/apex-chart';
import Chartist from '../components/charts/chartistComponent';

// maps
import GoogleMap from '../components/map/googleMap';
import LeafletMapComp from '../components/map/leafletMap';

// editors
import Editor1 from '../components/editor/ckEditor';
import Editor2 from '../components/editor/mdeEditor';
import Editor3 from '../components/editor/aceCodeEditor';

// users
import UserProfile from '../components/users/userProfile';
import UserEdit from '../components/users/userEdit';
import UserCards from '../components/users/user-cards';

// Calender
import Calender1 from '../components/calender/calender1';
import Calender2 from '../components/calender/calender2';

// blog
import BlogDetail from '../components/blog/blogDetail';
import BlogSingle from '../components/blog/blogSingle';
import BlogPost from '../components/blog/blogPost';

// job search 
import CardView from '../components/jobSearch/cardView';
import JobList from '../components/jobSearch/job-list';
import JobDetail from '../components/jobSearch/job-detail';
import JobApply from '../components/jobSearch/job-apply';

// learning 
import LearningList from '../components/learning/learning-list';
import LearningDeatil from '../components/learning/learning-deatil';

// Import Applications Components
import Todo from '../components/applications/todo-app/todo';
import EmailDefault from '../components/applications/email-app/emailDefault';
import Chat from '../components/applications/chat-app/chat';
import EcommerceApp from '../components/applications/ecommerce-app/product';
import AddToCart from '../components/applications/ecommerce-app/add-to-cart';
import WishlistComponent from '../components/applications/ecommerce-app/wishlist';
import ProductDetail from '../components/applications/ecommerce-app/product-detail/product-detail';
import Invoice from '../components/applications/ecommerce-app/invoice';
import Checkout from '../components/applications/ecommerce-app/checkout';
import TodoFirebase from '../components/applications/todo-firebase-app/todo-firebase';
import ContactApp from '../components/applications/contact-app/contactApp';
import ProductList from '../components/applications/ecommerce-app/product-list';
import Payment from '../components/applications/ecommerce-app/payment';
import History from '../components/applications/ecommerce-app/history';
import BookmarkApp from '../components/applications/bookmark-app/bookmarkApp';
import TaskApp from '../components/applications/task-app/taskApp';

import ProjectApp from '../components/applications/project/project'
import NewProject from '../components/applications/project/new-project'
import ProjectDetails from '../components/applications/project/project-details'
import KanbanBoard from '../components/applications/kanban-board/kanbanBoard'
import FileManager from '../components/applications/file-manager/file-manager'

import SocialApp from '../components/social-app/socialApp';
import FaqComponent from '../components/faq/faqComponent';
import KnowledgebaseComponent from '../components/knowledgebase/knowledgebaseComponent';
import SupportTicket from '../components/support-ticket/supportTicket';

// search page
import Searchpage from '../components/search/searchpage';

// sample page
import Samplepage from '../components/sample/samplepage';

// Pricing
import Pricing from '../components/price/pricing';

// import Inicio from '../components_chicken/admin/inicio';

import ProductosServicios from '../components_chicken/servicios/servicios';
import ProductosServiciosForm from '../components_chicken/servicios/servicios_form';

// sucursales
// import Sucursales from '../components_chicken/sucursales/sucursales_registros';
// import SucursalesNuevo from '../components_chicken/sucursales/sucursales_nuevo';
// import SucursalesDetalle from '../components_chicken/sucursales/sucursales_detalle';

// Cobertura
// import DefinirCobertura from '../components_chicken/cobertura/cobertura';
// import DefinirZonas from '../components_chicken/cobertura/zonas';

// Envío
// import EnviosDetalle from '../components_chicken/envios/envios_detalle';
// import EnviosNuevo from '../components_chicken/envios/envios';
// import Envios from '../components_chicken/envios/envios_realizados';
// import Solicitudes from '../components_chicken/envios/solicitudes';
// import SolicitudesDetalle from '../components_chicken/envios/solicitudes_detalle';

// Proveedores
import ProveedorSecciones from '../components_chicken/proveedores/proveedor_secciones'
import Proveedor from '../components_chicken/proveedores/proveedores'
import ProveedorForm from '../components_chicken/proveedores/proveedor_form';
import ProveedorContactoForm from '../components_chicken/proveedores/proveedor_contacto_form';

// Almacenes
import Almacen from '../components_chicken/almacenes/almacenes'
import AlmacenForm from '../components_chicken/almacenes/almacenes_form';

// Sucursales
import Sucursales from '../components_chicken/sucursales/sucursales'
import SucursalesForm from '../components_chicken/sucursales/sucursales_form';

// Insumos
import Insumos from '../components_chicken/insumos/insumos'
import InsumosForm from '../components_chicken/insumos/insumos_form';

// Articulos
import Articulos from '../components_chicken/articulos/articulos'
import ArticulosForm from '../components_chicken/articulos/articulos_form';

// Familias insumos
import FamiliasInsumos from '../components_chicken/familias_insumos/familias_insumos'
import FamiliasInsumosForm from '../components_chicken/familias_insumos/familias_insumos_form';

// Tipo inventaraios
import TipoInventario from '../components_chicken/tipo_inventario/tipo_inventario'
import TipoInventarioForm from '../components_chicken/tipo_inventario/tipo_inventario_form';

// Maximos y minimos
import MaximosMinimos from '../components_chicken/maximos_minimos/maximos_minimos'
import MaximosMinimosForm from '../components_chicken/maximos_minimos/maximos_minimos_form';

// Requisiciones surtido
import RequisicionesSurtido from '../components_chicken/requisiciones_surtido/requisiciones_surtido'
import RequisicionesSurtidoSecciones from '../components_chicken/requisiciones_surtido/requisiciones_surtido_secciones'
import RequisicionesSurtidoAutorizar from '../components_chicken/requisiciones_surtido/requisiciones_surtido_autorizar'
// import RequisicionesBuzon from '../components_chicken/requisiciones_surtido/requisiciones_surtido_buzon'
// import RequisicionesBuzonDetalle from '../components_chicken/requisiciones_surtido/requisiciones_surtido_buzon_detalle'
import RequisicionesSurtidoForm from '../components_chicken/requisiciones_surtido/requisiciones_surtido_form';

// Requisiciones pedido
import RequisicionesCompra from '../components_chicken/requisiciones_compra/requisiciones_compra'
import RequisicionesCompraSecciones from '../components_chicken/requisiciones_compra/requisiciones_compra_secciones'
import RequisicionesCompraAutorizar from '../components_chicken/requisiciones_compra/requisiciones_compra_autorizar'

//Buzón
import Buzon from '../components_chicken/buzon/buzon';

// Recetas
import Recetas from '../components_chicken/recetas/recetas'
import RecetasSecciones from '../components_chicken/recetas/recetas_secciones'

// Empresa
import Empresa from '../components_chicken/empresa/empresa';
import EmpresaNueva from '../components_chicken/empresa/empresa_form';

// Direcciones Fiscales
import DireccionFiscal from '../components_chicken/direccion_fiscal/direccion_fiscal';
import DireccionFiscalForm from '../components_chicken/direccion_fiscal/direccion_fiscal_form';

// Datos Fiscales
import DatosFiscales from '../components_chicken/empresa/empresa_df';
import DatosFiscalesForm from '../components_chicken/empresa/empresa_df_form';
import DatosPerfil from '../components_chicken/empresa/empresa_perfil';

// Clientes
// import Clientes from '../components_chicken/clientes/clientes';
// import ClientesNuevo from '../components_chicken/clientes/clientes_registro';
// import ClientesDetalle from '../components_chicken/clientes/clientes_perfil';

// Usuarios
import UsuariosNuevo from '../components_chicken/usuarios/usuarios_nuevo';
import Usuarios from '../components_chicken/usuarios/usuarios';
import UsuariosDetalle from '../components_chicken/usuarios/usuarios_detalle';
import UsuariosPerfil from '../components_chicken/usuarios/usuarios_perfil';

// Unidaddes De Medida
import UnidadesMedida from '../components_chicken/unidades_medida/unidades_medida'

// Marcas
import Marcas from '../components_chicken/marcas/marcas'

// Unidaddes De Medida
import Departamentos from '../components_chicken/departamentos/departamentos'

// Inicio
import Bienvenida from '../components_chicken/inicio/inicio';

export const routes = [
    // { path: `${process.env.PUBLIC_URL}/estadisticas`, Component: <Inicio /> },

    // Empresas
    { path: `${process.env.PUBLIC_URL}/empresa`, permisos: [11], Component: <Empresa esPrincipal={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/nueva`, permisos: [2038], Component: <EmpresaNueva esPrincipal={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/editar-empresa`, permisos: [12], Component: <EmpresaNueva esPrincipal={true} esEditar={true} noBtnAtras={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/:idEm`, permisos: [11], Component: <DatosPerfil esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/datos-fiscales`, permisos: [13], Component: <DatosFiscales esPrincipal={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/datos-fiscales/nuevo`, permisos: [2040], Component: <DatosFiscalesForm esPrincipal={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/datos-fiscales/:idDf`, permisos: [13], Component: <DatosFiscalesForm esPrincipal={true} esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/:idEm/datos-fiscales`, permisos: [13], Component: <DatosPerfil esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/empresa/:idEm/datos-fiscales/:idDf`, permisos: [13], Component: <DatosPerfil esEditar={true} /> },

    //Proveedores
    { path: `${process.env.PUBLIC_URL}/proveedores`, permisos: [14], Component: <Proveedor /> },
    { path: `${process.env.PUBLIC_URL}/proveedores/nuevo`, permisos: [15], Component: <ProveedorSecciones /> },
    { path: `${process.env.PUBLIC_URL}/proveedores/:id`, permisos: [14], Component: <ProveedorSecciones esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/proveedores-contacto/nuevo/:idPrv`, permisos: [14], Component: <ProveedorContactoForm /> },
    { path: `${process.env.PUBLIC_URL}/proveedores-contacto/editar/:idPrv/:idPrc`, permisos: [14], Component: <ProveedorContactoForm esEditar={true} /> },
    // { path: `${process.env.PUBLIC_URL}/proveedores/:id`, Component: <ProveedorForm esEditar={true} /> },

    //Almacenes
    { path: `${process.env.PUBLIC_URL}/almacenes`, permisos: [16], Component: <Almacen /> },
    { path: `${process.env.PUBLIC_URL}/almacenes/nuevo`, permisos: [17], Component: <AlmacenForm /> },
    { path: `${process.env.PUBLIC_URL}/almacenes/:id`, permisos: [16], Component: <AlmacenForm esEditar={true} /> },

    //Sucursales
    { path: `${process.env.PUBLIC_URL}/sucursales`, permisos: [2068], Component: <Sucursales /> },
    { path: `${process.env.PUBLIC_URL}/sucursales/nuevo`, permisos: [2069], Component: <SucursalesForm /> },
    { path: `${process.env.PUBLIC_URL}/sucursales/:id`, permisos: [2068], Component: <SucursalesForm esEditar={true} /> },

    //Insumos
    { path: `${process.env.PUBLIC_URL}/insumos`, permisos: [20], Component: <Insumos /> },
    { path: `${process.env.PUBLIC_URL}/insumos/nuevo`, permisos: [21], Component: <InsumosForm /> },
    { path: `${process.env.PUBLIC_URL}/insumos/:id`, permisos: [20], Component: <InsumosForm esEditar={true} /> },

    //Articulos
    { path: `${process.env.PUBLIC_URL}/articulos`, permisos: [22], Component: <Articulos /> },
    { path: `${process.env.PUBLIC_URL}/articulos/nuevo`, permisos: [23], Component: <ArticulosForm /> },
    { path: `${process.env.PUBLIC_URL}/articulos/:id`, permisos: [22], Component: <ArticulosForm esEditar={true} /> },

    //Familias Insumos
    { path: `${process.env.PUBLIC_URL}/familias-insumos`, permisos: [18], Component: <FamiliasInsumos /> },
    { path: `${process.env.PUBLIC_URL}/familias-insumos/nuevo`, permisos: [19], Component: <FamiliasInsumosForm /> },
    { path: `${process.env.PUBLIC_URL}/familias-insumos/:id`, permisos: [18], Component: <FamiliasInsumosForm esEditar={true} /> },

    //Direccion Fiscal
    { path: `${process.env.PUBLIC_URL}/direccion-fiscal`, permisos: [18], Component: <DireccionFiscal /> },
    { path: `${process.env.PUBLIC_URL}/direccion-fiscal/nuevo`, permisos: [19], Component: <DireccionFiscalForm /> },
    { path: `${process.env.PUBLIC_URL}/direccion-fiscal/:id`, permisos: [18], Component: <DireccionFiscalForm esEditar={true} /> },

    //Tipo Inventario
    { path: `${process.env.PUBLIC_URL}/tipo-inventario`, permisos: [24], Component: <TipoInventario /> },
    { path: `${process.env.PUBLIC_URL}/tipo-inventario/nuevo`, permisos: [25], Component: <TipoInventarioForm /> },
    { path: `${process.env.PUBLIC_URL}/tipo-inventario/:id`, permisos: [24], Component: <TipoInventarioForm esEditar={true} /> },

    //Maximos y Minimos
    { path: `${process.env.PUBLIC_URL}/maximos-minimos`, permisos: [26], Component: <MaximosMinimos /> },
    { path: `${process.env.PUBLIC_URL}/maximos-minimos/nuevo`, permisos: [27], Component: <MaximosMinimosForm /> },
    { path: `${process.env.PUBLIC_URL}/maximos-minimos/:id`, permisos: [26], Component: <MaximosMinimosForm esEditar={true} /> },

    //Requisiciones surtido
    { path: `${process.env.PUBLIC_URL}/requisiciones-surtido`, permisos: [28], Component: <RequisicionesSurtido rutaPlantillas={false} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-surtido/nuevo`, permisos: [29], Component: <RequisicionesSurtidoSecciones plantillaSimple={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-surtido/nueva-plantilla`, permisos: [29], Component: <RequisicionesSurtidoSecciones esPlantilla={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-surtido/:id`, permisos: [28], Component: <RequisicionesSurtidoSecciones esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-surtido/plantillas`, permisos: [29], Component: <RequisicionesSurtido rutaPlantillas={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-surtido/plantillas/:id`, permisos: [29], Component: <RequisicionesSurtidoSecciones rutaPlantillas={true} esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-surtido/autorizar/:crypt`, permisos: [2066], Component: <RequisicionesSurtidoAutorizar /> },
    // { path: `${process.env.PUBLIC_URL}/requisiciones-surtido/buzon`, permisos: [28], Component: <RequisicionesBuzon /> },
    // { path: `${process.env.PUBLIC_URL}/requisiciones-surtido/buzon/:id`, permisos: [28], Component: <RequisicionesBuzonDetalle /> },


    //Requisiciones pedido
    { path: `${process.env.PUBLIC_URL}/requisiciones-compra`, permisos: [2088], Component: <RequisicionesCompra rutaPlantillas={false} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-compra/nuevo`, permisos: [2089], Component: <RequisicionesCompraSecciones plantillaSimple={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-compra/nueva-plantilla`, permisos: [2089], Component: <RequisicionesCompraSecciones esPlantilla={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-compra/:id`, permisos: [2088], Component: <RequisicionesCompraSecciones esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-compra/plantillas`, permisos: [2089], Component: <RequisicionesCompra rutaPlantillas={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-compra/plantillas/:id`, permisos: [2089], Component: <RequisicionesCompraSecciones rutaPlantillas={true} esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/requisiciones-compra/autorizar/:crypt`, permisos: [2066], Component: <RequisicionesCompraAutorizar /> },


    //Recetas
    { path: `${process.env.PUBLIC_URL}/recetas`, permisos: [30], Component: <Recetas /> },
    { path: `${process.env.PUBLIC_URL}/recetas/nuevo`, permisos: [31], Component: <RecetasSecciones /> },
    { path: `${process.env.PUBLIC_URL}/recetas/:id`, permisos: [30], Component: <RecetasSecciones esEditar={true} /> },
    { path: `${process.env.PUBLIC_URL}/recetas/historial/:idh/:id`, permisos: [30], Component: <RecetasSecciones esHistorial={true} esEditar={true} /> },

    //Búzon
    { path: `${process.env.PUBLIC_URL}/buzon`, permisos: [2085], Component: <Buzon /> },

    //Unidades de Medida
    { path: `${process.env.PUBLIC_URL}/unidad-medida`, permisos: [33], Component: <UnidadesMedida /> },

    //Marcas
    { path: `${process.env.PUBLIC_URL}/marcas`, permisos: [2080], Component: <Marcas /> },

    //Departamentos
    { path: `${process.env.PUBLIC_URL}/departamentos`, permisos: [35], Component: <Departamentos /> },

    // Usuarios
    { path: `${process.env.PUBLIC_URL}/usuarios-cms`, permisos: [1036], Component: <Usuarios /> },
    { path: `${process.env.PUBLIC_URL}/usuarios-cms/nuevo`, permisos: [1037], Component: <UsuariosDetalle /> },
    { path: `${process.env.PUBLIC_URL}/usuarios-cms/detalle/:id`, permisos: [1036], Component: <UsuariosDetalle esEditar={true} /> },

    // Productos servicios
    { path: `${process.env.PUBLIC_URL}/servicios`, Component: <ProductosServicios /> },
    { path: `${process.env.PUBLIC_URL}/servicios/nuevo`, Component: <ProductosServiciosForm /> },
    { path: `${process.env.PUBLIC_URL}/servicios/:id`, Component: <ProductosServiciosForm esEditar={true} /> },

    // Sucursales
    // { path: `${process.env.PUBLIC_URL}/sucursales`, Component: <Sucursales /> },
    // { path: `${process.env.PUBLIC_URL}/sucursales/nuevo`, Component: <SucursalesNuevo /> },
    // { path: `${process.env.PUBLIC_URL}/sucursales/detalle/:id`, Component: <SucursalesDetalle /> },


    // Perfil
    { path: `${process.env.PUBLIC_URL}/perfil`, Component: <UsuariosPerfil /> },

    // Bienvenida
    { path: `${process.env.PUBLIC_URL}/inicio`, Component: <Bienvenida /> },

    // Cobertura
    // { path: `${process.env.PUBLIC_URL}/definir-cobertura`, Component: <DefinirCobertura /> },
    // { path: `${process.env.PUBLIC_URL}/definir-zonas`, Component: <DefinirZonas /> },
    // Envio
    // { path: `${process.env.PUBLIC_URL}/solicitudes`, Component: <Solicitudes /> },
    // { path: `${process.env.PUBLIC_URL}/solicitudes/:id`, Component: <SolicitudesDetalle /> },
    // { path: `${process.env.PUBLIC_URL}/envios`, Component: <Envios /> },
    // { path: `${process.env.PUBLIC_URL}/envios/nuevo`, Component: <EnviosNuevo /> },
    // { path: `${process.env.PUBLIC_URL}/envios/:id`, Component: <EnviosDetalle /> },

    // Clientes
    // { path: `${process.env.PUBLIC_URL}/clientes`, Component: <Clientes /> },
    // { path: `${process.env.PUBLIC_URL}/clientes/nuevo`, Component: <ClientesNuevo /> },
    // { path: `${process.env.PUBLIC_URL}/clientes/:id`, Component: <ClientesDetalle /> },

    // Usuarios
    { path: `${process.env.PUBLIC_URL}/usuarios-cms/nuevo`, Component: <UsuariosNuevo /> },
    { path: `${process.env.PUBLIC_URL}/usuarios-cms`, Component: <Usuarios /> },
    { path: `${process.env.PUBLIC_URL}/usuarios-cms/detalle/:id`, Component: <UsuariosDetalle /> },

    // Plantilla
    { path: `${process.env.PUBLIC_URL}/dashboard/default`, Component: <Default /> },
    { path: `${process.env.PUBLIC_URL}/dashboard/ecommerce`, Component: <Ecommerce /> },
    { path: `${process.env.PUBLIC_URL}/dashboard/university`, Component: <University /> },
    { path: `${process.env.PUBLIC_URL}/dashboard/crypto`, Component: <Crypto /> },
    { path: `${process.env.PUBLIC_URL}/dashboard/server`, Component: <ServerComponent /> },
    { path: `${process.env.PUBLIC_URL}/dashboard/project`, Component: <Project /> },

    // {/* Widgets Menu */}
    { path: `${process.env.PUBLIC_URL}/widgets/general`, Component: <General /> },
    { path: `${process.env.PUBLIC_URL}/widgets/chart`, Component: <Chart /> },

    //    {/* base */}
    { path: `${process.env.PUBLIC_URL}/base/typography`, Component: <Typography /> },
    { path: `${process.env.PUBLIC_URL}/base/avatar`, Component: <Avatar /> },
    { path: `${process.env.PUBLIC_URL}/base/grid`, Component: <Grid /> },
    { path: `${process.env.PUBLIC_URL}/base/helperclass`, Component: <HelperClass /> },
    { path: `${process.env.PUBLIC_URL}/base/tagsandpills`, Component: <TagsandPills /> },
    { path: `${process.env.PUBLIC_URL}/base/progressBar`, Component: <ProgressBar /> },
    { path: `${process.env.PUBLIC_URL}/base/modalComponent`, Component: <ModalComponent /> },
    { path: `${process.env.PUBLIC_URL}/base/alert`, Component: <AlertComponent /> },
    { path: `${process.env.PUBLIC_URL}/base/popoverComponent`, Component: <PopoverComponent /> },
    { path: `${process.env.PUBLIC_URL}/base/tooltipsComponent`, Component: <TooltipsComponent /> },
    { path: `${process.env.PUBLIC_URL}/base/spinner`, Component: <Spinner /> },
    { path: `${process.env.PUBLIC_URL}/base/dropdownComponent`, Component: <DropdownComponent /> },
    { path: `${process.env.PUBLIC_URL}/base/tabs/tab-bootstrap`, Component: <TabBootstrap /> },
    { path: `${process.env.PUBLIC_URL}/base/tabs/tab-line`, Component: <TabLine /> },
    { path: `${process.env.PUBLIC_URL}/base/accordion`, Component: <Accordion /> },
    { path: `${process.env.PUBLIC_URL}/base/navs`, Component: <Navs /> },
    { path: `${process.env.PUBLIC_URL}/base/shadow`, Component: <Shadow /> },
    { path: `${process.env.PUBLIC_URL}/base/list`, Component: <List /> },

    // {/* Advance */}2
    { path: `${process.env.PUBLIC_URL}/advance/scrollable`, Component: <Scrollable /> },
    { path: `${process.env.PUBLIC_URL}/advance/bootstrap-notify`, Component: <Toastr /> },
    { path: `${process.env.PUBLIC_URL}/advance/ratingComponent`, Component: <RatingComponent /> },
    { path: `${process.env.PUBLIC_URL}/advance/dropzone`, Component: <DropzoneComponent /> },
    { path: `${process.env.PUBLIC_URL}/advance/tourComponent`, Component: <TourComponent /> },
    { path: `${process.env.PUBLIC_URL}/advance/sweetAlert`, Component: <SweetAlert /> },
    { path: `${process.env.PUBLIC_URL}/advance/slick-slider`, Component: <SlickSlider /> },
    { path: `${process.env.PUBLIC_URL}/advance/carouselComponent`, Component: <CarouselComponent /> },
    { path: `${process.env.PUBLIC_URL}/advance/ribbon`, Component: <Ribbon /> },
    { path: `${process.env.PUBLIC_URL}/advance/pagination`, Component: <Pagination /> },
    { path: `${process.env.PUBLIC_URL}/advance/steps`, Component: <Steps /> },
    { path: `${process.env.PUBLIC_URL}/advance/uibreadcrumb`, Component: <UIBreadCrumb /> },
    { path: `${process.env.PUBLIC_URL}/advance/rangeSlider`, Component: <RangeSlider /> },
    { path: `${process.env.PUBLIC_URL}/advance/imageCropper`, Component: <ImageCropper /> },
    { path: `${process.env.PUBLIC_URL}/advance/stickyNotes`, Component: <StickyNotes /> },
    { path: `${process.env.PUBLIC_URL}/advance/dragNDropComp`, Component: <DragNDropComp /> },
    { path: `${process.env.PUBLIC_URL}/advance/uploadImage`, Component: <UploadImage /> },

    //   {/* icons */}
    { path: `${process.env.PUBLIC_URL}/icons/flagIcons`, Component: <FlagIcons /> },
    { path: `${process.env.PUBLIC_URL}/icons/fontAwsomeIcon`, Component: <FontAwsomeIcon /> },
    { path: `${process.env.PUBLIC_URL}/icons/icoIcons`, Component: <IcoIcons /> },
    { path: `${process.env.PUBLIC_URL}/icons/themifyIcons`, Component: <ThemifyIcons /> },
    { path: `${process.env.PUBLIC_URL}/icons/featherIcons`, Component: <FeatherIcons /> },
    { path: `${process.env.PUBLIC_URL}/icons/weatherIcons`, Component: <WeatherIcons /> },

    // {/* buttons */}
    { path: `${process.env.PUBLIC_URL}/buttons/default-btn`, Component: <DefaultBtn /> },
    { path: `${process.env.PUBLIC_URL}/buttons/flatBtn`, Component: <FlatBtn /> },
    { path: `${process.env.PUBLIC_URL}/buttons/edgeBtn`, Component: <EdgeBtn /> },
    { path: `${process.env.PUBLIC_URL}/buttons/raisedBtn`, Component: <RaisedBtn /> },
    { path: `${process.env.PUBLIC_URL}/buttons/groupBtn`, Component: <GroupBtn /> },

    // {/* gallery */}
    { path: `${process.env.PUBLIC_URL}/gallery/imageGallery`, Component: <ImageGallery /> },
    { path: `${process.env.PUBLIC_URL}/gallery/imageWithDesc`, Component: <ImageWithDesc /> },
    { path: `${process.env.PUBLIC_URL}/gallery/imageHover`, Component: <ImageHover /> },
    { path: `${process.env.PUBLIC_URL}/gallery/mesonryGallery`, Component: <MesonryGallery /> },
    { path: `${process.env.PUBLIC_URL}/gallery/mesonryDesc`, Component: <MesonryDesc /> },

    // {/* Forms */}
    { path: `${process.env.PUBLIC_URL}/forms-controls/form-validation`, Component: <FormValidation /> },
    { path: `${process.env.PUBLIC_URL}/forms-controls/baseInput`, Component: <BaseInput /> },
    { path: `${process.env.PUBLIC_URL}/forms-controls/radio-checkbox`, Component: <RadioCheckbox /> },
    { path: `${process.env.PUBLIC_URL}/forms-controls/inputGroup`, Component: <InputGroupComp /> },
    { path: `${process.env.PUBLIC_URL}/forms-controls/megaOptions`, Component: <MegaOptions /> },

    { path: `${process.env.PUBLIC_URL}/form-layout/formDefault`, Component: <FormDefault /> },
    { path: `${process.env.PUBLIC_URL}/form-layout/FormWizard`, Component: <FormWizard /> },

    { path: `${process.env.PUBLIC_URL}/form-widget/datepickerComponent`, Component: <DatepickerComponent /> },
    { path: `${process.env.PUBLIC_URL}/form-widget/timepicker`, Component: <TimePickerWrapper /> },
    { path: `${process.env.PUBLIC_URL}/form-widget/typeahead`, Component: <TypeaheadComp /> },


    // {/* Tables */}
    { path: `${process.env.PUBLIC_URL}/table/datatable`, Component: <DataTableComponent /> },
    { path: `${process.env.PUBLIC_URL}/table/basic`, Component: <BasicTable /> },
    { path: `${process.env.PUBLIC_URL}/table/sizing`, Component: <SizingTable /> },
    { path: `${process.env.PUBLIC_URL}/table/border`, Component: <BorderTable /> },
    { path: `${process.env.PUBLIC_URL}/table/styling`, Component: <StylingTable /> },

    // {/* cards */}
    { path: `${process.env.PUBLIC_URL}/cards/basicCards`, Component: <BasicCards /> },
    { path: `${process.env.PUBLIC_URL}/cards/creativeCards`, Component: <CreativeCards /> },
    { path: `${process.env.PUBLIC_URL}/cards/tabCard`, Component: <TabCard /> },
    { path: `${process.env.PUBLIC_URL}/cards/draggingCards`, Component: <DraggingCards /> },

    // {/* Timeline */}
    { path: `${process.env.PUBLIC_URL}/timelines/timeline`, Component: <Timeline /> },
    { path: `${process.env.PUBLIC_URL}/timelines/timeline2`, Component: <Timeline2 /> },

    // {/* Charts */}
    { path: `${process.env.PUBLIC_URL}/charts/googleChart`, Component: <GoogleChart /> },
    { path: `${process.env.PUBLIC_URL}/charts/chartJs`, Component: <ChartJs /> },
    { path: `${process.env.PUBLIC_URL}/knob/knob-charts`, Component: <Knobcharts /> },
    { path: `${process.env.PUBLIC_URL}/charts/apex-chart`, Component: <ApexCharts /> },
    { path: `${process.env.PUBLIC_URL}/charts/chartist-chart`, Component: <Chartist /> },

    // {/* Map */}
    { path: `${process.env.PUBLIC_URL}/map/googleMap`, Component: <GoogleMap /> },
    { path: `${process.env.PUBLIC_URL}/map/leafletMap`, Component: <LeafletMapComp /> },

    // {/* Editor */}
    { path: `${process.env.PUBLIC_URL}/editor/ckEditor`, Component: <Editor1 /> },
    { path: `${process.env.PUBLIC_URL}/editor/mdeEditor`, Component: <Editor2 /> },
    { path: `${process.env.PUBLIC_URL}/editor/acecodeEditor`, Component: <Editor3 /> },

    // {/* Users */}
    { path: `${process.env.PUBLIC_URL}/users/userProfile`, Component: <UserProfile /> },
    { path: `${process.env.PUBLIC_URL}/users/userEdit`, Component: <UserEdit /> },
    { path: `${process.env.PUBLIC_URL}/users/userCards`, Component: <UserCards /> },

    // {/* Calender */}
    { path: `${process.env.PUBLIC_URL}/calender/calender1`, Component: <Calender1 /> },
    { path: `${process.env.PUBLIC_URL}/calender/calender2`, Component: <Calender2 /> },

    // {/* Blog */}
    { path: `${process.env.PUBLIC_URL}/blog/blogDetail`, Component: <BlogDetail /> },
    { path: `${process.env.PUBLIC_URL}/blog/blogSingle`, Component: <BlogSingle /> },
    { path: `${process.env.PUBLIC_URL}/blog/blogPost`, Component: <BlogPost /> },

    // {/* Social App */}
    { path: `${process.env.PUBLIC_URL}/social/socialApp`, Component: <SocialApp /> },

    // {/* Job Search App */}
    { path: `${process.env.PUBLIC_URL}/jobSearch/cardView`, Component: <CardView /> },
    { path: `${process.env.PUBLIC_URL}/jobSearch/job-list`, Component: <JobList /> },
    { path: `${process.env.PUBLIC_URL}/jobSearch/job-detail/:id`, Component: <JobDetail /> },
    { path: `${process.env.PUBLIC_URL}/jobSearch/job-apply/:id`, Component: <JobApply /> },

    // {/* Learning App */}
    { path: `${process.env.PUBLIC_URL}/learning/learning-list`, Component: <LearningList /> },
    { path: `${process.env.PUBLIC_URL}/learning/learning-detail/:id`, Component: <LearningDeatil /> },

    // {/* FAQ */}
    { path: `${process.env.PUBLIC_URL}/faq/faqComponent`, Component: <FaqComponent /> },

    // {/* Knowledgebase */}
    { path: `${process.env.PUBLIC_URL}/knowledgebase/knowledgebaseComponent`, Component: <KnowledgebaseComponent /> },

    // {/* Support Ticket */}
    { path: `${process.env.PUBLIC_URL}/support-ticket/supportTicket`, Component: <SupportTicket /> },

    // {/* Applications */}
    { path: `${process.env.PUBLIC_URL}/todo-app/todo`, Component: <Todo /> },
    { path: `${process.env.PUBLIC_URL}/email-app/emailDefault`, Component: <EmailDefault /> },
    { path: `${process.env.PUBLIC_URL}/chat-app/chat`, Component: <Chat /> },

    // {/* Ecommerce App */}
    { path: `${process.env.PUBLIC_URL}/ecommerce/product`, Component: <EcommerceApp /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/cart/:id`, Component: <AddToCart /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/wishlist/:id`, Component: <WishlistComponent /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/product-detail/:id`, Component: <ProductDetail /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/checkout`, Component: <Checkout /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/invoice`, Component: <Invoice /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/product-list`, Component: <ProductList /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/payment`, Component: <Payment /> },
    { path: `${process.env.PUBLIC_URL}/ecommerce/history`, Component: <History /> },

    // {/* To-Do-Firebase */}
    { path: `${process.env.PUBLIC_URL}/todo-app/todo-firebase`, Component: <TodoFirebase /> },

    // {/* CONTACT APP */}
    { path: `${process.env.PUBLIC_URL}/contact-app/contact`, Component: <ContactApp /> },

    // {/* Bookmark App */}
    { path: `${process.env.PUBLIC_URL}/bookmark-app/bookmark`, Component: <BookmarkApp /> },

    // {/* Task App */}
    { path: `${process.env.PUBLIC_URL}/taks-app/task`, Component: <TaskApp /> },

    { path: `${process.env.PUBLIC_URL}/project/project-list`, Component: <ProjectApp /> },
    { path: `${process.env.PUBLIC_URL}/project/new-project`, Component: <NewProject /> },
    { path: `${process.env.PUBLIC_URL}/project/project-details`, Component: <ProjectDetails /> },
    { path: `${process.env.PUBLIC_URL}/kanban-board`, Component: <KanbanBoard /> },
    { path: `${process.env.PUBLIC_URL}/file-manager`, Component: <FileManager /> },

    // {/* Search page */}
    { path: `${process.env.PUBLIC_URL}/search/searchpage`, Component: <Searchpage /> },

    // {/* Sample page */}
    { path: `${process.env.PUBLIC_URL}/sample/samplepage`, Component: <Samplepage /> },

    // {/* Pricing */}
    { path: `${process.env.PUBLIC_URL}/price/pricing`, Component: <Pricing /> },
]

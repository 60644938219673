import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Briefcase } from 'react-feather';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_familias_insumos_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Briefcase} title={'Familias insumos/articulos'} parent={[{ titulo: 'familias insumos/articulos', link: '/familias-insumos' }]} />
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    <div className="card-header d-flex justify-content-center p-3">
                        <h4>Familias Insumos/Articulos</h4>
                    </div>
                    <div className="mt-3 px-4">
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <div className="btn-container">
                                    {(GetPermisos.find(permiso => [19].includes(permiso)) ? true : false) ?
                                        <button className='btn-nuevo' onClick={() => {
                                            navigate('/familias-insumos/nuevo')
                                        }}>
                                            <span className="text">Nuevo</span>
                                            <div className="icon-container">
                                                <div className="icon icon--left">
                                                </div>
                                                <div className="icon icon--right">
                                                </div>
                                            </div>
                                        </button>
                                        : ''
                                    }
                                </div>}
                            headers={[
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_FI', html: (d) => {
                                        return <div>
                                            <strong>{d.NOMBRE_FI}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'TÍTULO', tipo: 'html', texto: 'TITULO_FI', html: (d) => {
                                        return <div>
                                            <strong>{d.TITULO_FI}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'ABERBIATURA', tipo: 'html', texto: 'ABREVIATURA_FI', html: (d) => {
                                        return <div>
                                            <strong>{d.ABREVIATURA_FI}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'TIPO', tipo: 'html', texto: 'TIPO', html: (d) => {
                                        return <div style={{ width: '25em' }}>
                                            <strong>{d.TIPO}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                    html: (d) => {
                                        return <div>
                                            <BtnVer
                                                onClick={() => {
                                                    navigate(`/familias-insumos/${d.ID_FI}`)
                                                }}
                                            />
                                            {(GetPermisos.find(permiso => [2047].includes(permiso)) ? true : false) ?
                                                <BtnEliminar
                                                    onClick={() => {
                                                        notie.confirm({
                                                            text: `Se eliminara <strong>${d.NOMBRE_FI}</strong> ¿estas de acuerdo?`,
                                                            submitText: 'Si, eliminar',
                                                            cancelText: 'No, Cancelar',
                                                            cancelCallback: function (value) {
                                                            },
                                                            submitCallback: function (value) {
                                                                eliminar({ id_fi: d.ID_FI })
                                                            },
                                                        })
                                                    }}
                                                /> : ''
                                            }
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_familias_insumos_list'}
                            refresh={esRefresh}
                            body={{}}
                            paginadorTop={true}
                        >
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;
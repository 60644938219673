import React, { Fragment, useState } from 'react';
import { useNavigate } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { GetPermisos } from '../common/getPermisos';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import { Briefcase } from 'react-feather';
import tools from '../../js/tools';

const Default = (props) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const [modal5, setModal5] = useState();
    const [esEditar, setEsEditar] = useState(false)
    const [idEdit, setIdEdit] = useState(0)
    const [formUn, setFormUn] = useState({})
    const navigate = useNavigate()

    const toggle5 = () => {
        setModal5(!modal5)
    }

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_marcas_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const detalle = (id) => {
        Tools.toolFetch({
            ruta: 'rt_marcas_detalle',
            method: 'POST',
            body: { id },
        }, (response) => {
            setEsEditar(true)
            setIdEdit(id)
            setFormUn(response.datos)
            toggle5()
        })
    }

    const onClickForm = () => {
        const form = tools.getForm('formCu')
        form.data.id = idEdit

        Tools.toolFetch({
            ruta: !esEditar ? 'rt_marcas_nuevo' : 'rt_marcas_editar',
            method: 'POST',
            body: { datos: form.data },
        }, (response) => {
            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                setEsRefresh((s) => !s)
                toggle5()
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Briefcase} title={'Marcas'} parent={[{ titulo: 'marcas', link: '/marcas' }]} />
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    <div className="card-header d-flex justify-content-center p-3">
                        <h4>Marcas</h4>
                    </div>
                    <div className="mt-3 px-4">
                        <Modal isOpen={modal5} toggle={toggle5}>
                            <ModalHeader toggle={toggle5}>Marca</ModalHeader>
                            <ModalBody>
                                <form id='formCu'>
                                    <div className="form-group">
                                        <label className="col-form-label" htmlFor="message-text">Nombre:</label>
                                        <input id='nombreCu' name='nombreCu' className="form-control" type="text" placeholder="Nombre"
                                            maxLength={100}
                                            value={formUn.NOMBRE_MAR ? formUn.NOMBRE_MAR : ''}
                                            onChange={(e) => { setFormUn(l => { return { ...l, NOMBRE_MAR: e.target.value } }) }} />
                                    </div>
                                </form>
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={onClickForm}>Guardar</Button>
                                <Button color="secondary" onClick={toggle5}>Cancelar</Button>
                            </ModalFooter>
                        </Modal>
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <div className="btn-container">
                                    {(GetPermisos.find(permiso => [2081].includes(permiso)) ? true : false) ?
                                        <button className='btn-nuevo' onClick={() => {
                                            setFormUn({})
                                            setEsEditar(false)
                                            toggle5()
                                        }}>
                                            <span className="text">Nuevo</span>
                                            <div className="icon-container">
                                                <div className="icon icon--left">
                                                </div>
                                                <div className="icon icon--right">
                                                </div>
                                            </div>
                                        </button>
                                        : ''
                                    }
                                </div>}
                            headers={[
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_MAR', html: (d) => {
                                        return <div>
                                            <strong>{d.NOMBRE_MAR}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'FECHA DE ALTA', tipo: 'html', texto: 'FECHA_ALTA_FORMAT', html: (d) => {
                                        return <div>
                                            <strong>{d.FECHA_ALTA_FORMAT}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                    html: (d) => {
                                        return <div>
                                            {(GetPermisos.find(permiso => [2082].includes(permiso)) ? true : false) ?
                                                <BtnVer
                                                    onClick={() => { detalle(d.ID_MAR) }}
                                                />
                                                : ''
                                            }
                                            {(GetPermisos.find(permiso => [2083].includes(permiso)) ? true : false) ?
                                                <BtnEliminar
                                                    onClick={() => {
                                                        notie.confirm({
                                                            text: `Se eliminara <strong>${d.NOMBRE_MAR}</strong> ¿estas de acuerdo?`,
                                                            submitText: 'Si, eliminar',
                                                            cancelText: 'No, Cancelar',
                                                            cancelCallback: function (value) {
                                                            },
                                                            submitCallback: function (value) {
                                                                eliminar({ idMar: d.ID_MAR })
                                                            },
                                                        })
                                                    }}
                                                />
                                                : ''
                                            }
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'rt_marcas_list'}
                            refresh={esRefresh}
                            body={{}}
                            paginadorTop={true}
                        >
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;
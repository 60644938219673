import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import { GetPermisos } from '../common/getPermisos';
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar, formProv, setFormProv, listArtIns, setSigPag, setDataProveedores }) => {
    const navigate = useNavigate()
    const params = useParams();

    const onClickProv = () => {
        const form = Tools.getForm('formProv')
        console.log(form);

        const articulosInsumos = Object.values(listArtIns).filter((v) => v)
        if (articulosInsumos.length === 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Ingrese algún Articulo/Insumo',
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        form.data.id = params.id
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_proveedores_nuevo' : 'rt_proveedores_editar',
            method: 'POST',
            body: { data: form.data, seleccionados: listArtIns },
        }, (response) => {
            console.log(response)

            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                navigate('/proveedores')
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            {/* <Breadcrumb icon={Briefcase} title={'Proveedores'} parent={['proveedores']} /> */}
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formProv' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">

                                {/* <div className="btn-container">
                                    <button className='btn-nuevo' type='button' onClick={(e) => {
                                        // navigate('/servicios')
                                        window.location.href = '/proveedores'
                                    }}>
                                        <span className="text">Regresar</span>
                                        <div className="icon-container">
                                            <div className="icon icon--left">
                                            </div>
                                            <div className="icon icon--right">
                                            </div>
                                        </div>
                                    </button>
                                </div> */}
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/proveedores')

                                }}></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} PROVEEDOR</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales del proveedor</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                maxLength={150}
                                                value={formProv ? formProv.NOMBRE_PRV : ''}
                                                onChange={(e) => { setFormProv(l => { return { ...l, NOMBRE_PRV: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span className="icon-icono_Cliente"></span> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_titulo" name="txt_titulo"
                                                type="text"
                                                maxLength={150}
                                                value={formProv ? formProv.TITULO_PRV : ''}
                                                onChange={(e) => { setFormProv(l => { return { ...l, TITULO_PRV: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Título<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Título)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_razon" name="txt_razon"
                                                type="text"
                                                maxLength={200}
                                                value={formProv ? formProv.RAZON_SOCIAL_PRV : ''}
                                                onChange={(e) => { setFormProv(l => { return { ...l, RAZON_SOCIAL_PRV: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-file" aria-hidden="true"></i> Razón social<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_razon" style={{ display: 'none' }}><strong>Falta completar (Razón social)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_correo" name="txt_correo"
                                                type="text"
                                                maxLength={150}
                                                value={formProv ? formProv.CORREO_PRV : ''}
                                                onChange={(e) => { setFormProv(l => { return { ...l, CORREO_PRV: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-envelope" aria-hidden="true"></i> Correo electrónico<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_correo" style={{ display: 'none' }}><strong>Falta completar (Correo)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_telefono" name="txt_telefono"
                                                type="text"
                                                maxLength={30}
                                                value={formProv ? formProv.TELEFONO_PRV : ''}
                                                onChange={(e) => { setFormProv(l => { return { ...l, TELEFONO_PRV: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Teléfono<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_telefono" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_celular" name="txt_celular"
                                                type="text"
                                                maxLength={30}
                                                value={formProv ? formProv.CELULAR_PRV : ''}
                                                onChange={(e) => { setFormProv(l => { return { ...l, CELULAR_PRV: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Celular<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_celular" style={{ display: 'none' }}><strong>Falta completar (Celular)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            {/* {(esEditar && (GetPermisos.find(permiso => [2057].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [15].includes(permiso)) ? true : false)) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickProv}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button>
                                                : ''
                                            } */}
                                            <button className='btn-nuevo' type='button' onClick={() => {
                                                setSigPag('1')
                                            }}>
                                                <span className="text">Siguiente</span>
                                                <div className="icon-container">
                                                    <div className="icon icon--left">
                                                    </div>
                                                    <div className="icon icon--right">
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default

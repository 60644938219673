import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie, { setOptions } from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import Table from '../common/tabla'
import InputLabel from '../common/inputLabel'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';
import { GetPermisos } from '../common/getPermisos';

const Default = ({
    listArtIns,
    setListArtIns,
    esEditar,
    dataForm,
    esPlantilla,
    rutaPlantillas,
    ctgAlmacenes,
    ctgUcAlmacenes,
    setCtgAlmacenes,
    setDataForm,
    ctgEstatus,
    formReq,
    setFormReq,
    estatus,
    valorEstatus,
    permisoAlmacen
}) => {
    const [formFaIn, setFormFaIn] = useState({})
    const [prVal, setPrVal] = useState(0)
    const [permisoSel, setPermisoSel] = useState(false)
    const [tipoVal, setTipoVal] = useState(0)
    const [ctgPrv, setCtgPrv] = useState([])
    const [esRefresh, setEsRefresh] = useState(true)
    const [almacenesSur, setAlmacenesSur] = useState([])
    const [listArtInsCopia, setListArtInsCopia] = useState({})
    const [esRefreshCopia, setEsRefreshCopia] = useState(true)
    const [modal5, setModal5] = useState();
    const [optionBtn, setOptionBtn] = useState(false)
    const [textButton, setTextButton] = useState({
        descargaPdf: 'Descargar PDF'
    })
    const navigate = useNavigate()
    const params = useParams();

    const toggle5 = () => {
        setListArtInsCopia(!modal5 ? Object.assign({}, listArtIns) : {})
        setTipoVal(1)
        setEsRefreshCopia(!esRefreshCopia)
        setModal5(!modal5)
    }


    const cantidades = [
        {
            nombre: 'CANTIDAD INICIAL',
            texto: 'CANTIDAD_INICIAL',
            condicion: true,
            disabled: !esEditar
        },
        {
            nombre: 'CANTIDAD AUTORIZADA',
            texto: 'CANTIDAD_AUTH',
            condicion: (!rutaPlantillas && formReq.ESTATUS_RQI >= 1 && formReq.ESTATUS_RQI != 5 && formReq.REQUIERE_AUTORIZAR_RQI),
            disabled: (esEditar && GetPermisos.find(permiso => [2066].includes(permiso)) && formReq.ESTATUS_RQI == 1) ? true : false
        },
        {
            nombre: 'CANTIDAD PROCESADA',
            texto: 'CANTIDAD_PROCESO',
            condicion: (!rutaPlantillas && formReq.ESTATUS_RQI >= 2 && formReq.ESTATUS_RQI != 5 && permisoAlmacen),
            disabled: (esEditar && permisoAlmacen && formReq.ESTATUS_RQI == 2)
        },
    ]

    useEffect(() => {
        setEsRefresh(l => !l)
    }, [esEditar])

    useEffect(() => {
        Tools.toolFetch({
            ruta: 'rt_get_proveedores',
            method: 'POST',
            body: {
                listArtIns: listArtIns,
                tipoVal: tipoVal
            },
        }, (response) => {
            if (response.estatus === 'OK') {
                setCtgPrv(response.datos)
            }
        })
    }, [tipoVal])

    useEffect(() => {
        setAlmacenesSur(ctgAlmacenes.filter(v => v.ID_ALM != dataForm.clave_almacen))
    }, [dataForm.clave_almacen, ctgAlmacenes])

    const onClickValid = () => {
        const articulosInsumos = Object.values(listArtIns).filter((v) => v)

        if (!dataForm.clave_almacen || !dataForm.clave_almacen_surtido) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Ingrese los almacenes',
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (articulosInsumos.length === 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Ingrese algún Articulo/Insumo',
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (articulosInsumos.filter(v => v.cantidad <= 0).length > 0) {
            setTipoVal(0)
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: 'Agregue una cantidad en todos los Articulos/Insumos seleccionados',
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }


        notie.select({
            text: `¿Desea guardar esta requisición?`,
            position: 'top',
            cancelText: 'Cancelar',
            choices: [
                {
                    text: 'Guardar y enviar',
                    handler: function () {
                        onClickReq(true, articulosInsumos)
                    }
                },
                // {
                //     text: 'Solo guardar',
                //     handler: function () {
                //         onClickReq(false, articulosInsumos)
                //     }
                // }
            ]
        })
    }

    const onClickReq = (esEnviar, articulosInsumos) => {
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_requisiciones_nuevo' : (rutaPlantillas ? 'rt_requisiciones_nuevo' : 'rt_requisiciones_editar'),
            method: 'POST',
            body: { ...params, datos: { ...dataForm, esPlantilla: esPlantilla ? 1 : 0, rutaPlantillas: rutaPlantillas || esPlantilla ? 1 : 0 }, seleccionados: articulosInsumos, esEnviar },
        }, (response) => {
            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            console.log(response);
            const artInsList = {}
            response[1].forEach(v => {
                artInsList[`${v.ID_INA_RQP}_${v.ID_PRV_RQP}`] = {
                    id: v.ID_INA_RQP,
                    id_prv: v.ID_PRV_RQP,
                    // cantidad: rutaPlantillas ? v.CANTIDAD_RQP : v.CANTIDAD,
                    cantidad_rqp: v.CANTIDAD_RQP,
                    cantidad_auth: v.CANTIDAD_AUTH_RQP,
                    cantidad_proceso: v.CANTIDAD_PROCESO_RQP,
                    comentarios: v.COMENTARIOS,
                    activo: v.ACTIVO_RQP
                }
            });
            setListArtIns(artInsList)

            if (!esEditar || (esEditar && rutaPlantillas)) {
                setTimeout(() => {
                    navigate('/requisiciones-surtido/' + response[0][0].ID)
                    setEsRefresh(l => !l)
                }, 2000);
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const onClickEnviar = () => {
        setOptionBtn(true)

        Tools.toolFetch({
            ruta: 'rt_requisiciones_enviar',
            method: 'POST',
            body: { id: params.id },
        }, (response) => {
            setOptionBtn(false)
            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `El correo se ha enviado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const enviarCorreo = (valor, comentarios = '') => {
        setOptionBtn(true)
        const articulosInsumos = Object.values(listArtIns).filter((v) => v)

        Tools.toolFetch({
            ruta: 'rt_requisiciones_estatus_enviar',
            method: 'POST',
            body: { id: params.id, articulosInsumos, estatus: valor, comentarios },
        }, (response) => {
            setOptionBtn(false)

            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setFormReq(l => ({ ...l, ESTATUS_RQI: response.result[0][0].ESTATUS_RQI }))
            if (response.estatus === 'ERROR-EMAIL') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ha ocurrido un problema al enviar el correo, por favor inténtelo más tarde`,
                    // stay: Boolean, // optional, default = false
                    time: 6, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            const artInsList = {}
            response.result[1].forEach(v => {
                artInsList[`${v.ID_INA_RQP}_${v.ID_PRV_RQP}`] = {
                    id: v.ID_INA_RQP,
                    id_prv: v.ID_PRV_RQP,
                    // cantidad: rutaPlantillas ? v.CANTIDAD_RQP : v.CANTIDAD,
                    cantidad_rqp: v.CANTIDAD_RQP,
                    cantidad_auth: v.CANTIDAD_AUTH_RQP,
                    cantidad_proceso: v.CANTIDAD_PROCESO_RQP,
                    comentarios: v.COMENTARIOS,
                    activo: v.ACTIVO_RQP
                }
            });
            setListArtIns(artInsList)
            setEsRefresh(!esRefresh)

            if (valor === 4 && formReq.ESTATUS_RQI !== valor) {
                descargarPdf()
                setTimeout(() => {
                    notie.alert({
                        type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Descargando PDF...`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }, 2000);
            }
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `La requisición ha sido enviada correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    const estatusEnviar = (valor) => {
        if (valor !== formReq.ESTATUS_RQI) {
            if (formReq.ESTATUS_RQI < 3) {
                const articulosInsumos = Object.values(listArtIns).filter((v) => v)

                const cantidadVacias = articulosInsumos.filter(v => !v.cantidad && v.activo)
                const comentariosVacios = articulosInsumos.filter(v => v.comentarios === '' && !v.activo)

                console.log('cantidad vacia', cantidadVacias);
                if (cantidadVacias.length > 0) {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Agregue una cantidad en todos los Articulos/Insumos seleccionados`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (comentariosVacios.length > 0) {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Agregue un comentario en ${comentariosVacios > 1 ? 'los Articulos/Insumos seleccionados' : 'el Articulo/Insumo seleccionado'}`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }
            }

            if (valor === 5) {
                return notie.input({
                    text: 'Se es necesario justificar la razón de la cancelación ¿Desea continuar?',
                    submitText: 'Aceptar',
                    cancelText: 'Cancelar',
                    submitCallback: function (value) {
                        if (value === '') {
                            setTimeout(() => {
                                estatusEnviar(valor)
                            }, 2000);

                            return notie.alert({
                                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                                text: `Ingrese un comentario`,
                                // stay: Boolean, // optional, default = false
                                time: 2, // optional, default = 3, minimum = 1,
                                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                            })
                        }

                        enviarCorreo(valor, value)
                    },
                    maxlength: '150',
                    type: 'text',
                    placeholder: 'Comentarios'
                })
            }

            return notie.confirm({
                text: `Al cambiar el estatus a "${estatus[valor - 1].titulo}" se le notificará a los almacenes correspondientes ¿Desea continuar?`,
                submitText: 'Aceptar',
                cancelText: 'Cancelar',
                submitCallback: () => {
                    enviarCorreo(valor)
                }
            })
        }
        enviarCorreo(valor)
    }

    const descargarPdf = () => {
        setTextButton(l => ({ ...l, descargaPdf: 'Descargando...' }))

        fetch('/rt_requisiciones_pdf', {
            method: 'POST',
            headers: { 'Content-Type': 'application/json' },
            body: JSON.stringify({
                id: params.id
            })
        }).then(response => {
            setTextButton(l => ({ ...l, descargaPdf: 'Descargar PDF' }))
            response.blob().then(blob => {
                let url = window.URL.createObjectURL(blob);
                let a = document.createElement('a');
                a.href = url;
                a.download = `RequisicionSurtido${params.id}.pdf`;
                a.click();
            }).catch(error => {
                notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
        });

        // Tools.toolFetch({
        //     ruta: 'rt_requisiciones_pdf',
        //     method: 'GET',
        //     // body: { id: params.id, articulosInsumos, estatus: valor, comentarios },
        // }, (response) => {
        //     console.log(response);
        //     if (response.status === 'ERROR') {
        //         return notie.alert({
        //             type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //             text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
        //             // stay: Boolean, // optional, default = false
        //             time: 2, // optional, default = 3, minimum = 1,
        //             position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //         })
        //     }

        //     response.blob().then(blob => {
        //         let url = window.URL.createObjectURL(blob);
        //         let a = document.createElement('a');
        //         a.href = url;
        //         a.download = 'test-archivo.pdf';
        //         a.click();
        //     });

        //     notie.alert({
        //         type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //         text: `Descargando...`,
        //         // stay: Boolean, // optional, default = false
        //         time: 2, // optional, default = 3, minimum = 1,
        //         position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //     })
        // })
    }

    const guardarSeleccionados = () => {
        setListArtIns(listArtInsCopia)
        setModal5(false)
        setEsRefresh(!esRefresh)
    }

    return (
        <Fragment>
            {!esPlantilla && !rutaPlantillas ?
                <Fragment>
                    <div className='card'>
                        {esEditar && !rutaPlantillas && valorEstatus.includes(formReq.ESTATUS_RQI) && permisoAlmacen ?
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Estatus</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="mt-3 px-4">
                                            <div className='input-field select'>
                                                <Select
                                                    data-label="estatus"
                                                    id="clave_estatus"
                                                    defaultOptOff={true}
                                                    value={formReq.ESTATUS_RQI}
                                                    datos={ctgEstatus.map(e => ({ clave: e.VALOR_EST, label: e.NOMBRE_EST }))}
                                                    onChange={(e) => {
                                                        // console.log(e.item.clave);
                                                        console.log(e.item.clave);
                                                        estatusEnviar(e.item.clave)
                                                    }}
                                                    required
                                                    spellcheck="false"
                                                />
                                                <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Estatus <span className="text-danger">*</span></label>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            : ''}
                        <div className="card-body">
                            <div className='row'>
                                <div className="col-md-12 mb-3">
                                    <h6><strong>Almacenes</strong></h6>
                                    <hr />
                                </div>
                                <div className="col-md-12 mb-3">
                                    <div className="mt-3 px-4">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Auditor"
                                                defaultOptOff={/*ctgUcAlmacenes.length > 1 && */!esEditar ? false : true}
                                                value={formReq ? formReq.ID_ALM_RQI : ''}
                                                datos={ctgUcAlmacenes.filter(e => esEditar ? formReq.ID_ALM_RQI == e.ID_ALM : e.ID_ALM).map((e) => { return { clave: e.ID_ALM, label: e.TITULO_ALM } })}
                                                onChange={(e) => { setDataForm(l => { return { ...l, clave_almacen: e.item.clave } }) }}
                                                // required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Almacen Solicitante<span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                    <div className="mt-3 px-4">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Proveedores"
                                                // defaultOptOff={ctgAlmacenes.length > 1 && !esEditar ? false : true}
                                                defaultOptOff={/*ctgUcAlmacenes.length > 1 && */!esEditar ? false : true}
                                                value={formReq ? formReq.ID_ALM_SURTIDO_RQI : ''}
                                                datos={almacenesSur.filter(e => esEditar ? formReq.ID_ALM_SURTIDO_RQI == e.ID_ALM : e.ID_ALM).map((e) => { return { clave: e.ID_ALM, label: e.TITULO_ALM } })}
                                                onChange={(e) => { setDataForm(l => { return { ...l, clave_almacen_surtido: e.item.clave } }) }}
                                                // required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Almacen Surtidor <span className="text-danger">*</span></label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    {!esEditar ?
                        <div className='card'>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Autorización</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <InputLabel
                                            checked={formReq.REQUIERE_AUTORIZAR_RQI ? true : false}
                                            opcion={'Requiere autorización'}
                                            callback={(checked) => { setDataForm(l => ({ ...l, check_autorizar: checked })) }}
                                        />
                                    </div>
                                </div>
                            </div>
                        </div>
                        : ''}
                </Fragment>
                : ''
            }
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    <div className="card-header d-flex justify-content-center p-3">
                        <h4>Insumos/Articulos</h4>
                    </div>
                    <div className="mt-3 px-4">
                        <Modal isOpen={modal5} toggle={toggle5}>
                            <ModalHeader toggle={toggle5}>Unidada de medida</ModalHeader>
                            <ModalBody>
                                <div className="mt-3 px-4">
                                    <div className='input-field select'>
                                        <Select
                                            data-label="Auditor"
                                            defaultOptOff={true}
                                            datos={[{ clave: 1, label: 'Insumos' }, { clave: 2, label: 'Articulos' }]}
                                            value={tipoVal}
                                            onChange={(e) => {
                                                setPrVal(0)
                                                setTipoVal(parseInt(e.item.clave))
                                            }}
                                            // required
                                            spellcheck="false"
                                        />
                                        <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Articulos/Insumos <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                <div className="mt-3 px-4">
                                    <div className='input-field select'>
                                        <Select
                                            data-label="Proveedores"
                                            datos={ctgPrv.map(v => { return { clave: v.ID_PRV, label: v.TITULO_PRV } })}
                                            onChange={(e) => {
                                                setPrVal(e.item.clave == '' ? 0 : e.item.clave)
                                                setEsRefresh(!esRefresh)
                                            }}
                                            // required
                                            spellcheck="false"
                                        />
                                        <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Proveedores <span className="text-danger">*</span></label>
                                    </div>
                                </div>
                                {tipoVal == 1 ?
                                    <Table
                                        placeholderSearch={'Buscar insumos...'}
                                        noRutaDinamica={true}
                                        cargando={false}
                                        headers={[
                                            {
                                                nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                                    return <div style={{ textAlign: 'center', width: '8em' }}>
                                                        <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.NOMBRE_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'MEDIDA MAXIMA', tipo: 'html', texto: 'NOMBRE_MAX_CU', html: (d) => {
                                                    return <div style={{ width: '8em' }}>
                                                        <strong>{d.NOMBRE_MAX_CU}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'MEDIDA MINIMA', tipo: 'html', texto: 'NOMBRE_MIN_CU', html: (d) => {
                                                    return <div style={{ width: '8em' }}>
                                                        <strong>{d.NOMBRE_MIN_CU}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'PROVEEDOR', tipo: 'html', texto: 'TITULO_PRV', html: (d) => {
                                                    return <div>
                                                        <strong>{d.TITULO_PRV}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                                    setListArtInsCopia(l => {
                                                        l[`${d.ID_INA}_${d.ID_PRV}`] = !l[`${d.ID_INA}_${d.ID_PRV}`] ? { id: d.ID_INA, id_prv: d.ID_PRV, cantidad: 0, activo: 1 } : false
                                                        return l
                                                    })
                                                }
                                            },
                                        ]}
                                        totalField={'NR'} /* NR = número de resultados totales */
                                        path={'/rt_requisiciones_artins_list'}
                                        refresh={esRefreshCopia}
                                        body={{ tipo: 1, valPrv: prVal, seleccionados: listArtIns }}
                                        paginadorTop={true}
                                    >
                                    </Table> : ''
                                }
                                {tipoVal === 2 ?
                                    <Table
                                        placeholderSearch={'Buscar articulos...'}
                                        noRutaDinamica={true}
                                        cargando={false}
                                        headers={[
                                            {
                                                nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                                    return <div style={{ textAlign: 'center', width: '8em' }}>
                                                        <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.NOMBRE_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'MEDIDA MAXIMA', tipo: 'html', texto: 'NOMBRE_MAX_CU', html: (d) => {
                                                    return <div style={{ width: '8em' }}>
                                                        <strong>{d.NOMBRE_MAX_CU}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'MEDIDA MINIMA', tipo: 'html', texto: 'NOMBRE_MIN_CU', html: (d) => {
                                                    return <div style={{ width: '8em' }}>
                                                        <strong>{d.NOMBRE_MIN_CU}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'PROVEEDOR', tipo: 'html', texto: 'TITULO_PRV', html: (d) => {
                                                    return <div>
                                                        <strong>{d.TITULO_PRV}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                                    setListArtInsCopia(l => {
                                                        l[`${d.ID_INA}_${d.ID_PRV}`] = !l[`${d.ID_INA}_${d.ID_PRV}`] ? { id: d.ID_INA, id_prv: d.ID_PRV, cantidad: 0, activo: 1 } : false
                                                        return l
                                                    })
                                                }
                                            },
                                        ]}
                                        totalField={'NR'} /* NR = número de resultados totales */
                                        path={'/rt_requisiciones_artins_list'}
                                        refresh={esRefreshCopia}
                                        body={{ tipo: 2, valPrv: prVal, seleccionados: listArtInsCopia }}
                                        paginadorTop={true}
                                    >
                                    </Table> : ''
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={guardarSeleccionados}>Guardar</Button>
                                <Button color="secondary" onClick={toggle5}>Cancelar</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    {/* {!rutaPlantillas && !esEditar ?
                        <Fragment>
                            
                        </Fragment>
                        : ''} */}
                    <div className="mt-3 px-4">
                        <Table
                            placeholderSearch={'Buscar seleccionados...'}
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <Fragment>
                                    {!rutaPlantillas && !esEditar ?
                                        <div className="btn-container">
                                            <button className='btn-nuevo' onClick={toggle5}>
                                                <span className="text">Agregar</span>
                                                <div className="icon-container">
                                                    <div className="icon icon--left">
                                                    </div>
                                                    <div className="icon icon--right">
                                                    </div>
                                                </div>
                                            </button>
                                        </div>
                                        : ''}
                                    {formReq.ESTATUS_RQI === 4 ?
                                        <div className="btn-container">
                                            <button className='btn-nuevo' onClick={() => {
                                                descargarPdf()
                                            }}>
                                                <span className="text">{textButton.descargaPdf}</span>
                                            </button>
                                        </div>
                                        : ''}
                                </Fragment>
                            }
                            headers={[
                                {
                                    nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                        return <div style={{ textAlign: 'center', width: '8em' }} >
                                            <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                        return <div>
                                            <strong>{d.NOMBRE_INA}</strong><br />
                                        </div>
                                    }
                                },
                                // {
                                //     nombre: 'TITULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                //         return <div>
                                //             <strong>{d.TITULO_INA}</strong><br />
                                //         </div>
                                //     }
                                // },
                                {
                                    nombre: 'PROVEEDOR', tipo: 'html', texto: 'TITULO_PRV', html: (d) => {
                                        return <div>
                                            <strong>{d.TITULO_PRV}</strong><br />
                                        </div>
                                    }
                                },
                                // {
                                //     nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                //         return <div style={{ width: '8em' }}>
                                //             <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                //         </div>
                                //     }
                                // },
                                ...cantidades.map(e => e.condicion ?
                                    {
                                        nombre: e.nombre,
                                        tipo: 'input',
                                        disabled: !e.disabled,
                                        texto: e.texto,
                                        valueOp: (d, v) => {
                                            const cantidad = {}
                                            cantidad[e.texto] = v > 0 ? v : 1

                                            setListArtIns(l => {
                                                l[`${d.ID_INA}_${d.ID_PRV}`].cantidad = v

                                                console.log(l);
                                                return l
                                            })

                                            return cantidad
                                        }
                                    }
                                    : {}),
                                {
                                    nombre: 'MEDIDA MAXIMA', tipo: 'html', texto: 'NOMBRE_MAX_CU', html: (d) => {
                                        return <div style={{ width: '7em' }}>
                                            <strong>{d.NOMBRE_MAX_CU}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'MEDIDA MINIMA', tipo: 'html', texto: 'NOMBRE_MIN_CU', html: (d) => {
                                        return <div style={{ width: '7em' }}>
                                            <strong>{d.NOMBRE_MIN_CU}</strong><br />
                                        </div>
                                    }
                                },
                                ((!rutaPlantillas && !esEditar) || ([1, 2].includes(formReq.ESTATUS_RQI)/*formReq.ESTATUS_RQI === 2*/ && !rutaPlantillas && esEditar && permisoAlmacen)) ? {
                                    nombre: 'SELECCIONAR',
                                    tipo: 'check',
                                    texto: 'ID_INA',
                                    inputCom: ([1, 2].includes(formReq.ESTATUS_RQI) && !rutaPlantillas && esEditar && permisoAlmacen) ? true : false,
                                    callback: (d) => {
                                        if (!rutaPlantillas && esEditar && permisoAlmacen) {
                                            return setListArtIns(l => {
                                                l[`${d.ID_INA}_${d.ID_PRV}`] = {
                                                    ...l[`${d.ID_INA}_${d.ID_PRV}`],
                                                    activo: !l[`${d.ID_INA}_${d.ID_PRV}`].activo,
                                                    comentario: ''
                                                }
                                                return l
                                            })
                                        }

                                        setListArtIns(l => {
                                            l[`${d.ID_INA}_${d.ID_PRV}`] = !l[`${d.ID_INA}_${d.ID_PRV}`] ? { id: d.ID_INA, id_prv: d.ID_PRV, cantidad: 0 } : false
                                            return l
                                        })
                                        setEsRefresh(l => !l)
                                    },
                                    callBackComentario: (d) => {
                                        setListArtIns(l => {
                                            l[`${d.ID_INA}_${d.ID_PRV}`] = { ...l[`${d.ID_INA}_${d.ID_PRV}`], comentarios: d.comentario }

                                            console.log(l);
                                            return l
                                        })
                                    }
                                } : {},
                                [3, 4].includes(formReq.ESTATUS_RQI) ?
                                    {
                                        nombre: 'ESTATUS', tipo: 'html', texto: 'ESTATUS_RQI', html: (d) => {
                                            return <div style={{ width: '8em' }}>
                                                {d.ACTIVO_SEL ?
                                                    <span className="badge badge-success">Aceptado</span>
                                                    : <span className="badge badge-primary">Cancelado</span>
                                                }
                                            </div>
                                        }
                                    } : {}
                            ].filter(v => Object.keys(v).length > 0)}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_requisiciones_artins_list'}
                            refresh={esRefresh}
                            body={{ tipo: 0, valPrv: prVal, seleccionados: listArtIns }}
                            inicioComentarios={![1].includes(formReq.ESTATUS_RQI) && !rutaPlantillas && esEditar}
                            callBackComentario={(d) => {
                                setListArtIns(l => {
                                    l[`${d.ID_INA}_${d.ID_PRV}`] = { ...l[`${d.ID_INA}_${d.ID_PRV}`], comentarios: d.comentario }

                                    console.log(l);
                                    return l
                                })
                            }}
                            paginadorTop={true}
                        >
                        </Table>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="justify-content-end d-flex">
                            <div style={{ display: 'flex', justifyContent: 'space-between' }}>
                                {((!esEditar || (esEditar && rutaPlantillas)) && (GetPermisos.find(permiso => [29].includes(permiso)) ? true : false)) ?
                                    <button className='btn-nuevo' type='button'
                                        onClick={onClickValid}
                                    >
                                        <span className="text">Guardar</span>
                                        <div className="icon-container">
                                            <div className="icon icon--left">
                                            </div>
                                            <div className="icon icon--right">
                                            </div>
                                        </div>
                                    </button>

                                    : ''
                                }
                                {esEditar && !rutaPlantillas && [1, 2].includes(formReq.ESTATUS_RQI) /*&& !permisoAlmacen*/ && (GetPermisos.find(permiso => [2066].includes(permiso)) ? true : false) ?
                                    // <button className='btn-nuevo' type='button' disabled={optionBtn}
                                    //     onClick={onClickEnviar} style={{ marginLeft: '20px' }}
                                    // >
                                    //     <span className="text">{optionBtn ? 'Enviando...' : 'Enviar'}</span>
                                    //     <div className="icon-container">
                                    //         <div className="icon icon--left">
                                    //         </div>
                                    //         <div className="icon icon--right">
                                    //         </div>
                                    //     </div>
                                    // </button>
                                    ''
                                    : ''
                                }
                                {esEditar && !rutaPlantillas && valorEstatus.includes(formReq.ESTATUS_RQI) /*&& (GetPermisos.find(permiso => [2066].includes(permiso)) ? true : false) */ ?
                                    ''
                                    // <button className='btn-nuevo' type='button' disabled={optionBtn}
                                    //     onClick={() => { estatusEnviar(formReq.ESTATUS_RQI) }} style={{ marginLeft: '20px' }}
                                    // >
                                    //     <span className="text">{optionBtn ? 'Enviando...' : 'Reenviar correo'}</span>
                                    //     <div className="icon-container">
                                    //         <div className="icon icon--left">
                                    //         </div>
                                    //         <div className="icon icon--right">
                                    //         </div>
                                    //     </div>
                                    // </button>
                                    : ''
                                }
                                {esEditar && !rutaPlantillas && [1, 2].includes(formReq.ESTATUS_RQI) && permisoAlmacen ?
                                    <Fragment>
                                        <button className='btn-nuevo' type='button' disabled={optionBtn}
                                            onClick={() => { estatusEnviar(5) }} style={{ marginLeft: '20px' }}
                                        >
                                            <span className="text">{'Cancelar Reqisición'}</span>
                                            <div className="icon-container">
                                                <div className="icon icon--left">
                                                </div>
                                                <div className="icon icon--right">
                                                </div>
                                            </div>
                                        </button>
                                        {formReq.ESTATUS_RQI === 2 ?
                                            <button className='btn-nuevo' type='button' disabled={optionBtn}
                                                onClick={() => { estatusEnviar(3) }} style={{ marginLeft: '20px' }}
                                            >
                                                <span className="text">{optionBtn ? 'Enviando...' : 'Procesar requisición'}</span>
                                                <div className="icon-container">
                                                    <div className="icon icon--left">
                                                    </div>
                                                    <div className="icon icon--right">
                                                    </div>
                                                </div>
                                            </button>
                                            : ''}
                                    </Fragment>
                                    : ''
                                }
                                {[1].includes(formReq.ESTATUS_RQI) && (GetPermisos.find(permiso => [2066].includes(permiso)) ? true : false) && esEditar && !rutaPlantillas ?
                                    <Fragment>
                                        <button className='btn-nuevo' type='button'
                                            onClick={() => { estatusEnviar(2) }}
                                            style={{ marginLeft: '20px' }}
                                        >
                                            <span className="text">Autorizar</span>
                                            <div className="icon-container">
                                                <div className="icon icon--left">
                                                </div>
                                                <div className="icon icon--right">
                                                </div>
                                            </div>
                                        </button>
                                    </Fragment>

                                    : ''}
                            </div>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >
    );
}

export default Default
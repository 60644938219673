import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { GetPermisos } from '../common/getPermisos'
import { useNavigate, useParams } from 'react-router-dom'
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar }) => {

    const [formAlm, setFormAlm] = useState({})
    const [ctgEstados, setCtgEstados] = useState([])
    const [ctgDireccionFiscal, setCtgDireccionFiscal] = useState([])
    const [valEstado, setValEstado] = useState([])
    const [valDepartamento, setValDepartamento] = useState([])
    const [valDireccionFiscal, setValDireccionFiscal] = useState([])
    const [ctgCiudades, setCtgCiudades] = useState([])
    const [ctgDepartamentos, setCtgDepartamentos] = useState([])
    const [valCiudad, setValCiudad] = useState([])
    const navigate = useNavigate()
    const params = useParams();

    const [catalogos, setCatalogos] = useState({})

    useEffect(() => {
        return Tools.toolFetch({
            ruta: 'rt_sucursales_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            console.log('el response', response);

            if (response.status === 'OK') {
                setCtgEstados(response.estados.datos)
                setCtgCiudades(response.ciudades.datos)
                setCtgDepartamentos(response.departamentos.datos)
                setCtgDireccionFiscal(response.dirFisc)
                if (esEditar) {
                    setFormAlm(response.result)
                    setValDepartamento(response.result.ID_DEP_SUC)
                    setValEstado(response.result.ID_CES_SUC)
                    setValCiudad(response.result.ID_CCD_SUC)
                    setValDireccionFiscal(response.result.ID_DIF_SUC)
                } else {
                    setFormAlm({})
                    setValDepartamento(0)
                    setValEstado(0)
                    setValCiudad(0)
                }
            }
        })
    }, [esEditar])

    const onChangeEstados = (clave) => {
        setValEstado(clave)
        Tools.toolFetch({
            ruta: 'rt_sucursales_change_estados',
            method: 'POST',
            body: { clave },
        }, (response) => {
            console.log(response);

            if (response.estatus === 'OK') {
                setCtgCiudades(response.datos)
            }
        })
    }

    const onClickAlm = () => {
        const form = Tools.getForm('formAlm', { ignore: ['txt_num_ext'] })
        console.log(form.data);

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        form.data.id = params.id
        Tools.toolFetch({
            ruta: !esEditar ? 'rt_sucursales_nuevo' : 'rt_sucursales_editar',
            method: 'POST',
            body: form.data,
        }, (response) => {
            console.log(response)

            if (response.status === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setTimeout(() => {
                navigate('/sucursales')
            }, 2000);

            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Los datos se han guardado correctamente`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Briefcase} title={'Sucursales'} parent={[{ titulo: 'sucursales', link: '/sucursales' }, { titulo: `${esEditar ? 'editar' : 'nueva'} sucursal`, link: `/sucursales/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formAlm' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/sucursales')
                                }}></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVA'} SUCURSAL</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales de la sucursal</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.NOMBRE_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-building" aria-hidden="true"></i> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_titulo" name="txt_titulo"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.TITULO_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Título<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Título)</strong></span>
                                    </div>
                                    {/* <div classNamjue57 me="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Departamentos"
                                                placeholder="Seleccionar departamento"
                                                id="clave_departamento"
                                                name="clave_departamento"
                                                value={valDepartamento}
                                                datos={ctgDepartamentos}
                                                onChange={(e) => { setValDepartamento(e.item.clave) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Departamento<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_departamento" style={{ display: 'none' }}><strong>Falta completar (Departamento)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Estado"
                                                placeholder="Seleccionar estado"
                                                id="clave_estado"
                                                name="clave_estado"
                                                value={valEstado}
                                                datos={ctgEstados}
                                                onChange={(e) => {
                                                    onChangeEstados(e.item.clave)
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Estado<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_estado" style={{ display: 'none' }}><strong>Falta completar (Estado)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Ciudad"
                                                placeholder="Seleccionar ciudad"
                                                id="clave_ciudad"
                                                name="clave_ciudad"
                                                value={valCiudad}
                                                datos={ctgCiudades}
                                                onChange={(e) => { setValCiudad(e.item.clave) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Ciudad<span className="text-danger">*</span></label>
                                        </div>
                                        {/* <div className="input-group inputubicacion">
                                            <div className="input-group-prepend"></div>
                                            <select className='form-select' onChange={() => null} value={valCiudad} data-label='tipo de usuario' name='clave_ciudad' id='clave_ciudad'>
                                                <option hidden value={''}>Seleccione</option>
                                                {
                                                    ciudades.map(item =>
                                                        <option key={`key_option_tipos_${item.clave}`} value={item.clave}>{item.label}</option>
                                                    )
                                                }
                                            </select>
                                        </div> */}
                                        <span className="text-danger txtVacios clave_ciudad" style={{ display: 'none' }}><strong>Falta completar (Ciudad)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_calle" name="txt_calle"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.CALLE_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Calle<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_calle" style={{ display: 'none' }}><strong>Falta completar (Calle)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_num_int" name="txt_num_int"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.NUM_INT_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Numero interno<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_num_int" style={{ display: 'none' }}><strong>Falta completar (Número interno)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_num_ext" name="txt_num_ext"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.NUM_EXT_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Número externo</label>
                                        </div>
                                        {/* <span className="text-danger txtVacios txt_num_ext" style={{ display: 'none' }}><strong>Falta completar (Número externo)</strong></span> */}
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_col" name="txt_col"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.COLONIA_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Colonia<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_col" style={{ display: 'none' }}><strong>Falta completar (Colonia)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_cp" name="txt_cp"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.CP_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Codigo postal<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_cp" style={{ display: 'none' }}><strong>Falta completar (Codigo postal)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_ref" name="txt_ref"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.REFERENCIA_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Referencia<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_ref" style={{ display: 'none' }}><strong>Falta completar (Referencia)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_longitud" name="txt_longitud"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.NUM_EXT_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Longitud<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_longitud" style={{ display: 'none' }}><strong>Falta completar (Longitud)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_latitud" name="txt_latitud"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.LATITUD_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Latitud<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_latitud" style={{ display: 'none' }}><strong>Falta completar (Latitud)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Dirección Fiscal"
                                                placeholder="Seleccionar dirección fical"
                                                id="clave_dirfisc"
                                                name="clave_dirfisc"
                                                value={valDireccionFiscal}
                                                datos={ctgDireccionFiscal}
                                                onChange={(e) => {
                                                    setValDireccionFiscal(e.item.clave)
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-map-marker" aria-hidden="true"></i> Dirección fiscal<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_dirfisc" style={{ display: 'none' }}><strong>Falta completar ("Dirección Fiscal)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_telefono" name="txt_telefono"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.TELEFONO_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Teléfono<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_telefono" style={{ display: 'none' }}><strong>Falta completar (Teléfono)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_celular" name="txt_celular"
                                                type="text"
                                                defaultValue={formAlm ? formAlm.CELULAR_SUC : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-phone" aria-hidden="true"></i> Celular<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_celular" style={{ display: 'none' }}><strong>Falta completar (Celular)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            {(esEditar && (GetPermisos.find(permiso => [2070].includes(permiso)) ? true : false))
                                                || (!esEditar && (GetPermisos.find(permiso => [2069].includes(permiso)) ? true : false))
                                                ?
                                                <button className='btn-nuevo' type='button' onClick={onClickAlm}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button> : ''
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default
import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import Table from '../common/tabla'
import { GetPermisos } from '../common/getPermisos';
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({
    listArtIns,
    setListArtIns,
    formRec,
    esEditar,
    esHistorial,
    tiempos,
    setTiempos,
    setValueTab,
    setArtInsPag,
    listArtInsTipos,
    setListArtInsTipos
}) => {
    const [formFaIn, setFormFaIn] = useState({})
    const [listArtInsCopia, setListArtInsCopia] = useState({})
    const [datosArtIns, setDatosArtIn] = useState([])
    const [valArtIns, setValArtIns] = useState(1)
    const [esRefresh, setEsRefresh] = useState(true)
    const [esRefreshCopia, setEsRefreshCopia] = useState(true)
    const [modal5, setModal5] = useState();
    const [indexSel, setIndexSel] = useState(0)
    const [tiposTablas, setTiposTablas] = useState([])
    const [textot, setTextot] = useState('asadaw')
    const [check, setCheck] = useState(true)
    const [valArtInsTipos, setValArtInsTipos] = useState(0)

    const navigate = useNavigate()
    const params = useParams();

    const toggle5 = (index) => {
        setListArtInsCopia(!modal5 ? Object.assign({}, listArtInsTipos[index].artIns) : {})
        setValArtInsTipos(index)
        setValArtIns(1)
        setEsRefreshCopia(!esRefreshCopia)
        setModal5(!modal5)
    }

    useEffect(() => {
        console.log('entra');
        setListArtInsTipos([...listArtInsTipos.map(v => v)])
    }, [esRefresh])

    // useEffect(() => {
    //     if (!esEditar) return

    //     Tools.toolFetch({
    //         ruta: 'rt_familias_insumos_detalle',
    //         method: 'POST',
    //         body: params,
    //     }, (response) => {
    //         console.log(response);

    //         if (response.status === 'OK') {
    //             setFormFaIn(response.result[0])
    //             setValArtIns(response.result[0].TIPO_RRS)
    //         }
    //     })
    // }, [])

    const operacionRegistro = (d, obj, v) => {
        let registro = Object.assign({}, listArtIns[d.ID_INA])
        registro[obj] = parseFloat(v)

        let inv_in = parseFloat(registro.inv_in ? registro.inv_in : 0)
        let compra = parseFloat(registro.compra ? registro.compra : 0)
        let merma = parseFloat(registro.merma ? registro.merma : 0)
        let produccion = parseFloat(registro.produccion ? registro.produccion : 0)
        let total = parseFloat(formRec.COMPRA_RE ? formRec.COMPRA_RE : 0)
        let consumo = parseFloat(produccion * total)

        let inv_fin = inv_in + compra - merma - consumo
        registro = { ...registro, inv_in, compra, produccion, merma, consumo, inv_fin }

        setListArtIns(l => {
            l[d.ID_INA] = registro
            return l
        })

        return registro
    }

    const guardarSeleccionados = () => {
        setListArtInsTipos(l => {
            l[valArtInsTipos].artIns = listArtInsCopia
            l[valArtInsTipos].esRefresh = !l[valArtInsTipos].esRefresh
            return l
        })
        setModal5(false)
    }

    return (
        <Fragment>
            <div className='row'>
                <div className="mt-3 px-4">
                    <Modal isOpen={modal5} toggle={toggle5}>
                        <ModalHeader toggle={toggle5}>Agregar Articulos/Insumos</ModalHeader>
                        <ModalBody>
                            {/*!esHistorial ?*/
                                <div className='input-field select'>
                                    <Select
                                        data-label="Auditor"
                                        defaultOptOff={true}
                                        datos={[{ clave: 1, label: 'Insumos' }, { clave: 2, label: 'Articulos' }]}
                                        onChange={(e) => {
                                            setValArtIns(parseInt(e.item.clave))
                                            setEsRefreshCopia(!esRefreshCopia)
                                        }}
                                        spellcheck="false"
                                    />
                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Articulos/Insumos <span className="text-danger">*</span></label>
                                </div>
                                    /*: ''*/}
                            <Table
                                noRutaDinamica={true}
                                cargando={false}
                                headers={[
                                    {
                                        nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                            return <div style={{ textAlign: 'center' }}>
                                                <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.NOMBRE_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'TITULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                            return <div>
                                                <strong>{d.TITULO_INA}</strong><br />
                                            </div>
                                        }
                                    },
                                    {
                                        nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                            setListArtInsCopia(l => {
                                                l[d.ID_INA] = !l[d.ID_INA] ? { id: d.ID_INA, cantidad: 1, merma: 1, tipo: valArtInsTipos } : false
                                                return l
                                            })
                                        }
                                    },
                                ]}
                                totalField={'NR'} /* NR = número de resultados totales */
                                // path={'/rt_familias_insumos_list'}
                                path={'/rt_recetas_artins_list'}
                                refresh={esRefreshCopia}
                                body={{ tipo: valArtIns, seleccionados: listArtInsCopia }}
                                paginadorTop={true}
                            // results={(rows) => {
                            //     if (rows) {
                            //         rows.forEach(v => {
                            //             setListCheck(l => {
                            //                 l[v.ID_INA] = v.CHECKED
                            //                 return l
                            //             })
                            //         });
                            //     }
                            // }}
                            >
                            </Table>
                        </ModalBody>
                        <ModalFooter>
                            <Button color="primary" onClick={guardarSeleccionados}>Guardar</Button>
                            <Button color="secondary" onClick={toggle5}>Cancelar</Button>
                        </ModalFooter>
                    </Modal>
                </div>
                <div className="card">
                    <div className="col-xl-12 col-md-12">
                        <div className="btn-container">
                            <div className="card-header d-flex justify-content-center p-3">
                                <button className='btn-nuevo' onClick={() => {
                                    setListArtInsTipos(l => [...l, {
                                        titulo: '',
                                        index: l.length,
                                        esRefresh: true,
                                        artIns: {}
                                    }])
                                }}>
                                    <span className="text">Agregar un tiempo</span>
                                </button>
                            </div>
                        </div>
                    </div>
                </div>
                {listArtInsTipos.map((e, index) =>
                    <div className="card">
                        <div className="col-xl-12 col-md-12">
                            <div className="card-header d-flex justify-content-center p-3">
                                <div className='input-field'>
                                    <input autoComplete='off'
                                        data-label="titulo"
                                        // id="txt_titulo" name="txt_titulo"
                                        type="text"
                                        maxLength={10}
                                        placeholder='Titulo tipo:'
                                        value={e.titulo}
                                        onChange={(v) => {
                                            setListArtInsTipos(l => {
                                                l[index].titulo = v.target.value
                                                // l[index].esRefresh = !l[index].esRefresh
                                                return l
                                            })
                                            setEsRefresh(l => !l)
                                        }}
                                        required
                                        spellcheck="false"
                                    />
                                </div>
                            </div>
                            <div className="mt-3 px-4">
                                <Table
                                    noRutaDinamica={true}
                                    cargando={false}
                                    htmlBtnPag={
                                        <Fragment>
                                            {!esHistorial ?
                                                <div className="btn-container">
                                                    <button className='btn-nuevo' onClick={() => toggle5(index)}>
                                                        <span className="text">Agregar</span>
                                                        <div className="icon-container">
                                                            <div className="icon icon--left">
                                                            </div>
                                                            <div className="icon icon--right">
                                                            </div>
                                                        </div>
                                                    </button>
                                                </div>
                                                : ''}
                                        </Fragment>
                                    }
                                    headers={[
                                        {
                                            nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                                return <div style={{ textAlign: 'center' }}>
                                                    <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                                </div>
                                            }
                                        },
                                        {
                                            nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                                return <div>
                                                    <strong>{d.NOMBRE_INA}</strong><br />
                                                </div>
                                            }
                                        },
                                        {
                                            nombre: 'TÍTULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                                return <div>
                                                    <strong>{d.TITULO_INA}</strong><br />
                                                </div>
                                            }
                                        },
                                        {
                                            nombre: 'CANTIDAD',
                                            tipo: 'input',
                                            texto: 'CANTIDAD_SEL',
                                            disabled: esHistorial,
                                            valueOp: (d, v) => {
                                                const cantidad = {}
                                                cantidad['CANTIDAD_SEL'] = v > 0 ? v : 1

                                                setListArtInsTipos(l => {
                                                    l[index].artIns[d.ID_INA].cantidad = v
                                                    return l
                                                })

                                                return cantidad
                                            }
                                        },
                                        // {
                                        //     nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                        //         return <div>
                                        //             <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                        //         </div>
                                        //     }
                                        // },
                                        // {
                                        //     nombre: 'INVENTARIO INICIAL', tipo: 'input', texto: 'inv_in', valueOp: (d, v) => {
                                        //         return operacionRegistro(d, 'inv_in', v)
                                        //     }
                                        // },
                                        // {
                                        //     nombre: 'COMPRA', tipo: 'input', texto: 'compra', valueOp: (d, v) => {
                                        //         return operacionRegistro(d, 'compra', v)
                                        //     }
                                        // },
                                        // {
                                        //     nombre: 'PRODUCCION ', tipo: 'input', texto: 'produccion', valueOp: (d, v) => {
                                        //         return operacionRegistro(d, 'produccion', v)
                                        //     }
                                        // },
                                        {
                                            nombre: 'MINIMA', tipo: 'html', texto: 'UNIDAD_MINIMA', html: (d) => {
                                                return <div>
                                                    <strong>{d.UNIDAD_MINIMA}</strong><br />
                                                </div>
                                            }
                                        },
                                        {
                                            nombre: 'MAXIMA', tipo: 'html', texto: 'UNIDAD_MAXIMA', html: (d) => {
                                                return <div>
                                                    <strong>{d.UNIDAD_MAXIMA}</strong><br />
                                                </div>
                                            }
                                        },
                                        {
                                            nombre: 'MERMA',
                                            tipo: 'input',
                                            texto: 'MERMA_SEL',
                                            disabled: esHistorial,
                                            valueOp: (d, v) => {
                                                const merma = {}
                                                merma['MERMA_SEL'] = v > 0 ? v : 1

                                                setListArtInsTipos(l => {
                                                    l[index].artIns[d.ID_INA].merma = v
                                                    return l
                                                })

                                                return merma
                                            }
                                            // valueOp: (d, v) => {
                                            //     // return operacionRegistro(d, 'merma', v)
                                            // }
                                        },
                                        // {
                                        //     nombre: 'MINIMA', tipo: 'html', texto: 'UNIDAD_MINIMA', html: (d) => {
                                        //         return <div>
                                        //             <select value={2}>
                                        //                 <option value={1}>1</option>
                                        //                 <option value={2}>2</option>
                                        //                 <option value={3}>3</option>
                                        //             </select>
                                        //         </div>
                                        //     }
                                        // },
                                        // {
                                        //     nombre: 'PALEY1', tipo: 'input', texto: 'paley1', valueOp: (d, v) => {
                                        //         return operacionRegistro(d, 'paley1', v)
                                        //     }
                                        // },
                                        // {
                                        //     nombre: 'PALEY2', tipo: 'input', texto: 'paley2', valueOp: (d, v) => {
                                        //         return operacionRegistro(d, 'paley2', v)
                                        //     }
                                        // },
                                        // {
                                        //     nombre: 'CONOSUMO', tipo: 'input', texto: 'consumo', disabled: true
                                        // },
                                        // {
                                        //     nombre: 'INVENTARIO FINAL', tipo: 'input', texto: 'inv_fin', disabled: true
                                        // },
                                        !esHistorial ? {
                                            nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                                setEsRefresh(v => !v)
                                                setListArtInsTipos(l => {
                                                    l[index].artIns[d.ID_INA] = !l[index].artIns[d.ID_INA] ? { id: d.ID_INA } : false
                                                    l[index].esRefresh = !l[index].esRefresh

                                                    return l
                                                })
                                            }
                                        } : {},
                                    ]}
                                    totalField={'NR'} /* NR = número de resultados totales */
                                    path={'/rt_recetas_artins_list'}
                                    refresh={listArtInsTipos[index].esRefresh}
                                    body={{ tipo: 0, seleccionados: e.artIns }}
                                    // results={(result = []) => {
                                    //     setDatosArtIn(result)
                                    //     setTiempos(l => {
                                    //         l.forEach(e => {
                                    //             e.artins = e.artins ? [...e.artins, ...result.filter(k => !e.artins.map(j => j.ID_INA).includes(k.ID_INA))] : result
                                    //         });
                                    //         return l
                                    //     })
                                    //     // setDatosArtIn(l => ([...l, ...result.filter(e => !l.map(j => j.ID_INA).includes(e.ID_INA))]))
                                    // }}
                                    rowsPerPage={20}
                                    paginadorTop={true}
                                >
                                </Table>
                            </div>
                            {index === listArtInsTipos.length - 1 ?
                                <div className="col-md-12 mb-3">
                                    <div className="justify-content-end d-flex">
                                        {(!esHistorial && esEditar && (GetPermisos.find(permiso => [2061].includes(permiso)) ? true : false)) ||
                                            (!esEditar && (GetPermisos.find(permiso => [31].includes(permiso)) ? true : false)) ?
                                            <button
                                                className='btn-nuevo'
                                                type='button'
                                                onClick={() => { setArtInsPag(l => !l) }}
                                            >
                                                <span className="text">Guardar</span>
                                                <div className="icon-container">
                                                    <div className="icon icon--left">
                                                    </div>
                                                    <div className="icon icon--right">
                                                    </div>
                                                </div>
                                            </button> : ''}
                                    </div>
                                </div>
                                : ''}
                        </div>
                    </div >
                )}
            </div>
        </Fragment >
    );
}

export default Default
import React, { Fragment, useEffect, useState } from "react";
import { Pagination, PaginationItem, PaginationLink } from "reactstrap";
import { useLocation } from "react-router-dom";
import NoImg from '../../assets/images/product/14.png'
import Select from "react-select";
import { formatoDinero } from "../../js/funciones";
import tools from "../../js/tools";

const TablaPro = (props) => {
    const noRutaDinamica = props.noRutaDinamica
    /* ============== PROPIEDADES ============== */
    const opcionesCantidadResultados = props.resultsOptions || [
        { value: "10", label: "10" },
        { value: "20", label: "20" },
        { value: "30", label: "30" },
        { value: "40", label: "40" },
    ];

    const propRow = props.row ? props.row : {}

    /* 	const clasesTipos = {
            "0": "pendiente",
            "1": "transferencia",
            "2": "paypal",
            "3": "link",
            "4": "terminal",
            "5": "pendiente",
            "6": "terminal",
        }
    
        const clasesEstatus = {
            "danger": "cancelado",
            "1": "pendiente",
            "2": "pagado",
            "3": "revisar",
            "4": "pendiente",
            "5": "cortesia",
        }
     */
    const [loading, setLoading] = useState(props.loading || true);

    const [headers, setHeaders] = useState(props.headers);// props.headers;

    const [rows, setRows] = useState(props.rows || []);

    const [path, setPath] = useState(props.path);

    const [method, setMethod] = useState(props.method || 'POST');

    const [results, setResults] = useState(props.results)

    const [checked, setChecked] = useState({});
    const [operacion, setOperacion] = useState({});
    const [totalChecked, setTotalChecked] = useState(0);
    const [checkedTotal, setCheckedTotal] = useState(0);
    const [comentRow, setComentRow] = useState({})

    // const [body, setBody] = useState(props.body);
    let callBack = props.results
    let body = props.body;

    const [totalField] = useState(props.totalField || 'CANTIDAD_RESULTADOS')

    // Paginador
    const [dataPagination, setDataPagination] = useState(props.dataPagination || {
        page: 1,
        pages: [],
        lastPage: 1,
        rowsPerPage: props.rowsPerPage ? props.rowsPerPage : 10
    })

    // query params
    const search = useLocation().search;
    const search_params = new URLSearchParams(search);
    const ruta = useLocation().pathname;

    let timeout, busqueda_pendiente = '';
    const [filtros, setFiltros] = useState({
        b: ''
    })

    const estatus = [{ clase: 'digits btn-success', mensaje: 'Seleccionado', check: 'on' }, { clase: 'digits btn-primary', mensaje: 'Seleccionar', check: '' }]

    /* ============== USEEFFECT ============== */

    useEffect(() => {
        setHeaders(props.headers)
    }, [props.headers.length])

    useEffect(() => {
        if (props.inicioComentarios && rows.length > 0 && !Object.keys(comentRow).length) {
            const comentarios = {}
            rows.forEach((fila, i) => {
                console.log('fila', fila);

                comentarios['coment-row-' + i] = fila.COMENTARIOS ? { comentario: fila.COMENTARIOS, callBackComentario: props.callBackComentario } : null
            })
            setComentRow(comentarios)
        }
    }, [rows])

    useEffect(() => {
        /*	for (param of temp1.keys()) {
            console.log(param)
        } */
        // const ac = new AbortController();
        // if (props.inicioComentarios) {
        //     console.log('Estos son los seleccionados', props.body.seleccionados);
        // }

        setHeaders(props.headers)
        construirElementos()
        let isSubscribed = true
        let pag = getParamsJson(window.location.href)

        let s_pagina = pag.pagina ? pag.pagina : search_params.get('page'),
            s_filas = search_params.get('rowsPerPage');

        if (isSubscribed) {
            if (search_params.get('page') < 2) {
                s_pagina = 1
            }

            cargarRegistros(s_pagina || 1, s_filas || dataPagination.rowsPerPage)
        }

        return () => isSubscribed = false
        // return () => ac.abort();
    }, [props.refresh, filtros.b, dataPagination.rowsPerPage])

    /* ============== FUNCIONES ============== */

    const selectCantidadResultados = (value) => {
        console.log('elementos por pagina', value);


        setDataPagination((s) => ({ ...s, rowsPerPage: value.value }));
    }

    const onClickChecked = (fila) => {
        let claveChecked = fila['CLAVE_CHECK']
        let item = `{"${claveChecked}":${JSON.stringify(fila)}}`

        if (fila.checked) {
            item = `{"${claveChecked}":0}`
            delete checked[claveChecked]
        }

        item = JSON.parse(item)

        let total = 0
        setChecked((s) => {
            total = Object.values({ ...s, ...item }).filter((v) => v != 0).length
            setTotalChecked(total)
            if (item[claveChecked]) {
                return { ...checked, ...item }
            } else {
                return { ...checked }
            }
        })
        let _rows = rows.map((v) => ({ ...v, checked: v.CLAVE_CHECK === claveChecked ? item[v.CLAVE_CHECK] : v.checked }))
        setRows(_rows)
    }

    const onChangeInput = (fila, registro) => {
        let claveChecked = fila['CLAVE_CHECK']
        let _rows = rows.map((v) => {
            return v.CLAVE_CHECK === claveChecked ? { ...v, ...registro } : v
        })
        setRows(_rows)
    }

    const construirElementos = () => {
        if (rows.length == 0) {
            return <tr key={`key_fila_tabla`} style={{ cursor: 'pointer' }}>
                <td colSpan={headers.length} key={`key_fila_tabla_td`} 					>
                    <div className="text-center">
                        <h5>Sin registros</h5>
                    </div>
                </td>
            </tr>
        }

        // if (props.inicioComentarios) {
        //     const comentarios = {}
        //     rows.forEach((fila, i) => {
        //         comentarios['coment-row-' + i] = fila.COMENTARIOS !== '' ? { comentario: fila.COMENTARIOS, callBackComentario: props.callBackComentario } : null
        //     })
        //     console.log(comentarios);
        //     // setComentRow(comentarios)
        // }

        return rows.map((fila, i) => {
            return (<Fragment>
                <tr key={`key_fila_tabla${i}`} style={{ cursor: 'pointer' }}>
                    {
                        headers.map((h, j) => {
                            if (h.tipo === 'estatus') {
                                return <td
                                    style={h.style ? h.style : {}}
                                    key={`key_fila_tabla_td${i}${j}`}
                                >
                                    <div className='input-field'>

                                    </div>
                                </td>
                            }
                            if (h.tipo === 'input') {
                                return <td
                                    style={h.style ? h.style : {}}
                                    key={`key_fila_tabla_td${i}${j}`}
                                >
                                    <div className='input-field' style={{ width: '7em' }}>
                                        <input autoComplete='off'
                                            data-label="fecha"
                                            type="number"
                                            value={fila[h.texto]}
                                            required
                                            spellcheck="false"
                                            disabled={h.disabled ? true : false}
                                            onChange={(e) => { onChangeInput(fila, h.valueOp(fila, e.target.value)) }}
                                        />
                                    </div>
                                </td>
                            }
                            if (h.tipo === 'check') {
                                const statusCheckLabel = (checkedTotal ? (fila.checked ? estatus[1] : checked[fila.CLAVE_CHECK] ? estatus[1] : estatus[0]) : (fila.checked ? estatus[0] : checked[fila.CLAVE_CHECK] ? estatus[0] : estatus[1]))

                                return <td
                                    style={h.style ? h.style : {}}
                                    key={`key_fila_tabla_td${i}${j}`}
                                >
                                    <div className="form-check">
                                        {/* <span className={"btn btn-square " + statusCheckLabel.clase} style={{ width: 150, }}> */}
                                        {/* <input
                                            id={`check_table_${i}_${j}`}
                                            type="checkbox"
                                            style={h.chkStyle ? h.chkStyle : {}}
                                            className="checkbox_animated"
                                            checked={statusCheckLabel.check}
                                            onChange={(e) => {
                                                if (h.inputCom) {
                                                    setComentRow(l => {
                                                        l['coment-row-' + i] = !e.target.checked ? { comentario: '', callBackComentario: props.callBackComentario } : null
                                                        return l
                                                    })
                                                }
                                            }}
                                        /> */}
                                        <button
                                            className={`btn btn-outline-${statusCheckLabel.check ? 'success' : 'info'} fa fa-${statusCheckLabel.check ? 'check' : 'plus'}`}
                                            id={`check_table_${i}_${j}`}
                                            type="checkbox"
                                            style={h.chkStyle ? h.chkStyle : {}}
                                            checked={statusCheckLabel.check}
                                            onClick={(e) => {
                                                onClickChecked(fila)
                                                h.callback(fila)
                                                if (h.inputCom) {
                                                    console.log(e.target.checked);
                                                    setComentRow(l => {
                                                        l['coment-row-' + i] = e.target.checked ? { comentario: '', callBackComentario: props.callBackComentario } : null
                                                        return l
                                                    })
                                                }
                                            }}
                                        ></button>
                                        {/* <label htmlFor={`check_table_${i}_${j}`}>{statusCheckLabel.mensaje}</label> */}
                                        {/* </span> */}
                                    </div>
                                </td>
                            }
                            if (!h.tipo || h.tipo === 'individual')
                                return <td
                                    style={h.style ? h.style : {}}
                                    key={`key_fila_tabla_td${i}${j}`}
                                    onClick={(e) => {
                                        if (propRow.onClick)
                                            if (!propRow.ignoreColumns.includes(h.texto))
                                                propRow.onClick(e, fila)
                                    }}>
                                    {
                                        h.formatear ? // si se envía este parámetro que es una función, se manda la fila como parámetro al llamarla
                                            h.formatear(fila)
                                            :
                                            h.formato === 'dinero' ?
                                                `$${formatoDinero(fila[h.texto])}`
                                                :
                                                fila[h.texto]
                                    }
                                </td>
                            if (!h.tipo || h.tipo === 'normal')
                                return <td
                                    style={h.style ? h.style : {}}
                                    key={`key_fila_tabla_td${i}${j}`}
                                    onClick={(e) => {
                                        if (propRow.onClick)
                                            if (!propRow.ignoreColumns.includes(h.texto))
                                                propRow.onClick(e, fila)
                                    }}
                                >
                                    <div>
                                        <strong>{fila[h.titulo]}</strong>
                                        <br />
                                        <small>{fila[h.texto]}</small>
                                    </div>
                                </td>

                            if (h.tipo === 'categoria')
                                return <td
                                    style={h.style ? h.style : {}}
                                    key={`key_fila_tabla_td${i}${j}`}
                                    onClick={(e) => {
                                        if (propRow.onClick)
                                            if (!propRow.ignoreColumns.includes(h.texto))
                                                propRow.onClick(e, fila)
                                    }}
                                >
                                    <div className={`alert alert-light p-2 m-0 rounded ${h.clases[fila[h.categoria]]}`} role="alert">
                                        {fila[h.texto]}
                                    </div>
                                </td>

                            if (!h.tipo || h.tipo === 'html')
                                return <td
                                    style={h.style ? h.style : {}}
                                    key={`key_fila_tabla_td${i}${j}`}
                                    onClick={(e) => {
                                        if (propRow.onClick)
                                            if (!propRow.ignoreColumns.includes(h.texto))
                                                propRow.onClick(e, fila)
                                    }}
                                >
                                    {h.html ? h.html(fila) : ''}
                                </td>

                            if (h.tipo === 'acciones')
                                return <td
                                    style={h.style ? h.style : {}}
                                    key={`key_fila_tabla_td${i}${j}`}
                                    className="detalle-col text-left"
                                    onClick={(e) => {
                                        if (propRow.onClick)
                                            if (!propRow.ignoreColumns.includes(h.texto))
                                                propRow.onClick(e, fila)
                                    }}
                                >
                                    {
                                        h.acciones.map((a, k) => {
                                            let objeto;
                                            if (a.params && Array.isArray(a.params) && a.params.length) {
                                                objeto = {};
                                                a.params.forEach(param => {
                                                    objeto[param] = fila[param];
                                                });
                                            }

                                            return <button key={`key_fila_tabla_btn${i}${j}${k}`} type="button" className="btn btn-detalle rounded-pill m-1" onClick={a.onClick ? () => { a['onClick'](objeto) } : null}>
                                                {a['texto']}
                                            </button>
                                        })
                                    }
                                </td>
                        })
                    }
                </tr>
                {comentRow['coment-row-' + i] ?
                    <tr>
                        <td colSpan={headers.length}>
                            <div className='input-field' >
                                <input
                                    type="text"
                                    placeholder="Escriba un comentario..."
                                    value={comentRow['coment-row-' + i].comentario}
                                    onChange={(e) => {
                                        comentRow['coment-row-' + i].callBackComentario({ ...fila, comentario: e.target.value })
                                        setComentRow(l => {
                                            l['coment-row-' + i] = { ...l['coment-row-' + i], comentario: e.target.value }
                                            return l
                                        })
                                        onChangeInput(fila)
                                    }} />
                            </div>
                        </td>
                    </tr>
                    : ''}
            </Fragment>)
        }
        )
    }

    const construirElementosCargando = () => {
        let arreglo = [], i;
        for (i = 0; i < dataPagination.rowsPerPage; i++) {
            arreglo.push(
                <tr key={`key_fila_cargando${i}`}>
                    {
                        headers.map((h, j) => {
                            if (h.tipo === 'categoria')
                                return <td key={`key_fila_td_cargando${i}${j}`}>
                                    <div>
                                        <div className="skeleton skeleton-text skeleton-footer"></div>
                                        <div className="skeleton skeleton-text skeleton-text__body"></div>
                                    </div>
                                </td>

                            else
                                return <td key={`key_fila_td_cargando${i}${j}`} className="detalle-col">
                                    <div className="skeleton skeleton-text skeleton-badge"></div>
                                </td>
                        })
                    }
                </tr>
            )
        }
        return arreglo;
    }

    const construirElementosCargandoCards = () => {
        let arreglo = [], i;
        for (i = 0; i < dataPagination.rowsPerPage; i++) {
            arreglo.push(
                <div className={`col-xl-3 col-sm-6 xl-4 col-grid-box`} key={'rowSlide' + i}>
                    <div className="card" >
                        <div className="product-box">
                            <div className="product-img">
                                <div className="skeleton skeleton-text skeleton-card"></div>
                            </div>
                        </div>
                    </div>
                </div>
            )
        }
        return arreglo;
    }

    const getParamsJson = (url, esUrl) => {
        var arr = url.split('?') ? url.split('?') : []
        url = arr[0]
        var strParams = arr[1]
        var arrParams = strParams ? strParams.split('&') : []
        var _json = {}
        if (esUrl) {
            _json['url'] = url
        }
        arrParams.map((v) => {
            var arrItem = v.split('=')
            _json[arrItem[0]] = arrItem[1]
        })
        return _json
    }

    const cargarRegistros = (pagina, n_filas) => {
        // console.log('Cargar registros', JSON.stringify(({...body, pagina, cantidad_resultados: n_filas})));
        // console.log('pagina', pagina);
        // console.log('n_filas', n_filas);
        let isSubscribed = true

        if (isSubscribed) {
            setLoading(true);
        }
        //pagina = body.pagina == 0 && body.BUSQUEDA.length == 0? body.pagina: pagina

        fetch(path, {
            method,
            body: method === 'GET' ? null : JSON.stringify(({ ...(body), ...filtros, pagina, cantidad_resultados: n_filas })),
            headers: { 'Content-Type': 'application/json' }
        }).then(res => res.json())
            .then(res => {
                if (isSubscribed) {
                    if (res.status === 'OK') {
                        if (res.result[0] && res.result[0][totalField]) {

                            if (!noRutaDinamica) {
                                window.history.pushState({}, 'url', `${ruta}?pagina=${pagina}`)
                            }

                            let paginacion = infoPaginacion(pagina, res.result[0][totalField], n_filas);
                            setDataPagination((s) => ({ ...s, page: pagina, pages: paginacion.paginas, lastPage: paginacion.total_paginas, rowsPerPage: n_filas }));
                            setRows(res.result);

                            callBack(res.result)
                            setLoading(false);
                        } else {
                            setDataPagination((s) => ({ ...s, page: 1, pages: [], lastPage: 1, rowsPerPage: n_filas }));
                            setRows([]);
                            setLoading(false);
                        }
                    } else {
                        setLoading(false);
                    }
                }
            }).catch(err => {
                if (isSubscribed) {
                    setLoading(false);
                }
            })

        return () => isSubscribed = false
    }

    const evaluarEscribiendo = (e) => {
        if (timeout) {
            clearTimeout(timeout)
        }

        busqueda_pendiente = e.target.value;

        if (!noRutaDinamica) {
            window.history.pushState({}, 'url', `${ruta}?pagina=1`)
        }

        timeout = setTimeout(() => {
            setFiltros((s) => ({ ...s, b: busqueda_pendiente }))
        }, 500)
    }

    // useEffect(() => {
    // 	let _rows = rows.map((v) => ({...v, checked: checked[v.CLAVE_CHECK]?true:false}))
    // 	setRows(_rows)
    // 	console.log('Entro', checked)
    // }, [checked])

    return (
        <Fragment>
            <div className="mt-2">
                <div className="row mt-2 mb-2">
                    {!props.sinBuscador ?
                        <div className="col-md-6">
                            <label style={{ fontWeight: 600, fontSize: 16 }} htmlFor="buscar">
                                BUSCAR
                            </label>
                            <div className="d-flex justify-content-between align-items-end">
                                <div className="input_buscador_container">
                                    <input
                                        id="input_buscador"
                                        type="text"
                                        className="form-control"
                                        name="buscar"
                                        placeholder={props.placeholderSearch ? props.placeholderSearch : 'Buscar...'}
                                        aria-label=""
                                        aria-describedby="basic-addon1"
                                        onKeyUp={evaluarEscribiendo}
                                    />
                                </div>
                                <span className='m-2'></span>
                                <button type="button" className="btn btn-success" onClick={() => {
                                    window.document.getElementById('input_buscador').value = ''
                                    setFiltros((s) => ({ ...s, pagina: 0, b: '' }))
                                }}>
                                    <svg width="" height="20" viewBox="0 0 76 56" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M57.2 9.60001V15.6C64.1 15.6 69.6 21.2 69.6 28C69.6 34.8 64 40.4 57.2 40.4H40L46.2 33.2L41.6 29.3L30.2 42.6L41.6 56L46.2 52.1L41.4 46.5H57.2C67.4 46.5 75.6 38.2 75.6 28.1C75.6 18 67.4 9.60001 57.2 9.60001Z" fill="#ffffff" />
                                        <path d="M34 26.7L45.4 13.4L34 0L29.4 4L34.2 9.6H18.4C8.2 9.6 0 17.9 0 28C0 38.1 8.3 46.4 18.4 46.4V40.4C11.5 40.4 6 34.8 6 28C6 21.2 11.6 15.6 18.4 15.6H35.6L29.4 22.8L34 26.7Z" fill="#ffffff" />
                                    </svg>
                                </button>
                                {/* <span className='m-2'></span> */}
                                {props.htmlBtn ? props.htmlBtn : ''}
                            </div>
                        </div>
                        : ''}
                    {props.paginadorTop ?
                        <div className={`${!props.sinBuscador ? 'col-md-6' : 'col-12'} mb-4 mt-4 selectnum d-flex align-items-end justify-content-end flex-wrap`}>
                            {props.htmlBtnPag ?
                                props.htmlBtnPag
                                : ''}
                            {props.seleccion ?
                                <button
                                    className={props.seleccion.className ? props.seleccion.className : "btn btn-secondary btn-sm"}
                                    type="button"
                                    onClick={() => {
                                        props.seleccion.onClickSeleccion({ checked, checkedTotal }, (datos) => {
                                            if (datos.estatus) {
                                                setChecked((s) => ({}))
                                                setTotalChecked(0)

                                                if (checkedTotal) {
                                                    setCheckedTotal((s) => false)
                                                }

                                                setRows((s) => (s.map((v) => ({ ...v, checked: false }))))
                                            }
                                        })
                                    }}
                                >{props.seleccion.btnNombre}
                                </button> : ''}
                            {/* <span className='m-2'></span> */}
                            <div className="pagin" style={{ marginLeft: '10px', marginTop: '10px' }}>
                                <Paginador
                                    pagina={dataPagination.page}
                                    paginas={dataPagination.pages}
                                    ultima_pagina={dataPagination.lastPage}
                                    filasPorPagina={dataPagination.rowsPerPage}
                                    cargarDatos={(pagina, n_filas) => { cargarRegistros(pagina, n_filas) }}
                                    rows={rows}
                                />
                            </div>
                        </div> : ''}
                </div>
                {!props.modo || props.modo === 'table' ? <div className="table-responsive prueba">
                    <table className="table table-sticky">
                        <thead>
                            <tr>
                                {
                                    headers.map((h, i) => {
                                        return <th key={`key_header	_tabla${i}`} scope="col" style={h.style ? h.style : {}}>
                                            <div className="tab-header">
                                                <span className="mb-32">
                                                    {!(h.tipo === 'check') ? h.nombre :
                                                        <Fragment>
                                                            <div className="form-check">
                                                                {/* <input className="form-check-input" type="checkbox"
                                                                    checked={(checkedTotal ? 'on' : '')}
                                                                    onChange={() => {
                                                                        if (checkedTotal) {
                                                                            setTotalChecked(0)
                                                                        }

                                                                        setChecked((s) => ({}))
                                                                        setTimeout(() => setCheckedTotal((s) => !s), 100)
                                                                        setTimeout(() => setRows((s) => (s.map((v) => ({ ...v, checked: 0 })))), 100)
                                                                    }}
                                                                /> */}
                                                                {h.nombre} {/*!checkedTotal ? <Fragment>({totalChecked})</Fragment> : ''*/}
                                                            </div>
                                                        </Fragment>
                                                    }
                                                </span>
                                            </div>
                                        </th>
                                    })
                                }
                            </tr>
                        </thead>
                        <tbody>
                            {!loading && rows && body ?
                                construirElementos()
                                :
                                construirElementosCargando()
                            }
                        </tbody>
                    </table>
                </div> : ''}
                {props.modo === 'card' ?
                    <div className="row gridRow">
                        {!loading && rows && body ? rows.map((item, i) =>
                            <div className={`col-xl-3 col-sm-6 xl-4 col-grid-box`} key={'row' + i}>
                                <div className="card" >
                                    <div className="product-box">
                                        <div className="product-img">
                                            {/* <span className="ribbon ribbon-bookmark ribbon-vertical-right ribbon-info">
											<i className="icon-heart">Test</i>
										</span>  */}
                                            {/* {(item.status === 'Hot') ?
											<span className="ribbon ribbon ribbon-clip ribbon-warning">
												{item.status}
											</span> : ''} */}
                                            <img className="img-fluid" style={{ height: '100%' }} alt="" src={
                                                (item[(headers.find((h) => {
                                                    return h.tipo === 'img'
                                                }) || {}).texto]) || NoImg
                                            } />
                                            <div className="product-hover">
                                                <ul>
                                                    {/* <li>
													<button className="btn" type="button" onClick={() => null} >
														<i className="icon-shopping-cart"></i>
													</button>
												</li> */}
                                                    <li style={{ cursor: 'pointer' }}>
                                                        <button className="btn" type="button" data-toggle="modal"
                                                            onClick={(e) => props.modoProps.onClick ? props.modoProps.onClick(item) : null} data-target="#exampleModalCenter">
                                                            <i className="icon-plus"></i>
                                                        </button>
                                                    </li>
                                                    {/* <li>
													<button className="btn" type="button" onClick={() => null}>
														<i className="icon-heart"></i>
													</button>
												</li> */}
                                                </ul>
                                            </div>
                                        </div>
                                        <div className="product-details">
                                            {headers.map((h, i) => {
                                                if (h.tipo === 'html') {
                                                    return h.html(item)
                                                }

                                                if (h.tipo === 'img') {
                                                    return ''
                                                }

                                                if (h.tipo === 'titulo') {
                                                    return <h5 key={'titulo' + i}>{item[h.texto]}</h5>
                                                }

                                                return <div key={'otro' + i}>{item[h.texto]}</div>
                                            })}
                                            {/* <h5>
											<a onClick={() => null} className="font-primary" href="#javascript">
												{item.name}
											</a></h5>
										<div className="product-price">
											<del>
											{item.discountPrice} </del>
											{item.price}
										</div> */}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        ) :
                            construirElementosCargandoCards()
                        }
                    </div>
                    : ''}
            </div>

            {(props.esPaginador === null || props.esPaginador === undefined) || props.esPaginador === true ?
                <div className="row">
                    <div className="col-12 selectnum d-flex align-items-center flex-wrap mb-4 justify-content-between">
                        <div className="select-number d-flex align-items-center me-3">
                            <label className="mb-0 mr-2">Elementos por página:</label>
                            <Select
                                style={{ width: 40 }}
                                options={opcionesCantidadResultados}
                                placeholder="10"
                                onChange={selectCantidadResultados}
                                menuPlacement="auto"
                            ></Select>
                        </div>
                        <div className="pagin">
                            <Paginador
                                pagina={dataPagination.page}
                                paginas={dataPagination.pages}
                                ultima_pagina={dataPagination.lastPage}
                                filasPorPagina={dataPagination.rowsPerPage}
                                cargarDatos={(pagina, n_filas) => { cargarRegistros(pagina, n_filas) }}
                                rows={rows}
                            />
                        </div>
                    </div>
                </div>
                : ''}
        </Fragment>
    )
}

function infoPaginacion(pag, n_resultados /* cantidad de resultados */, n_filas /* filas por página */) {
    pag = parseInt(pag)
    var cantidad = n_filas, array_paginas = []
    var i, fin, total = Math.ceil(n_resultados / cantidad), suma = true, content = ''

    if (pag - 2 < 1) {
        i = 1
        fin = 5
    } else {
        i = pag - 2
        fin = pag + 2
    }

    if (fin > total) {
        fin = total
    }
    if ((fin - i) < 4) {
        while ((fin - i) < 4 && suma) {
            suma = false
            if (i > 1) {
                suma = true
                i--
            }
            if ((fin - i) < 4 && fin < total) {
                suma = true
                fin++
            }
        }
    }

    for (i; i <= fin; i++) {
        array_paginas.push(i)
    }

    return { paginas: array_paginas, total_paginas: total }
}

const Paginador = ({ pagina, paginas, ultima_pagina, cargarDatos, filasPorPagina, rows }) => {
    return <Pagination aria-label="Page navigation" className="pagination-primary new-pagination">
        <PaginationItem
            disabled={pagina == 1 ? true : false}
            onClick={() => {
                if (pagina - 1 >= 1) {
                    cargarDatos(1, filasPorPagina);
                }
            }}
        >
            <PaginationLink className="bg-blue arrow">
                <i className="fa fa-angle-double-left"></i>
            </PaginationLink>
        </PaginationItem>

        <PaginationItem onClick={() => {
            if (pagina - 1 > 1) {
                cargarDatos(pagina - 1, filasPorPagina)
            }
        }}
            disabled={pagina == 1 ? true : false}>
            <PaginationLink className="bg-blue arrow">
                <i className="fa fa-angle-left"></i>
            </PaginationLink>
        </PaginationItem>

        {
            paginas.map((pag, i) => {
                return <PaginationItem key={`PaginationItem${i}`} onClick={() => { cargarDatos(pag, filasPorPagina); }} className={pag == pagina ? "active" : ""}>
                    <PaginationLink >{tools.formatNumber(pag)}</PaginationLink>
                </PaginationItem>
            })
        }

        <PaginationItem
            disabled={pagina == ultima_pagina ? true : false}
            onClick={() => {
                if (pagina < ultima_pagina) {
                    cargarDatos(pagina + 1, filasPorPagina);
                }
            }}
        >

            <PaginationLink className="bg-blue arrow">
                <i className="fa fa-angle-right"></i>
            </PaginationLink>
        </PaginationItem>

        <PaginationItem
            disabled={pagina == ultima_pagina ? true : false}
            onClick={() => {
                if (ultima_pagina > 1 && pagina < ultima_pagina) {
                    cargarDatos(ultima_pagina, filasPorPagina);
                }
            }}
        >
            <PaginationLink className="bg-blue arrow">
                <i className="fa fa-angle-double-right"></i>
            </PaginationLink>
        </PaginationItem>
        <PaginationItem disabled={false}>
            <PaginationLink className="bg-blue arrow text-center">
                <span style={{ fontSize: 11 }}>{rows[0] ? tools.formatNumber(rows[0].NR) : tools.formatNumber(0)}</span>
            </PaginationLink>
        </PaginationItem>
    </Pagination>
}

export default TablaPro;
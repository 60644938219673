import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import { GetPermisos } from '../common/getPermisos';
import Breadcrumb from '../common/breadcrumbv2';
import { Navigate, useNavigate, useParams } from 'react-router-dom'
import Dropzone from '../common/dropzone';
import NewDropzone from '../common/dropzone2';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({ esEditar }) => {

    const [formIns, setFormIns] = useState({})
    const [img, setImg] = useState({})
    const [listFamilias, setListFamilias] = useState([])
    const [listMarcas, setListMarcas] = useState([])
    const [valFam, setValFam] = useState(0)
    const [ctgUnidades, setCtgUnidades] = useState([])
    const [maxMin, setMaxMin] = useState([])
    const [valUnidades, setValUnidades] = useState('')
    const params = useParams();
    const navigate = useNavigate()
    const [catalogos, setCatalogos] = useState({})

    useEffect(() => {
        if (!esEditar) setImg({})

        params.tipo = 1
        Tools.toolFetch({
            ruta: 'rt_insumos_detalle',
            method: 'POST',
            body: params,
        }, (response) => {
            console.log(response);
            if (response.status === 'OK') {
                setListFamilias(response.familias)
                setListMarcas(response.marcas)
                setCtgUnidades(response.unidadMedida)
                setMaxMin(response.maxMin)
                setFormIns(esEditar ? response.result[0] : {})
            }
        })
    }, [esEditar])

    const onClickIns = () => {
        const form = Tools.getForm('formIns', { ignore: ['txt_num_ext', 'txt_medida_maxima', 'txt_medida_minima', 'txt_descripcion'] })
        console.log(img);

        if (form.isEmtyCount > 0) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        if (!img.imgPortada) {
            return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese una imagen`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        }

        var dataImg = new FormData()

        dataImg.append('datos', JSON.stringify({ ...form.data, id: params.id, tipo: 1 }))
        dataImg.append('img', img.imgPortada)
        dataImg.append('id', 1)

        fetch(!esEditar ? '../rt_insumos_nuevo' : '../rt_insumos_editar', {
            method: 'POST',
            body: dataImg
        })
            .then((response) => response.json())
            .then((response) => {
                console.log(response);

                if (response.status === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.status === 'IMG-TYPE') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Inserte un archivo .jpg, .jpeg, .png`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.status === 'IMG-SIZE') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Su imagen excede el límite (5mb)`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                setTimeout(() => {
                    window.location.href = '/insumos'
                }, 2000);

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Los datos se han guardado correctamente`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Briefcase} title={'Insumos'} parent={[{ titulo: 'insumos', link: '/insumos' }, { titulo: `${esEditar ? 'editar' : 'nuevo'} insumo`, link: `/insumos/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formIns' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            <div className="card-header d-flex justify-content-between">
                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/insumos')
                                }}></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVO'} INSUMO</strong></h4>
                                <h4></h4>
                            </div>
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales del insumo</strong></h6>
                                        <hr />
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                maxLength={150}
                                                defaultValue={formIns ? formIns.NOMBRE_INA : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_titulo" name="txt_titulo"
                                                type="text"
                                                maxLength={150}
                                                defaultValue={formIns ? formIns.TITULO_INA : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Título<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Titulo)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                id="txt_clave_interna" name="txt_clave_interna"
                                                type="text"
                                                maxLength={50}
                                                defaultValue={formIns ? formIns.CLAVE_INTERNA_INA : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> Clave interna<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_clave_interna" style={{ display: 'none' }}><strong>Falta completar (Clave interna)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Familia"
                                                id="clave_familia"
                                                name="clave_familia"
                                                value={formIns.ID_FI_INA ? formIns.ID_FI_INA : ''}
                                                datos={listFamilias}
                                                onChange={(e) => { setFormIns(l => { return { ...l, ID_FI_INA: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-list-ul" aria-hidden="true"></i> Familias<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_familia" style={{ display: 'none' }}><strong>Falta completar (Familia)</strong></span>
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                autoComplete='off'
                                                id="txt_sku" name="txt_sku"
                                                type="text"
                                                maxLength={20}
                                                defaultValue={formIns ? formIns.SKU_INA : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-hashtag" aria-hidden="true"></i> SKU<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_sku" style={{ display: 'none' }}><strong>Falta completar (SKU)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Marca"
                                                id="clave_marca"
                                                name="clave_marca"
                                                value={formIns.ID_MAR_INA ? formIns.ID_MAR_INA : ''}
                                                datos={listMarcas}
                                                onChange={(e) => { setFormIns(l => { return { ...l, ID_MAR_INA: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-list-ul" aria-hidden="true"></i> Marca<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_marca" style={{ display: 'none' }}><strong>Falta completar (Marca)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Unidad"
                                                id="clave_unidad_max"
                                                name="clave_unidad_max"
                                                value={formIns.ID_CU_MAX_INA}
                                                datos={ctgUnidades}
                                                onChange={(e) => { setFormIns(l => { return { ...l, ID_CU_MAX_INA: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-level-up" aria-hidden="true"></i> Unidad de medida máxima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_unidad_max" style={{ display: 'none' }}><strong>Falta completar (Unidad de medida)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                autoComplete='off'
                                                id="txt_medida_maxima" name="txt_medida_maxima"
                                                type="number"
                                                maxLength={10}
                                                defaultValue={formIns ? formIns.MEDIDA_MAXIMA_INA : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-level-up" aria-hidden="true"></i> Medida máxima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_medida_maxima" style={{ display: 'none' }}><strong>Falta completar (Medida máxima)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field select'>
                                            <Select
                                                data-label="Unidad"
                                                id="clave_unidad_min"
                                                name="clave_unidad_min"
                                                value={formIns.ID_CU_MIN_INA}
                                                datos={ctgUnidades}
                                                onChange={(e) => { setFormIns(l => { return { ...l, ID_CU_MIN_INA: e.item.clave } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-level-down" aria-hidden="true"></i> Unidad de medida mínima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios clave_unidad_min" style={{ display: 'none' }}><strong>Falta completar (Unidad de medida)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input
                                                autoComplete='off'
                                                id="txt_medida_minima" name="txt_medida_minima"
                                                type="number"
                                                maxLength={10}
                                                defaultValue={formIns ? formIns.MEDIDA_MINIMA_INA : ''}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-level-down" aria-hidden="true"></i> Medida mínima<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_medida_minima" style={{ display: 'none' }}><strong>Falta completar (Medida mínima)</strong></span>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className='input-field'>
                                            <textarea
                                                autoComplete='off'
                                                id="txt_descripcion" name="txt_descripcion"
                                                type="text"
                                                maxLength={1000}
                                                value={formIns.DESCRIPCION_INA}
                                                onChange={(e) => {
                                                    setFormIns({ ...formIns, DESCRIPCION_INA: e.target.value })
                                                }}
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Descripción<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_descripcion" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label labelupload">Portada (600 x 400)<span className="text-danger">*</span></label>
                                        <div className="input-group" style={{ borderRadius: '15px' }}>
                                            <div className="input-group-prepend" placeholder='Subir archivo'></div>
                                            {esEditar ?
                                                <Dropzone
                                                    initialFiles={formIns ? formIns.ruta_imagen : null}
                                                    fileName={formIns ? formIns.ruta_imagen : ''}
                                                    inputText={'Da click o arrastra una imagen .jpg, .jpeg, .png (5mb)...'}
                                                    onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                                /> :
                                                <NewDropzone
                                                    initialFiles={null}
                                                    fileName={''}
                                                    inputText={'Da click o arrastra una imagen .jpg, .jpeg, .png (5mb)...'}
                                                    onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                                />
                                            }
                                        </div>
                                        {/* <span className="text-danger" style={{ display: img.statusPortada === 'removed' ? '' : 'none' }}><strong>Falta completar (Portada)</strong></span> */}
                                        {/* {esEditar ?
                                            <div className="justify-content-end d-flex mt-2">
                                                <button className="btn btn-primary btn-sm d-flex align-items-center" type="button"
                                                // onClick={(e) => guardarImg(1)} 
                                                >Guardar Portada</button>
                                            </div>
                                            : ''} */}
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        <div className="justify-content-end d-flex">
                                            {(esEditar && (GetPermisos.find(permiso => [2051].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [21].includes(permiso)) ? true : false)) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickIns}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button> : ''}
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </Fragment>)
}

export default Default
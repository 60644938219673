import React, { Fragment, useState, useEffect } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams } from 'react-router-dom'
import Table from '../common/tabla'
import { Button, Modal, ModalHeader, ModalBody, ModalFooter } from 'reactstrap';
import Dropzone from '../common/dropzone';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';

const Default = ({
    listArtIns,
    setListArtIns,
    esEditar,
    setSigPag,
    titulo }) => {
    const [formFaIn, setFormFaIn] = useState({})
    const [tipoVal, setTipoVal] = useState(1)
    const [esRefresh, setEsRefresh] = useState(true)
    const [textot, setTextot] = useState('asadaw')
    const [check, setCheck] = useState(true)
    const [listArtInsCopia, setListArtInsCopia] = useState({})
    const [esRefreshCopia, setEsRefreshCopia] = useState(true)
    const [modal5, setModal5] = useState();
    const navigate = useNavigate()
    const params = useParams();

    const toggle5 = () => {
        setListArtInsCopia(!modal5 ? Object.assign({}, listArtIns) : {})
        setTipoVal(1)
        setEsRefreshCopia(!esRefreshCopia)
        setModal5(!modal5)
    }
    const guardarSeleccionados = () => {
        setListArtIns(listArtInsCopia)
        setModal5(false)
        setEsRefresh(!esRefresh)
    }

    return (
        <Fragment>
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    <div className="card-header d-flex justify-content-center p-3">
                        <h4>Insumos/Articulos {titulo !== '' ? `(${titulo})` : ''}</h4>
                    </div>
                    <div className="mt-3 px-4">
                        <Modal isOpen={modal5} toggle={toggle5}>
                            <ModalHeader toggle={toggle5}>Unidada de medida</ModalHeader>
                            <div className="mt-3 px-4">
                                <div className='input-field select'>
                                    <Select
                                        data-label="Auditor"
                                        defaultOptOff={true}
                                        datos={[{ clave: 1, label: 'Insumos' }, { clave: 2, label: 'Articulos' }]}
                                        onChange={(e) => {
                                            setTipoVal(parseInt(e.item.clave))
                                            console.log(listArtIns);
                                        }}
                                        // required
                                        spellcheck="false"
                                    />
                                    <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Seleccionar Articulos/Insumos <span className="text-danger">*</span></label>
                                </div>
                            </div>
                            <ModalBody>
                                {tipoVal == 1 ?
                                    <Table
                                        noRutaDinamica={true}
                                        cargando={false}
                                        headers={[
                                            {
                                                nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                                    return <div style={{ textAlign: 'center' }}>
                                                        <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.NOMBRE_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'TITULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.TITULO_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'SKU', tipo: 'html', texto: 'SKU_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.SKU_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                                    setListArtInsCopia(l => {
                                                        l[d.ID_INA] = !l[d.ID_INA] ? d.ID_INA : false
                                                        return l
                                                    })
                                                }
                                            },
                                        ]}
                                        totalField={'NR'} /* NR = número de resultados totales */
                                        // path={'/rt_familias_insumos_list'}
                                        path={'/rt_proveedores_artins_list'}
                                        // refresh={esRefresh}
                                        body={{ tipo: 1, seleccionados: listArtInsCopia }}
                                        rowsPerPage={20}
                                        paginadorTop={true}
                                    >
                                    </Table> : ''
                                }
                                {tipoVal === 2 ?
                                    <Table
                                        noRutaDinamica={true}
                                        cargando={false}
                                        headers={[
                                            {
                                                nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                                    return <div style={{ textAlign: 'center' }}>
                                                        <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.NOMBRE_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'TITULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.TITULO_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'SKU', tipo: 'html', texto: 'SKU_INA', html: (d) => {
                                                    return <div>
                                                        <strong>{d.SKU_INA}</strong><br />
                                                    </div>
                                                }
                                            },
                                            {
                                                nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                                    setListArtInsCopia(l => {
                                                        l[d.ID_INA] = !l[d.ID_INA] ? d.ID_INA : false
                                                        return l
                                                    })
                                                }
                                            },
                                        ]}
                                        totalField={'NR'} /* NR = número de resultados totales */
                                        // path={'/rt_familias_insumos_list'}
                                        path={'/rt_proveedores_artins_list'}
                                        // refresh={esRefresh}
                                        body={{ tipo: 2, seleccionados: listArtInsCopia }}
                                        rowsPerPage={20}
                                        paginadorTop={true}
                                    >
                                    </Table> : ''
                                }
                            </ModalBody>
                            <ModalFooter>
                                <Button color="primary" onClick={guardarSeleccionados}>Guardar</Button>
                                <Button color="secondary" onClick={toggle5}>Cancelar</Button>
                            </ModalFooter>
                        </Modal>
                    </div>
                    <div className="mt-3 px-4">
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <Fragment>
                                    {/* {!esHistorial ? */}
                                    <div className="btn-container">
                                        <button className='btn-nuevo' onClick={toggle5}>
                                            <span className="text">Agregar</span>
                                            <div className="icon-container">
                                                <div className="icon icon--left">
                                                </div>
                                                <div className="icon icon--right">
                                                </div>
                                            </div>
                                        </button>
                                    </div>
                                    {/* : ''} */}
                                </Fragment>
                            }
                            headers={[
                                {
                                    nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                        return <div style={{ textAlign: 'center' }}>
                                            <img src={'/images/articulos-insumos/' + d.ID_INA + '/' + d.RUTA_IMAGEN_INA} width={100} height={100} />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_INA', html: (d) => {
                                        return <div>
                                            <strong>{d.NOMBRE_INA}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'TITULO', tipo: 'html', texto: 'TITULO_INA', html: (d) => {
                                        return <div>
                                            <strong>{d.TITULO_INA}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_INA', html: (d) => {
                                        return <div>
                                            <strong>{d.CLAVE_INTERNA_INA}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'SKU', tipo: 'html', texto: 'SKU_INA', html: (d) => {
                                        return <div>
                                            <strong>{d.SKU_INA}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'SELECCIONAR', tipo: 'check', texto: 'ID_INA', callback: (d) => {
                                        setListArtIns(l => {
                                            l[d.ID_INA] = !l[d.ID_INA] ? d.ID_INA : false
                                            return l
                                        })
                                        setEsRefresh(l => !l)
                                    }
                                },
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_proveedores_artins_list'}
                            refresh={esRefresh}
                            body={{ tipo: 0, seleccionados: listArtIns }}
                            rowsPerPage={20}
                            paginadorTop={true}
                        >
                        </Table>
                    </div>
                    <div className="col-md-12 mb-3">
                        <div className="justify-content-end d-flex">
                            {/* {(esEditar && (GetPermisos.find(permiso => [2057].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [15].includes(permiso)) ? true : false)) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickProv}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button>
                                                : ''
                                            } */}
                            <button className='btn-nuevo' type='button' onClick={() => {
                                setSigPag('2')
                            }}>
                                <span className="text">Guardar</span>
                                <div className="icon-container">
                                    <div className="icon icon--left">
                                    </div>
                                    <div className="icon icon--right">
                                    </div>
                                </div>
                            </button>
                        </div>
                    </div>
                </div>
            </div >
        </Fragment >
    );
}

export default Default
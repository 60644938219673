import React, { Fragment, useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux'
import notie from 'notie'

// import Breadcrumb from '../common/breadcrumbv2';
import user from '../../assets/images/user/1.jpg';
import email1 from '../../assets/images/email/1.jpg';
import email2 from '../../assets/images/email/2.jpg';
import email3 from '../../assets/images/email/3.jpg';
import Tools from '../../js/tools'

import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap';
import CKEditors from "react-ckeditor-component";
var images = require.context('../../assets/images', true);


const Default = props => {
    const emailList = useSelector(list => list);

    const usersList = useSelector(content => content.EmailApp.allEmails);
    const TypesOfData = useSelector(mailTypes => mailTypes.EmailApp.types);
    const dispatch = useDispatch();

    const [singleMailRecord, setSingleMailRecord] = useState({});
    const [compose, setCompose] = useState(true);
    const [dropdownOpen, setOpen] = useState(false);
    const [html, setHtml] = useState('')
    const [titulo, setTitulo] = useState('Seleccione un correo')
    const [type, setType] = useState('inbox');
    const [emailIds, setEmailIds] = useState([]);
    const [mailData, setMailData] = useState([]);
    const [correosList, setCorreosList] = useState([])
    const [indiceCantidad, setIndiceCantidad] = useState(0)
    const [checked, setchecked] = useState(false);
    const [selectedFav, setSelectedFav] = useState(false);

    const toggle = () => setOpen(!dropdownOpen);

    function groupBy(array = [], f) {
        var groups = {};
        // array.forEach(function (o) {
        //     var group = f(o)[0]
        //     groups[group] = groups[group] || [];
        //     groups[group].push(o);
        // });
        return groups;
    }

    useEffect(() => {
        var result = groupBy(usersList, function (item) {
            return [item.type];
        });
        dispatch({ type: 'GET_EMAIL_TYPES', payload: result })
    }, []);

    useEffect(() => {
        console.log(indiceCantidad);

        Tools.toolFetch({
            ruta: 'rt_buzon_list',
            method: 'POST',
            body: {
                indiceCantidad,
                tr: 10
            }
        }, (response) => {
            console.log(response);
            setCorreosList(l => ([...l, ...response.result]))
        })
    }, [indiceCantidad]);

    const dynamicImage = (image) => {
        return images(`./${image}`);
    }

    const correoDetalle = (id, nombre) => {
        Tools.toolFetch({
            ruta: 'rt_buzon_detalle',
            method: 'POST',
            body: { id }
        }, (response) => {
            if (response.status === 'ERROR') return notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persite el detalle comunica al equipo de Prointernet.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })

            setTitulo(nombre)
            setHtml(response.html)
        })
    }

    const addFavourite = (singleMailRecord) => {
        dispatch({ type: 'UPDATE_EMAIL_TYPES', payload: singleMailRecord })
    }


    const selectTypes = (types) => {
        setSelectedFav(false)
        setType(types)
    }

    const selectFev = (types) => {
        setSelectedFav(true)
    }

    const moveEmails = (val) => {
        [...document.querySelectorAll('.checkbox_animated')].map((input) => {
            if (input.checked) {
                let fakeInput = {
                    target: {
                        value: input.value,
                        checked: false
                    }
                }
                input.checked = !input.checked;
                selectedmail(fakeInput);
            }
            return null;
        })
        for (var i = 0; i < usersList.length; i++) {
            if (emailIds.includes(usersList[i].id)) {
                usersList[i].type = val;
            }
        }

        var result = groupBy(usersList, function (item) {
            return [item.type];
        });

        dispatch({ type: 'GET_ALL_EMAILS', payload: usersList });
        dispatch({ type: 'GET_EMAIL_TYPES', payload: result })

    }

    const selectedmail = (e, emailID) => {
        const IDs = emailIds;
        setchecked(e.target.checked);
        if (emailIds == null) {
            setEmailIds(mailData)
        } else {
            if (e.target.checked) {
                IDs.push(emailID)
                setEmailIds(IDs)
                const arr = [...new Set(emailIds)];;
                setEmailIds(arr)
            } else {
                setEmailIds(mailData)
            }
        }
    }

    return (
        <Fragment>
            {/* <Breadcrumb title="Email App" parent="Email App" /> */}
            <div className="container-fluid">
                <div className="email-wrap">
                    <div className="row">
                        <div className="col-xl-3 col-md-6">
                            <div className="email-right-aside">
                                <div className="card email-body">
                                    <div className="pr-0 b-r-light">
                                        <div className="email-top">
                                            <div className="row">
                                                <div className="col">
                                                    <h5>Correos</h5>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="inbox custom-scrollbar" onScroll={(e) => {
                                            if (parseInt(e.target.scrollHeight - e.target.scrollTop) === e.target.clientHeight) setIndiceCantidad(l => l + 1)
                                        }}>
                                            {correosList.length > 0 ?
                                                correosList.map((list, index) => {
                                                    return (
                                                        <div className="media"
                                                            key={index}
                                                            style={{
                                                                padding: '20px',
                                                                alignItems: 'center',
                                                                cursor: 'pointer'
                                                            }}
                                                            onClick={() => { correoDetalle(list.ID_BC, list.NOMBRE_BC) }}
                                                        >
                                                            <div className="media-size-email">
                                                                <img className="mr-3 rounded-circle" src={dynamicImage("user/9.jpg")} alt="" />
                                                            </div>
                                                            <div className="media-body">
                                                                <h6>{list.NOMBRE_BC}  <small><span className="digits">({list.FECHA_ALTA_FORMAT_BC})</span></small></h6>
                                                                <p>{list.cc},</p>
                                                            </div>
                                                        </div>
                                                    )
                                                })
                                                :
                                                <div className="search-not-found text-center ng-star-inserted" >
                                                    <div className="">
                                                        <img alt="" className="second-search" src={images(`./search-not-found.png`)} />
                                                        <p className="mb-0">No mail found</p>
                                                    </div>
                                                </div>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div className="col-xl-9 col-md-12">
                            <div className="email-right-aside">
                                <div className="card email-body radius-left">
                                    <div className="pl-0">
                                        <div className="tab-content">
                                            <div className={`tab-pane fade ${compose ? 'active show' : ''}`} id="pills-darkhome" role="tabpanel" aria-labelledby="pills-darkhome-tab">
                                                <div className="email-compose">
                                                    <div className="email-top compose-border">
                                                        <div className="row">
                                                            <div className="col-sm-8 xl-50">
                                                                <h4 className="mb-0">{titulo}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="email-wrapper">
                                                        <div className="container-fluid">
                                                            <div className="row theme-tab">
                                                                {html ?
                                                                    <div dangerouslySetInnerHTML={{ __html: html }}></div>
                                                                    :
                                                                    <div className="search-not-found text-center ng-star-inserted" >
                                                                        <div className="">
                                                                            <img alt="" className="second-search" src={images(`./dashboard/folder.png`)} />
                                                                            <p className="mb-0">Buzón</p>
                                                                        </div>
                                                                    </div>}
                                                            </div >
                                                        </div >
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;
import React, { Fragment, useState, useEffect, useRef } from 'react';
import Select from '../common/select-basic'
import Tools from '../../js/tools'
import notie from 'notie'
import { Briefcase } from 'react-feather';
import Breadcrumb from '../common/breadcrumbv2';
import { useNavigate, useParams, useLocation } from 'react-router-dom'
// import { GetPermisos } from '../common/getPermisos';
import Dropzone from '../common/dropzone';
import NewDropzone from '../common/dropzone2';
import SelectFecth from '../common/select-fetch';
import SelectSimple from '../common/select-simple';
import { NumericFormat } from 'react-number-format'
import TablaPrecios from '../common/tabla_estatica';
import { GetPermisos } from '../common/getPermisos';

const Default = ({
    esEditar,
    listArtIns,
    setListArtIns,
    formRec,
    setFormRec,
    procedimiento,
    setProcedimiento,
    setValueTab,
    img,
    setImg,
    formPag,
    setFormPag,
    setDataForm,
    esHistorial }) => {
    const [verify, setVerify] = useState(true)
    const [tipoVal, setTipoVal] = useState(0)
    const [ctgUsuarios, setCtgUsuarios] = useState([])
    const [ctgAlmacenes, setCtgAlmacenes] = useState([])
    const [listSelect, setListSelect] = useState({})
    const navigate = useNavigate()
    const params = useParams();
    const firstCurrent = useRef(true)
    const location = useLocation()

    useEffect(() => {
        if (firstCurrent.current) {
            firstCurrent.current = false
            return
        }

        const form = siguientePag()
        if (form.activo) {
            setValueTab('1')
            setDataForm(form.form)
        }
    }, [formPag])

    const siguientePag = () => {
        const form = Tools.getForm('formRec', { ignore: ['txt_descripcion'] })

        if (form.isEmtyCount > 0) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese los campos: ` + form.isEmtyString,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        if (!img.imgPortada) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese una imagen`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        if (procedimiento.length === 0) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Ingrese un procedimiento para su receta.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        let imgType = img.imgPortada.type.split('/')
        if (!['jpg', 'jpeg', 'png'].includes(imgType[imgType.length - 1])) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Inserte un archivo .jpg, .jpeg, .png`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        if (img.imgPortada.size > 5767168) {
            notie.alert({
                type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Su imagen excede el limite (5mb)`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
            return { activo: 0 }
        }

        return { activo: 1, form }
    }


    const changeCompraArtIns = (compra_final) => {
        compra_final = compra_final ? compra_final : 0

        const listJson = {}
        const lista = Object.values(listArtIns).filter((v) => v)

        lista.forEach(v => {
            let inv_in = parseFloat(v.inv_in ? v.inv_in : 0)
            let merma = parseFloat(v.merma ? v.merma : 0)
            let compra = parseFloat(v.compra ? v.compra : 0)
            let produccion = parseFloat(v.produccion ? v.produccion : 0)
            let consumo = produccion * compra_final

            listJson[v.id] = { ...v, consumo, inv_fin: (inv_in + compra - merma - consumo) }
        })

        setListArtIns(listJson)
    }

    return (
        <Fragment>
            {/* <Breadcrumb icon={Briefcase} title={'Familias insumos/articulos'} parent={['familias insumos']} /> */}
            <div className="container-fluid ">
                <div className='row'>
                    <form id='formRec' className="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                        <div className='card'>
                            {/* <div className="card-header d-flex justify-content-between">

                                <button className='btn-regresar' type='button' onClick={(e) => {
                                    navigate('/familias-insumos')
                                }}></button>
                                <h4><strong>{esEditar ? 'EDITAR' : 'NUEVA'} FAMILA DE INSUMOS/ARTICULOS</strong></h4>
                                <h4></h4>
                            </div> */}
                            <div className="card-body">
                                <div className='row'>
                                    <div className="col-md-12 mb-3">
                                        <h6><strong>Datos generales de la receta</strong></h6>
                                        <hr />
                                    </div>
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="clave interna"
                                                id="txt_clave" name="txt_clave"
                                                type="text"
                                                value={formRec.CLAVE_INTERNA_RE ? formRec.CLAVE_INTERNA_RE : ''}
                                                onChange={(e) => { setFormRec(f => { return { ...f, CLAVE_INTERNA_RE: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Clave interna<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_clave" style={{ display: 'none' }}><strong>Falta completar (Clave interna)</strong></span>
                                    </div> */}
                                    {/* <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="clave interna"
                                                id="txt_sku" name="txt_sku"
                                                type="text"
                                                value={formRec.SKU_RE ? formRec.SKU_RE : ''}
                                                onChange={(e) => { setFormRec(f => { return { ...f, SKU_RE: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Sku<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_sku" style={{ display: 'none' }}><strong>Falta completar (Sku)</strong></span>
                                    </div> */}
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="titulo"
                                                id="txt_titulo" name="txt_titulo"
                                                type="text"
                                                maxLength={150}
                                                value={formRec.TITULO_RE ? formRec.TITULO_RE : ''}
                                                onChange={(e) => { setFormRec(f => { return { ...f, TITULO_RE: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Título<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_titulo" style={{ display: 'none' }}><strong>Falta completar (Título)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="nombre"
                                                id="txt_nombre" name="txt_nombre"
                                                type="text"
                                                maxLength={150}
                                                value={formRec.NOMBRE_RE ? formRec.NOMBRE_RE : ''}
                                                onChange={(e) => { setFormRec(f => { return { ...f, NOMBRE_RE: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><span class="icon-icono_Materia-Prima"></span> Nombre<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_nombre" style={{ display: 'none' }}><strong>Falta completar (Nombre)</strong></span>
                                    </div>
                                    {/* <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="inventario inicial"
                                                id="txt_inv_inc" name="txt_inv_inc"
                                                type="text"
                                                value={formRec.INV_INC_RE ? formRec.INV_INC_RE : ''}
                                                onChange={(e) => {
                                                    setFormRec(f => { return { ...f, INV_INC_RE: parseFloat(e.target.value) } })
                                                    setFormRec(f => { return { ...f, INV_FIN_RE: (parseFloat(e.target.value) + f.PALEY1_RE + f.PALEY2_RE - f.MERMA_RE - f.CONSUMO_RE) } })
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Inventario inicial<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_inv_inc" style={{ display: 'none' }}><strong>Falta completar (Inventario inicial)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="Compra"
                                                id="txt_compra" name="txt_compra"
                                                type="text"
                                                value={formRec.COMPRA_RE ? formRec.COMPRA_RE : ''}
                                                onChange={(e) => { setFormRec(f => { return { ...f, COMPRA_RE: parseFloat(e.target.value) } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Compra<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_compra" style={{ display: 'none' }}><strong>Falta completar (compra)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="produccion"
                                                id="txt_produccion" name="txt_produccion"
                                                type="text"
                                                value={formRec.PRODUCCION_RE ? formRec.PRODUCCION_RE : ''}
                                                onChange={(e) => {
                                                    setFormRec(f => {
                                                        const compra = f.CONSUMO_RE / parseFloat(e.target.value)
                                                        changeCompraArtIns(compra)

                                                        return { ...f, COMPRA_RE: compra, PRODUCCION_RE: e.target.value }
                                                    })
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Produccion<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_produccion" style={{ display: 'none' }}><strong>Falta completar (Produccion)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="merma"
                                                id="txt_merma" name="txt_merma"
                                                type="text"
                                                value={formRec.MERMA_RE ? formRec.MERMA_RE : ''}
                                                onChange={(e) => {
                                                    setFormRec(f => {
                                                        return {
                                                            ...f,
                                                            MERMA_RE: parseFloat(e.target.value),
                                                            INV_FIN_RE: (f.PALEY1_RE + f.PALEY2_RE + f.INV_INC_RE - f.CONSUMO_RE - parseFloat(e.target.value))
                                                        }
                                                    })
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Merma<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_merma" style={{ display: 'none' }}><strong>Falta completar (Merma)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="paley1"
                                                id="txt_paley1" name="txt_paley1"
                                                type="text"
                                                value={formRec.PALEY1_RE ? formRec.PALEY1_RE : ''}
                                                onChange={(e) => {
                                                    setFormRec(f => {
                                                        const consumo = ((f.PALEY2_RE ? f.PALEY2_RE : 0) + parseFloat(e.target.value))
                                                        const compra = f.PRODUCCION_RE ? (consumo / f.PRODUCCION_RE) : 0
                                                        changeCompraArtIns(compra)

                                                        return {
                                                            ...f,
                                                            CONSUMO_RE: consumo,
                                                            PALEY1_RE: parseFloat(e.target.value),
                                                            COMPRA_RE: compra,
                                                            INV_FIN_RE: (parseFloat(e.target.value) + f.PALEY2_RE - f.MERMA_RE + f.INV_INC_RE + consumo)
                                                        }
                                                    })
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Paley1<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_paley1" style={{ display: 'none' }}><strong>Falta completar (Paley1)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="paley2"
                                                id="txt_paley2" name="txt_paley2"
                                                type="text"
                                                value={formRec.PALEY2_RE ? formRec.PALEY2_RE : ''}
                                                onChange={(e) => {
                                                    setFormRec(f => {
                                                        const consumo = ((f.PALEY1_RE ? f.PALEY1_RE : 0) + parseFloat(e.target.value))
                                                        const compra = f.PRODUCCION_RE ? (consumo / f.PRODUCCION_RE) : 0
                                                        changeCompraArtIns(compra)

                                                        return {
                                                            ...f,
                                                            CONSUMO_RE: consumo,
                                                            PALEY2_RE: parseFloat(e.target.value),
                                                            COMPRA_RE: compra,
                                                            INV_FIN_RE: (parseFloat(e.target.value) + f.PALEY1_RE - f.MERMA_RE + f.INV_INC_RE + consumo)
                                                        }
                                                    })
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Paley2<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_paley2" style={{ display: 'none' }}><strong>Falta completar (Paley2)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="consumo"
                                                id="txt_consumo" name="txt_consumo"
                                                type="text"
                                                value={formRec.CONSUMO_RE ? formRec.CONSUMO_RE : ''}
                                                onChange={(e) => {
                                                    setFormRec(f => {
                                                        const compra = (parseFloat(e.target.value) / (f.PRODUCCION_RE ? f.PRODUCCION_RE : 0))
                                                        changeCompraArtIns(compra)

                                                        return {
                                                            ...f,
                                                            COMPRA_RE: compra,
                                                            CONSUMO_RE: parseFloat(e.target.value),
                                                            INV_FIN_RE: (f.PALEY1_RE - f.MERMA_RE + f.INV_INC_RE + f.PALEY2_RE - parseFloat(e.target.value))
                                                        }
                                                    })
                                                }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Consumo<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_consumo" style={{ display: 'none' }}><strong>Falta completar (Consumo)</strong></span>
                                    </div>
                                    <div className="col-md-3 mb-3">
                                        <div className='input-field'>
                                            <input autoComplete='off'
                                                data-label="inventario final"
                                                id="txt_inv_final" name="txt_inv_final"
                                                type="text"
                                                value={formRec.INV_FIN_RE ? formRec.INV_FIN_RE : ''}
                                                onChange={(e) => { setFormRec(f => { return { ...f, INV_FIN_RE: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Inventario final<span className="text-danger">*</span></label>
                                        </div>
                                        <span className="text-danger txtVacios txt_inv_final" style={{ display: 'none' }}><strong>Falta completar (Inventario final)</strong></span>
                                    </div> */}
                                    <div className="col-md-12 mb-3">
                                        <div className='input-field'>
                                            <textarea autoComplete='off'
                                                data-label="Descripcion"
                                                id="txt_descripcion" name="txt_descripcion"
                                                type="text"
                                                maxLength={1000}
                                                value={formRec.DESCRIPCION_RE ? formRec.DESCRIPCION_RE : ''}
                                                onChange={(e) => { setFormRec(f => { return { ...f, DESCRIPCION_RE: e.target.value } }) }}
                                                required
                                                spellcheck="false"
                                            />
                                            <label htmlFor="validationTooltip02"><i class="fa fa-comment" aria-hidden="true"></i> Descripción</label>
                                        </div>
                                        <span className="text-danger txtVacios txt_descripcion" style={{ display: 'none' }}><strong>Falta completar (Descripción)</strong></span>
                                    </div>
                                    <div className="col-md-6 mb-3">
                                        <label className="form-label labelupload">Portada (600 X 400)<span className="text-danger">*</span></label>
                                        <div className="input-group">
                                            <div className="input-group-prepend" placeholder='Subir archivo'></div>
                                            {esEditar ?
                                                <Dropzone
                                                    initialFiles={formRec.ruta_imagen}
                                                    fileName={formRec.ruta_imagen}
                                                    inputText={'Da click o arrastra una imagen .jpg, .jpeg, .png (5mb)...'}
                                                    onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                                /> :
                                                <NewDropzone
                                                    initialFiles={null}
                                                    fileName={''}
                                                    inputText={'Da click o arrastra una imagen .jpg, .jpeg, .png (5mb)...'}
                                                    onImg={(datos) => { setImg((s) => ({ ...s, statusPortada: datos.status, imgPortada: datos.file })) }}
                                                />
                                            }
                                        </div>
                                    </div>
                                    <div className="col-md-12 mb-3">
                                        {/* <div className="justify-content-end d-flex">
                                            {(esEditar && (GetPermisos.find(permiso => [2061].includes(permiso)) ? true : false)) ||
                                                (!esEditar && (GetPermisos.find(permiso => [31].includes(permiso)) ? true : false)) ?
                                                <button className='btn-nuevo' type='button' onClick={onClickForm}>
                                                    <span className="text">Guardar</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button>
                                                : ''}
                                        </div> */}
                                    </div>
                                </div>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
            <div className='row'>
                <div className='card'>
                    <div className="card-body">
                        <div className='row'>
                            <div className="col-md-12 mb-3">
                                <h6><strong>Pasos para la preparación de la receta</strong></h6>
                                <hr />
                            </div>
                            <div className="col-md-12 mb-3">
                                {!esHistorial ?
                                    <button
                                        className='btn-nuevo'
                                        type='button'
                                        onClick={() => {
                                            setProcedimiento([...procedimiento, {}])
                                        }}
                                    >
                                        <span className="text">Agregar paso +</span>
                                    </button>
                                    : ''
                                }
                                <br />
                                <div className="u-steps u-steps-vertical">
                                    {procedimiento.map((v, index) => <div className="justify-content-end d-flex">
                                        <div className="col-md-9 mb-3">
                                            <div className='input-field'>
                                                <input autoComplete='off'
                                                    data-label="clave interna"
                                                    id="txt_clave" name="txt_clave"
                                                    type="text"
                                                    maxLength={600}
                                                    value={procedimiento[index].texto ? procedimiento[index].texto : ''}
                                                    onChange={(e) => {
                                                        setProcedimiento(l => {
                                                            l[index] = { ...l[index], texto: e.target.value }
                                                            return l
                                                        })
                                                        setVerify(!verify)
                                                    }}
                                                    required
                                                    spellcheck="false"
                                                />
                                                <label htmlFor="validationTooltip02">Paso {index + 1}<span className="text-danger">*</span></label>
                                            </div>
                                        </div>
                                        <div className="col-md-3 mb-3">
                                            {!esHistorial ?
                                                <button
                                                    className="btn btn-pill btn-danger"
                                                    type="button"
                                                    onClick={() => {
                                                        setProcedimiento(l => {
                                                            delete l[index]
                                                            return l.filter(v => v)
                                                        })
                                                    }}
                                                >X
                                                </button> : ''
                                            }
                                        </div>
                                    </div>
                                    )}
                                </div>
                            </div>
                        </div>
                        {(esEditar && (GetPermisos.find(permiso => [2061].includes(permiso)) ? true : false)) ||
                            (!esEditar && (GetPermisos.find(permiso => [31].includes(permiso)) ? true : false))
                            ? <div className="col-md-12 mb-3">
                                <div className="justify-content-end d-flex">
                                    <button
                                        className='btn-nuevo'
                                        type='button'
                                        // onClick={onClickForm}
                                        onClick={() => { setFormPag(!formPag) }}
                                    >
                                        <span className="text">Siguiente</span>
                                        <div className="icon-container">
                                            <div className="icon icon--left">
                                            </div>
                                            <div className="icon icon--right">
                                            </div>
                                        </div>
                                    </button>
                                </div>
                            </div> : ''}
                    </div>
                </div>
            </div>
        </Fragment>)
}

export default Default
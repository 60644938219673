import React, { useState } from 'react';
import logo from '../assets/images/logo/logo.png';
import { Mail } from 'react-feather';
import '../assets/css/notie.css';
import notie from 'notie';
import { textos } from '../constant/textos';
import Tools from '../js/tools';
import { useNavigate } from 'react-router-dom';

const Default = (props) => {
    const [siguiente, setSiguiente] = useState(1)
    const [chkDobleAuth, setChkDobleAuth] = useState(false)
    const [cHkActivoAuth, setChkActivoAuth] = useState(false)
    const [statusGenerar, setStatusGenerar] = useState({ count: 20, disabled: true })
    const [cAuth] = useState(localStorage.getItem('c_auth') ? localStorage.getItem('c_auth') : '')
    const [codigo, setCodigo] = useState('')
    const [tipoMsj, setTipoMsj] = useState('')
    const [usuario, setUsuario] = useState({})
    const [datosUsu, setDatosUsu] = useState({})
    const navigate = useNavigate();

    let intervalId = 0

    const validaCodigo = (e) => {
        if (isNaN(e.currentTarget.value)) {
            return
        } else {
            if (e.currentTarget.value.length < 7) {
                setCodigo(e.currentTarget.value)
            } else {
                return
            }
        }
    }

    const generarCodigoInterval = () => {
        intervalId = setInterval(() => {
            setStatusGenerar((s) => {
                if (s.count > 0) {
                    return ({ count: s.count - 1, disabled: true })
                } else {
                    clearInterval(intervalId)
                    return ({ count: 20, disabled: false })
                }
            })
        }, 1000)
    }

    const onClickLogin = () => {
        let correo_usuario = document.getElementById('txt_usuario').value;
        let txt_contrasena = document.getElementById('txt_contrasenia').value;
        let datosAcc = {}

        if (!(correo_usuario !== '' && txt_contrasena !== '')) {
            return notie.alert({ type: 'warning', text: textos.login.datos_vacios })
        }

        if (!(/^\w+([\.-]?\w+)*@\w+([\.-]?\w+)*(\.\w{2,4})+$/.test(correo_usuario))) {
            return notie.alert({ type: 'warning', text: textos.login.correo_incorrecto, time: 3 })
        }

        datosAcc = { correo_usuario: correo_usuario, txt_contrasenia: txt_contrasena, c_auth: cAuth, chk_doble_auth: chkDobleAuth }
        Tools.toolFetch({
            ruta: 'rt_login',
            method: 'POST',
            body: datosAcc
        }, (response) => {
            if (response.estatus === 'NO-ENCONTRADO') {
                return notie.alert({ type: 'warning', text: textos.login.usuario_incorrecto, time: 3 })
            }

            // if (response.estado === 3) {
            //     return notie.alert({ type: 'warning', text: textos.login.existe_sesion })
            // }

            if (response.estatus === 'OK') {
                localStorage.setItem('UID', JSON.stringify(response.datos))
                notie.alert({
                    type: 'success',
                    text: `${textos.login.acceso_correcto} ${response.datos.NOMBRE} ${response.datos.APELLIDO}`, time: 2
                })

                setSiguiente(2)
                setTipoMsj(2)
                setDatosUsu({ ...response.datos, PASS: txt_contrasena })
                // return setTimeout(() => {
                //     window.location.href = '/inicio'
                // }, 2500)
            }
        })
    }

    const onClickEnviarCodigo = () => {
        Tools.toolFetch({
            ruta: 'rt_sms',
            method: 'POST',
            body: usuario
        }, (response) => {
            if (!response.estado) {
                return notie.alert({ type: 'warning', text: textos.login.no_pudo_codigo })
            }

            if (!response.estado_sms) {
                return notie.alert({ type: 'warning', text: textos.login.no_pudo_codigo })
            }

            if (response.tipo_mensaje === 1) {
                notie.alert({ type: 'success', text: textos.login.codigo_enviado_numero })
            }

            if (response.tipo_mensaje === 2) {
                notie.alert({ type: 'success', text: textos.login.codigo_enviado_correo })
            }

            generarCodigoInterval()
        })
    }

    const onClickVerificar = () => {
        if (codigo !== '') {
            Tools.toolFetch({
                ruta: 'rt_sms_code',
                method: 'POST',
                body: { ...datosUsu, codigo }
            }, (response) => {
                if (response.estatus === 'ERROR') {
                    return notie.alert({ type: 'warning', text: `Ocurrió un error con la página, favor de recargar la página o vuelva más tarde. Si el problema persiste favor de comunicarse con soporte técnico.` })
                }

                if (response.VERIFICADO === 1) {
                    notie.alert({
                        type: 'success',
                        text: `${textos.login.acceso_correcto} ${response.NOMBRE} ${response.APELLIDO}`, time: 2
                    })

                    return setTimeout(() => {
                        window.location.href = '/inicio'
                    }, 2500)
                }

                return notie.alert({ type: 'warning', text: 'Ocurrio un error al momento de validar el (Código).' })

                // if (cHkActivoAuth && response.datos[0].CAUT) {
                //     localStorage.setItem('c_auth', response.datos[0].CAUT)
                // }
                // localStorage.setItem('UID', JSON.stringify(response.datos[0]))

            })
        } else {
            notie.alert({ type: 'warning', text: textos.login.codigo_vacio })
        }
    }

    return (
        <div className="page-wrapper">
            <div className="container-fluid p-0">
                {/* <!-- login page start--> */}
                <div className="authentication-main">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="auth-innerright">
                                <div className="authentication-box">
                                    <div className="text-center">
                                        <img className="img-fluid" src={logo} alt="CHICKEN PALEY" />
                                    </div>
                                    <div className="card mt-4">
                                        <div className="card-body">
                                            <div className="text-center">
                                                {/* <h4>CHICKEN PALEY</h4> */}
                                                {siguiente === 2 ? <h6>
                                                    Ingrese el código de verificación de 6 dígitos enviado a su
                                                    {tipoMsj === 1 ? ' celular' : ''}
                                                    {tipoMsj === 2 ? ' correo electrónico.' : ''}
                                                </h6> : ''}
                                                {siguiente === 1 ? <h6>Ingrese con su correo y contraseña</h6> : ''}
                                            </div>
                                            <form method='dialog' className="theme-form">
                                                {/* <div className="form-group form-row mt-3 mb-0">
                                                <button className="btn btn-outline-light btn-sm txt-dark btn-block" type="button">Recuperar Contraseña</button>
                                            </div> */}
                                                {siguiente === 1 ? <div className="form-group">
                                                    {/* <div className="form-group">
                                                        <label className="col-form-label pt-0">Correo</label>
                                                        <div className="input-group">
                                                            <div className="input-group-prepend"><span className="input-group-text" id="validationTooltip03"><Mail /></span></div>
                                                            <input id='txt_usuario' className="form-control" type="text" required='' />
                                                        </div>
                                                    </div> */}
                                                    <div className='form-group mt-4'>
                                                        <div className='input-field'>
                                                            <input id='txt_usuario' type="text" required spellcheck="false" />
                                                            <label><span className="icon-icono_Cliente"></span> Correo electrónico</label>
                                                        </div>
                                                    </div>
                                                    <div className='form-group mt-4'>
                                                        <div className='input-field'>
                                                            <input id='txt_contrasenia' type="password" required spellcheck="false" />
                                                            <label><i class="fa fa-lock" aria-hidden="true"></i> Contraseña</label>
                                                        </div>
                                                    </div>
                                                    {/* <div className="form-group">
                                                        <label className="col-form-label">Contraseña</label>
                                                        <input id='txt_contrasenia' className="form-control" type="password" required="" />
                                                    </div> */}
                                                    {cAuth !== '' ?
                                                        <div className="form-group mt-4">
                                                            <div className="input-field">
                                                                <input id="checkbox2" type="checkbox" onClick={() => setChkDobleAuth((s) => (!s))} value={chkDobleAuth} />
                                                                <label htmlFor="checkbox2">Activar la doble autenticación la siguiente vez</label>
                                                            </div>
                                                        </div>
                                                        : ''}
                                                    <div className="form-group form-row mt-3 mb-0">
                                                        <div className="btn-container">
                                                            <button id='btn_ingresar' className="btn-nuevo" type="submit" onClick={onClickLogin}>
                                                                <span className="text">Ingresar</span>
                                                                <div className="icon-container">
                                                                    <div className="icon icon--left">
                                                                    </div>
                                                                    <div className="icon icon--right">
                                                                    </div>
                                                                </div>
                                                            </button>
                                                        </div>
                                                    </div>
                                                </div> : ''}
                                                {siguiente === 2 ? <div className="form-group">
                                                    <form method='dialog' className="theme-form">
                                                        <div className="form-group m-form__group">
                                                            <div className='form-group mt-4'>
                                                                <div className='input-field'>
                                                                    <input className="form-control" type="text" required spellcheck="false"
                                                                        style={{ fontSize: 25, textAlign: 'center' }}
                                                                        onChange={(e) => validaCodigo(e)} value={codigo} />
                                                                    <label><span className="icon-icono_Contrasena"></span> Codigo</label>
                                                                </div>
                                                            </div>
                                                            {/* <div className="mt-3 animate-chk">
                                                            <label className="d-block" htmlFor="chk-ani1" >
                                                                <input className="checkbox_animated" id="chk-ani1" type="checkbox" onClick={() => setChkActivoAuth((s) => (!s))} />
                                                                Confio en este equipo, desactivar la doble autenticación.
                                                            </label>
                                                        </div>
                                                        <div className="form-group form-row mt-3 mb-0">
                                                            <button className="btn btn-dark btn-block" type="button" disabled={statusGenerar.disabled}
                                                                onClick={() => onClickEnviarCodigo()} >Generar Nuevo Código {statusGenerar.disabled ? `(${statusGenerar.count})` : ''}</button>
                                                        </div> */}
                                                            <div className="form-group form-row mt-3 mb-0">
                                                            </div>
                                                            <div className="form-group form-row mt-3 mb-0">
                                                                <div className="btn-container">
                                                                    <button id='btn_ingresar' className="btn-nuevo" type="submit" onClick={() => onClickVerificar()}>
                                                                        <span className="text">Confirmar Código</span>
                                                                        <div className="icon-container">
                                                                            <div className="icon icon--left">
                                                                            </div>
                                                                            <div className="icon icon--right">
                                                                            </div>
                                                                        </div>
                                                                    </button>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div> : ''}
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>);
};

export default Default;
import React, { Fragment, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom'
import Tools from '../../js/tools'
import { BtnVer, BtnEliminar } from '../common/table_buttons'
import { GetPermisos } from '../common/getPermisos';
import Table from '../common/tabla'
import notie from 'notie'
import Breadcrumb from '../common/breadcrumbv2';
import { Briefcase } from 'react-feather';

const Default = ({ esEditar, esHistorial }) => {
    const [esRefresh, setEsRefresh] = useState(false)
    const navigate = useNavigate()
    const params = useParams()

    const eliminar = (data) => {
        Tools.toolFetch({
            ruta: 'rt_recetas_eliminar',
            method: 'POST',
            body: data,
        }, (response) => {
            console.log(response)
            if (response.estatus === 'ERROR') {
                return notie.alert({
                    type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            }

            setEsRefresh((s) => !s)
            notie.alert({
                type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                text: `Se ha eliminado este registro.`,
                // stay: Boolean, // optional, default = false
                time: 2, // optional, default = 3, minimum = 1,
                position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
            })
        })
    }

    return (
        <Fragment>
            {!esEditar ? <Breadcrumb icon={Briefcase} title={'Recetas'} parent={[{ titulo: 'recetas', link: '/recetas' }]} /> : ''}
            <div className="card">
                <div className="col-xl-12 col-md-12">
                    <div className="card-header d-flex justify-content-center p-3">
                        <h4>Recetas</h4>
                    </div>
                    <div className="mt-3 px-4">
                        <Table
                            noRutaDinamica={true}
                            cargando={false}
                            htmlBtnPag={
                                <Fragment>
                                    {!esHistorial ?
                                        <div className="btn-container">
                                            {(GetPermisos.find(permiso => [31].includes(permiso)) ? true : false) ?
                                                <button className='btn-nuevo' onClick={() => {
                                                    navigate('/recetas/nuevo')
                                                }}>
                                                    <span className="text">Nuevo</span>
                                                    <div className="icon-container">
                                                        <div className="icon icon--left">
                                                        </div>
                                                        <div className="icon icon--right">
                                                        </div>
                                                    </div>
                                                </button> : ''
                                            }
                                        </div>
                                        : ''}
                                </Fragment>
                            }
                            headers={[
                                {
                                    nombre: 'PORTADA', tipo: 'html', texto: 'PORTADA', html: (d) => {
                                        return <div style={{ textAlign: 'center' }}>
                                            <img src={'/images/recetas/' + d.ID_RE + '/' + d.RUTA_IMAGEN_RE} width={100} height={100} />
                                        </div>
                                    }
                                },
                                // {
                                //     nombre: 'CLAVE INTERNA', tipo: 'html', texto: 'CLAVE_INTERNA_RE', html: (d) => {
                                //         return <div>
                                //             <strong>{d.CLAVE_INTERNA_RE}</strong><br />
                                //         </div>
                                //     }
                                // },
                                {
                                    nombre: 'NOMBRE', tipo: 'html', texto: 'NOMBRE_RE', html: (d) => {
                                        return <div style={{ textAlign: 'center' }}>
                                            <strong>{d.NOMBRE_RE}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'TÍTULO', tipo: 'html', texto: 'TITULO_RE', html: (d) => {
                                        return <div style={{ textAlign: 'center' }}>
                                            <strong>{d.TITULO_RE}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'VERSIÓN', tipo: 'html', texto: 'VERSION_RE', html: (d) => {
                                        return <div>
                                            <strong>V{d.VERSION_RE}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'FECHA ALTA', tipo: 'html', texto: 'FECHA_FORMAT', html: (d) => {
                                        return <div>
                                            <strong>{d.FECHA_FORMAT}</strong><br />
                                        </div>
                                    }
                                },
                                {
                                    nombre: 'Detalles', tipo: 'html', texto: 'Detalle',
                                    html: (d) => {
                                        return <div>
                                            <BtnVer
                                                onClick={() => {
                                                    navigate(!esEditar ? `/recetas/${d.ID_RE}` : `/recetas/historial/${d.ID_RE_RE}/${d.ID_RE}`)
                                                }}
                                            />
                                            {(!esHistorial && GetPermisos.find(permiso => [2062].includes(permiso)) ? true : false) ?
                                                <BtnEliminar
                                                    onClick={() => {
                                                        notie.confirm({
                                                            text: `Se eliminara <strong>${d.NOMBRE_RE}</strong> ¿estas de acuerdo?`,
                                                            submitText: 'Si, eliminar',
                                                            cancelText: 'No, Cancelar',
                                                            cancelCallback: function (value) {
                                                            },
                                                            submitCallback: function (value) {
                                                                eliminar({ id_re: d.ID_RE })
                                                            },
                                                        })
                                                    }}
                                                />
                                                : ''
                                            }
                                        </div>
                                    }
                                }
                            ]}
                            totalField={'NR'} /* NR = número de resultados totales */
                            path={'/rt_recetas_list'}
                            refresh={esRefresh}
                            body={{ esEditar: esEditar, id: params.id }}
                            paginadorTop={true}
                        >
                        </Table>
                    </div>
                </div>
            </div>
        </Fragment>
    );
};

export default Default;
import React, { Fragment, useState, useEffect } from 'react';

const Default = ({ checked, callback, opcion, id, name, disabled }) => {
    const [optCheck, setOptCheck] = useState(checked)

    return <Fragment>
        <label className="d-block container-fluid" for="chk-ani">
            <input
                disabled={disabled || false}
                className="checkbox_animated"
                id={id || "chk-ani"}
                name={name || ''}
                type="checkbox"
                checked={optCheck}
                onClick={(e) => {
                    callback(e.target.checked)
                    setOptCheck(e.target.checked)
                }}
            />
            {opcion}
        </label>
    </Fragment>
}

export default Default
import React, { Fragment, useState, useEffect, useRef } from 'react';
import { Home, Briefcase, BarChart2 } from 'react-feather';
// import { Tabs, TabList, TabPanel, Tab } from 'react-tabs';
import { useParams, useNavigate } from 'react-router-dom'
import Recetas from './recetas_form'
import RecetasList from './recetas'
import RecetasArtIns from './recetas_artins'
// import Breadcrumb from '../../components/common/breadcrumb';
import Breadcrumb from '../common/breadcrumbv2';
import Tools from '../../js/tools'
import notie from 'notie'

import Box from '@mui/material/Box';
import Tab from '@mui/material/Tab';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import { green } from '@mui/material/colors';

const Default = ({ esEditar, esHistorial }) => {
    const [valueTab, setValueTab] = useState('0');
    const [img, setImg] = useState({})
    const [dataForm, setDataForm] = useState({})
    const [formPag, setFormPag] = useState(true)
    const [artInsPag, setArtInsPag] = useState(true)
    const [nuevaVersion, setNuevaVersion] = useState(false)
    const [cantidadVersiones, setCantidadVersiones] = useState(0)
    const [procedimiento, setProcedimiento] = useState([])
    const [tiempos, setTiempos] = useState([])
    const [listArtIns, setListArtIns] = useState({})
    const [listArtInsTipos, setListArtInsTipos] = useState([])
    const [formRec, setFormRec] = useState({})
    const params = useParams()
    const firstCurrent = useRef(true)
    const navigate = useNavigate()

    useEffect(() => {
        return Tools.toolFetch({
            ruta: 'rt_recetas_detalle',
            method: 'POST',
            body: { ...params, esHistorial: esHistorial, esEditar: esEditar },
        }, (response) => {
            console.log('response', response);

            setListArtIns(response.artIns)
            setListArtInsTipos(response.tipos)
            if (esEditar) {
                setFormRec(response.datos)
                setProcedimiento(response.procedimiento)
                setCantidadVersiones(response.cantidad_versiones > 0 ? true : false)
                if (esHistorial) setValueTab('0')

                return
            }
            setFormRec({})
            setListArtIns({})
            setProcedimiento([])
        })
    }, [esEditar, esHistorial])

    useEffect(() => {
        if (firstCurrent.current) {
            firstCurrent.current = false
            return
        }

        // const cantidadVacias = Object.keys(listArtInsTipos).map((key) => listArtInsTipos[key].artIns).map(v => {
        //     const cantidades = Object.keys(v).map((key) => v[key]).filter(e => e.cantidad > 0)
        //     return cantidades.length > 0 ? cantidades : false
        // }).filter(v => v)


        // if (cantidadVacias.length === 0) {
        //     return notie.alert({
        //         type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //         text: `Agregue una cantidad en todos los Articulos/Insumos seleccionados`,
        //         // stay: Boolean, // optional, default = false
        //         time: 2, // optional, default = 3, minimum = 1,
        //         position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //     })
        // }

        let seleccionadosArtIns = []
        // cantidadVacias.forEach(v => { seleccionadosArtIns = [...seleccionadosArtIns, ...v] })

        if (!nuevaVersion && esEditar) {
            return notie.confirm({
                text: `Se creará una nueva versión de esta receta ¿estás de acuerdo?`,
                submitText: 'Si, Crear',
                cancelText: 'No, Cancelar',
                cancelCallback: function (value) {
                },
                submitCallback: function (value) {
                    onClickForm(true)
                    setCantidadVersiones(l => l + 1)
                },
            })
        }

        onClickForm()
    }, [artInsPag])

    const handleChange = (event, newValue) => {
        if (newValue === '1') return setFormPag(!formPag)
        setValueTab(newValue);
    };

    const onClickForm = (nuevaVers) => {
        // const articulosInsumos = Object.values(listArtIns).filter((v) => v)

        // if (articulosInsumos.length === 0) {
        //     return notie.alert({
        //         type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
        //         text: 'Ingrese algún Articulo/Insumo',
        //         // stay: Boolean, // optional, default = false
        //         time: 2, // optional, default = 3, minimum = 1,
        //         position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
        //     })
        // }

        var dataImg = new FormData()
        dataImg.append('id', params.id)
        dataImg.append('datos', JSON.stringify([dataForm.data]))
        dataImg.append('seleccionados', JSON.stringify(listArtInsTipos))
        dataImg.append('procedimiento', JSON.stringify(procedimiento.map((v, index) => ({ orden: index, texto: v.texto }))))
        dataImg.append('img', img.imgPortada)


        fetch(nuevaVers ? '../rt_recetas_nueva_version' : (!esEditar ? '../rt_recetas_nuevo' : '../rt_recetas_editar'), {
            method: 'POST',
            body: dataImg
        })
            .then((response) => response.json())
            .then((response) => {
                console.log('response', response);

                if (response.status === 'ERROR') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ocurrió algo en el servidor, vuelve a intentar en unos minutos, si persiste el detalle comunica al equipo de Prointernet.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.status === 'NOT-COUNT') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ingrese Articulos/Insumos en cada uno de los tiempos.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                if (response.status === 'NOT-TITLE') {
                    return notie.alert({
                        type: 'warning', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                        text: `Ingrese un titulo en cada uno de los tiempos.`,
                        // stay: Boolean, // optional, default = false
                        time: 2, // optional, default = 3, minimum = 1,
                        position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                    })
                }

                setNuevaVersion(true)
                navigate('/recetas/' + response.result[0].ID)

                notie.alert({
                    type: 'success', // optional, default = 4, enum: [1, 2, 3, 4, 5, 'success', 'warning', 'error', 'info', 'neutral']
                    text: `Los datos se han guardado correctamente`,
                    // stay: Boolean, // optional, default = false
                    time: 2, // optional, default = 3, minimum = 1,
                    position: 'top' // optional, default = 'top', enum: ['top', 'bottom']
                })
            })
    }

    return (
        <Fragment>
            <Breadcrumb icon={Home} title='Recetas' parent={[{ titulo: 'recetas', link: '/recetas' }, { titulo: `${esHistorial ? 'historial' : (esEditar ? 'editar' : 'nueva')} receta`, link: `/recetas/${esEditar ? params.id : 'nuevo'}` }]} />
            <div className="container-fluid">
                <div className="row theme-tab">
                    <Box>
                        <TabContext value={valueTab}>
                            <Box>
                                <TabList
                                    TabIndicatorProps={{
                                        style: {
                                            backgroundColor: "#D97D54"
                                        }
                                    }}
                                    style={{ color: "#7E0000" }}
                                    textColor='inherit'
                                    onChange={handleChange}
                                    aria-label="lab API tabs example">
                                    <Tab icon={<BarChart2 />} iconPosition="start" label='Datos' value="0" />
                                    <Tab icon={<Briefcase />} iconPosition="start" label='Tiempos' value="1" />
                                    {esEditar && !esHistorial && cantidadVersiones ? <Tab icon={<Briefcase />} iconPosition="start" label='Historial' value="2" /> : ''}
                                </TabList>
                            </Box>
                            <div className="tab-content-cls">
                                <TabPanel value="0">
                                    <Recetas
                                        listArtIns={listArtIns}
                                        setListArtIns={setListArtIns}
                                        esEditar={esEditar}
                                        formRec={formRec}
                                        setFormRec={setFormRec}
                                        procedimiento={procedimiento}
                                        setProcedimiento={setProcedimiento}
                                        setValueTab={setValueTab}
                                        img={img}
                                        setImg={setImg}
                                        formPag={formPag}
                                        setFormPag={setFormPag}
                                        setDataForm={setDataForm}
                                        esHistorial={esHistorial}
                                    />
                                </TabPanel>
                                <TabPanel value="1">
                                    <RecetasArtIns
                                        listArtIns={listArtIns}
                                        setListArtIns={setListArtIns}
                                        formRec={formRec}
                                        esEditar={esEditar}
                                        setValueTab={setValueTab}
                                        setArtInsPag={setArtInsPag}
                                        esHistorial={esHistorial}
                                        tiempos={tiempos}
                                        setTiempos={setTiempos}
                                        listArtInsTipos={listArtInsTipos}
                                        setListArtInsTipos={setListArtInsTipos}
                                    />
                                </TabPanel>
                                {esEditar && !esHistorial && cantidadVersiones ?
                                    <TabPanel value="2">
                                        <RecetasList
                                            esEditar={esEditar}
                                            esHistorial={true}
                                        />
                                    </TabPanel>
                                    : ''
                                }
                            </div>
                        </TabContext>
                    </Box>
                    {/* <Tabs className="col-sm-12" defaultTab={selectedTab.toString()}>
                        <TabList className="tabs tab-title">
                            <Tab className="current" tabFor="0">
                                <BarChart2 />Datos
                            </Tab>
                            <Tab tabFor="1">
                                <Briefcase />Insumos/Articulos
                            </Tab>
                        </TabList>
                        <div className="tab-content-cls">
                            <TabPanel tabId="0">
                                <Recetas listArtIns={listArtIns} setListArtIns={setListArtIns} esEditar={esEditar} formRec={formRec} setFormRec={setFormRec} procedimiento={procedimiento} setProcedimiento={setProcedimiento} setSelectedTab={setSelectedTab} />
                            </TabPanel>
                            <TabPanel tabId="1">
                                <RecetasArtIns listArtIns={listArtIns} setListArtIns={setListArtIns} formRec={formRec} esEditar={esEditar} />
                            </TabPanel>
                        </div>
                    </Tabs> */}
                </div>
            </div>
        </Fragment>
    )
}


export default Default;